// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const formCommonSaveSearchOptionDialogBaseSearchConditionNameSchema = yup.string().required().label("schema.master.searchCondition.label.name");
export type FormCommonSaveSearchOptionDialogBaseSearchConditionNameSchemaType = InferType<typeof formCommonSaveSearchOptionDialogBaseSearchConditionNameSchema>;
export const formCommonSaveSearchOptionDialogBaseSchema = yup.object({
	searchConditionName: formCommonSaveSearchOptionDialogBaseSearchConditionNameSchema,
}).required();
export type FormCommonSaveSearchOptionDialogBaseSchemaType = InferType<typeof formCommonSaveSearchOptionDialogBaseSchema>;
