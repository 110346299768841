import { number, object } from 'yup';
import { MasterResponse } from '../../../response';
import { formMasterAssetCategoryEntrySchema, formMasterAssetCategoryListSchema } from '../../../schema/form/master/asset';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';

export type AssetCategoryListResponse = MasterResponse & {
	assetCategoryId: number;
	assetCategoryCode: string;
	assetCategoryName: string;
	assetCategoryNote: string | undefined;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: AssetCategoryListResponse[] }>();

const listApi = createApiInterface().request(formMasterAssetCategoryListSchema).response<{
	list: AssetCategoryListResponse[];
}>();

const createApi = createApiInterface().request(formMasterAssetCategoryEntrySchema).response<{
	isSuccess: boolean;
	assetCategoryId?: number;
}>();

const updateApi = createApiInterface()
	.request(
		object({
			id: number().required().integer(),
			form: formMasterAssetCategoryEntrySchema,
		})
	)
	.response<{
		isSuccess: boolean;
	}>();
const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const assetCategoryApi = createApiInterfaceMap({
	['/v1/master/asset/category/find']: findApi,
	['/v1/master/asset/category/list']: listApi,
	['/v1/master/asset/category/create']: createApi,
	['/v1/master/asset/category/update']: updateApi,
	['/v1/master/asset/category/delete']: deleteApi,
});
