// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterProductSkuGroupCodeSchema, typeMasterProductSkuGroupNameSchema, typeMasterProductSkuIdSchema, typeMasterWarehouseStoringConditionIdSchema } from "./../../master";
import { InferType } from "yup";

export const formStockBreakSetProductListInputSkuGroupCodeSchema = typeMasterProductSkuGroupCodeSchema.optional();
export type FormStockBreakSetProductListInputSkuGroupCodeSchemaType = InferType<typeof formStockBreakSetProductListInputSkuGroupCodeSchema>;
export const formStockBreakSetProductListInputSkuGroupNameSchema = typeMasterProductSkuGroupNameSchema.optional();
export type FormStockBreakSetProductListInputSkuGroupNameSchemaType = InferType<typeof formStockBreakSetProductListInputSkuGroupNameSchema>;
export const formStockBreakSetProductListInputSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormStockBreakSetProductListInputSkuIdSchemaType = InferType<typeof formStockBreakSetProductListInputSkuIdSchema>;
export const formStockBreakSetProductListInputStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema.optional();
export type FormStockBreakSetProductListInputStoringConditionIdSchemaType = InferType<typeof formStockBreakSetProductListInputStoringConditionIdSchema>;
export const formStockBreakSetProductListInputSchema = yup.object({
	skuGroupCode: formStockBreakSetProductListInputSkuGroupCodeSchema,
	skuGroupName: formStockBreakSetProductListInputSkuGroupNameSchema,
	skuId: formStockBreakSetProductListInputSkuIdSchema,
	storingConditionId: formStockBreakSetProductListInputStoringConditionIdSchema,
}).required();
export type FormStockBreakSetProductListInputSchemaType = InferType<typeof formStockBreakSetProductListInputSchema>;
