/** 顧客マスタ */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterCustomerEntrySchema, formMasterCustomerListSchema } from '../../../schema/form/master/customer';

export type CustomerListResponse = MasterResponse & {
	customerId: number;
	customerCode: string;
	customerName: string;
	customerNameRuby: string | undefined;
	shipperId: number;
	shipperCode: string;
	shipperName: string;
	customerCategoryId: number;
	customerCategoryCode: string;
	customerCategoryName: string;
	customerPostalCode: string | undefined;
	customerAddress: string;
	customerTel: string | undefined;
	customerFax: string | undefined;
	customerContactName: string | undefined;
	customerNote: string | undefined;
	countryId: number;
	countryName: string;
	prefectureId: number | undefined;
	prefectureName: string | undefined;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: CustomerListResponse[] }>();

const listApi = createApiInterface().request(formMasterCustomerListSchema).response<{
	list: CustomerListResponse[];
}>();

const createApi = createApiInterface().request(formMasterCustomerEntrySchema).response<{
	isSuccess: boolean;
	customerId?: number;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterCustomerEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();
const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const customerApi = createApiInterfaceMap({
	['/v1/master/customer-manage/customer/find']: findApi,
	['/v1/master/customer-manage/customer/list']: listApi,
	['/v1/master/customer-manage/customer/create']: createApi,
	['/v1/master/customer-manage/customer/update']: updateApi,
	['/v1/master/customer-manage/customer/delete']: deleteApi,
});
