import { formMasterShipperListSchema, formMasterShipperEntrySchema } from '../../../schema/form/master/shipper';
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';

export type ShipperListResponse = MasterResponse & {
	shipperId: number;
	shipperCode: string;
	shipperName: string;
	shipperNote: string | undefined;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: ShipperListResponse[] }>();

const listApi = createApiInterface().request(formMasterShipperListSchema).response<{
	list: ShipperListResponse[];
}>();

const createApi = createApiInterface().request(formMasterShipperEntrySchema).response<{
	isSuccess: boolean;
	shipperId?: number;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterShipperEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const shipperApi = createApiInterfaceMap({
	['/v1/master/shipper/find']: findApi,
	['/v1/master/shipper/list']: listApi,
	['/v1/master/shipper/create']: createApi,
	['/v1/master/shipper/update']: updateApi,
	['/v1/master/shipper/delete']: deleteApi,
});
