/** 機能管理 */

import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formSystemMaintenanceFeatureEntrySchema, formSystemMaintenanceFeatureListSchema } from '../../../schema/form/systemMaintenance/feature';
import { object, number } from 'yup';

export type FeatureResponse = {
	featureId: number;
	featureCode: string;
	featureName: string;
};

/** 機能管理 一覧 */
export type FeatureListResponse = MasterResponse & {
	featureId: number; // 機能ID
	featureCode: string; // 機能コード
	featureName: string; // 機能名
	featureCategoryId1: number; // 機能区分ID1
	featureCategoryCode1: string; // 機能区分コード1
	featureCategoryName1: string; // 機能区分名1
	featureCategoryId2: number | undefined; // 機能区分ID2
	featureCategoryCode2: string | undefined; // 機能区分コード2
	featureCategoryName2: string | undefined; // 機能区分名2
	featureCategoryId3: number | undefined; // 機能区分ID3
	featureCategoryCode3: string | undefined; // 機能区分コード3
	featureCategoryName3: string | undefined; // 機能区分名3
	featureNote: string | undefined; // 備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: FeatureListResponse[] }>();

const listApi = createApiInterface().request(formSystemMaintenanceFeatureListSchema.notRequired()).response<{ list: FeatureListResponse[] }>();

const createApi = createApiInterface().request(formSystemMaintenanceFeatureEntrySchema).response<{ isSuccess: boolean }>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formSystemMaintenanceFeatureEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const featuresApi = createApiInterfaceMap({
	['/v1/system-maintenance/feature/find']: findApi,
	['/v1/system-maintenance/feature/list']: listApi,
	['/v1/system-maintenance/feature/create']: createApi,
	['/v1/system-maintenance/feature/update']: updateApi,
	['/v1/system-maintenance/feature/delete']: deleteApi,
});
