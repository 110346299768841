import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { array, number, object } from 'yup';
import { BasicResponse } from '../../response';
import { TotalPickingListResponse } from '../shipping/totalPicking';

export type PickingListResponse = TotalPickingListResponse & {
	details: PickingDetail[];
};

export type PickingDetail = BasicResponse & {
	shippingId: number; // 出荷Id
	shippingCode: string; // 出荷コード
	shipperId: number; // 荷主Id
	shipperCode: string; // 荷主コード
	shipperName: string; // 荷主名
	aisleId: number; // 通路Id
	aisleCode: string; // 通路コード
	aisleName: string; // 通路名
	locationId: number; // ロケーションId
	locationCode: string; // ロケーションコード
	locationName: string; // ロケーション名
	locationDetailId: number; // ロケーション明細Id
	locationDetailCode: string; // ロケーション明細コード
	locationDetailName: string; // ロケーション明細名
	lotId: number; // ロットId
	lotCode: string; // ロットコード
	lotName: string; // ロット名
	skuId: number; // skuid
	skuCode: string; // sku
	productNumberId: number; // 品番Id
	productNumberCode: string; // 品番コード
	productNumberName: string; // 品番名
	productId: number; // 商品Id
	productCode: string; // 商品コード
	productName: string; // 商品名
	pickingScheduleQuantity: number; // ピッキング予定数
	pickingQuantity: number; // ピッキング完了数
	stockQuantity: number; // ロケーション在庫数
};

const listApi = createApiInterface()
	.request(object({ id: array().of(number().integer().required()) }))
	.response<{
		list: PickingListResponse[];
	}>();

export const pickingListApi = createApiInterfaceMap({
	['/v1/print/shipping/picking-list']: listApi,
});
