// fv-generatorで生成されました
import * as yup from "yup";
import { typeIssuingScheduledDateSchema, typeIssuingDateSchema, typeIssuingCodeSchema, typeIssuingNameSchema, typeIssuingCategoryIdSchema } from "./../../issuing";
import { InferType } from "yup";
import { typeSearchItemsSchema } from "./../../search";
import { typeDetailedConditionItemsSchema } from "./../../detailedCondition";

export const formIssuingIssuingListInputIssuingScheduledDateFromSchema = typeIssuingScheduledDateSchema.optional();
export type FormIssuingIssuingListInputIssuingScheduledDateFromSchemaType = InferType<typeof formIssuingIssuingListInputIssuingScheduledDateFromSchema>;
export const formIssuingIssuingListInputIssuingScheduledDateToSchema = typeIssuingScheduledDateSchema.optional();
export type FormIssuingIssuingListInputIssuingScheduledDateToSchemaType = InferType<typeof formIssuingIssuingListInputIssuingScheduledDateToSchema>;
export const formIssuingIssuingListInputIssuingDateFromSchema = typeIssuingDateSchema.optional();
export type FormIssuingIssuingListInputIssuingDateFromSchemaType = InferType<typeof formIssuingIssuingListInputIssuingDateFromSchema>;
export const formIssuingIssuingListInputIssuingDateToSchema = typeIssuingDateSchema.optional();
export type FormIssuingIssuingListInputIssuingDateToSchemaType = InferType<typeof formIssuingIssuingListInputIssuingDateToSchema>;
export const formIssuingIssuingListInputReceiveIssueCodeSchema = typeIssuingCodeSchema.optional();
export type FormIssuingIssuingListInputReceiveIssueCodeSchemaType = InferType<typeof formIssuingIssuingListInputReceiveIssueCodeSchema>;
export const formIssuingIssuingListInputReceiveIssueNameSchema = typeIssuingNameSchema.optional();
export type FormIssuingIssuingListInputReceiveIssueNameSchemaType = InferType<typeof formIssuingIssuingListInputReceiveIssueNameSchema>;
export const formIssuingIssuingListInputReceiveIssueCategoryIdSchema = typeIssuingCategoryIdSchema.optional();
export type FormIssuingIssuingListInputReceiveIssueCategoryIdSchemaType = InferType<typeof formIssuingIssuingListInputReceiveIssueCategoryIdSchema>;
export const formIssuingIssuingListInputSearchItemsSchema = typeSearchItemsSchema;
export type FormIssuingIssuingListInputSearchItemsSchemaType = InferType<typeof formIssuingIssuingListInputSearchItemsSchema>;
export const formIssuingIssuingListInputDetailedConditionsSchema = typeDetailedConditionItemsSchema;
export type FormIssuingIssuingListInputDetailedConditionsSchemaType = InferType<typeof formIssuingIssuingListInputDetailedConditionsSchema>;
export const formIssuingIssuingListInputSchema = yup.object({
	issuingScheduledDateFrom: formIssuingIssuingListInputIssuingScheduledDateFromSchema,
	issuingScheduledDateTo: formIssuingIssuingListInputIssuingScheduledDateToSchema,
	issuingDateFrom: formIssuingIssuingListInputIssuingDateFromSchema,
	issuingDateTo: formIssuingIssuingListInputIssuingDateToSchema,
	receiveIssueCode: formIssuingIssuingListInputReceiveIssueCodeSchema,
	receiveIssueName: formIssuingIssuingListInputReceiveIssueNameSchema,
	receiveIssueCategoryId: formIssuingIssuingListInputReceiveIssueCategoryIdSchema,
	searchItems: formIssuingIssuingListInputSearchItemsSchema,
	detailedConditions: formIssuingIssuingListInputDetailedConditionsSchema,
}).required();
export type FormIssuingIssuingListInputSchemaType = InferType<typeof formIssuingIssuingListInputSchema>;
