// fv-generatorで生成されました
import * as yup from "yup";
import { typeArrivalCodeSchema, typeArrivalScheduledDateSchema, typeArrivalInspectionBeforeArrivalLocationDetailIdSchema, typeArrivalInspectionBeforeArrivalLocationDetailCodeSchema, typeArrivalInspectionBeforeArrivalLocationDetailNameSchema, typeArrivalArrivalLocationDetailIdSchema, typeArrivalArrivalLocationDetailCodeSchema, typeArrivalArrivalLocationDetailNameSchema, typeArrivalDetailIdSchema, typeArrivalScheduledQuantitySchema, typeArrivalCategoryIdSchema } from "./../../arrival";
import { InferType } from "yup";
import { typeMasterClientIdSchema, typeMasterClientCodeSchema, typeMasterClientNameSchema, typeMasterClientSenderIdSchema, typeMasterClientSenderCodeSchema, typeMasterClientSenderNameSchema, typeMasterProductSkuIdSchema, typeMasterProductIdSchema } from "./../../master";
import { typeCommonNoteSchema } from "./../../common";

export const formArrivalArrivalScheduleEntryArrivalCodeSchema = typeArrivalCodeSchema;
export type FormArrivalArrivalScheduleEntryArrivalCodeSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalCodeSchema>;
export const formArrivalArrivalScheduleEntryArrivalScheduledDateSchema = typeArrivalScheduledDateSchema;
export type FormArrivalArrivalScheduleEntryArrivalScheduledDateSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalScheduledDateSchema>;
export const formArrivalArrivalScheduleEntryClientIdSchema = typeMasterClientIdSchema;
export type FormArrivalArrivalScheduleEntryClientIdSchemaType = InferType<typeof formArrivalArrivalScheduleEntryClientIdSchema>;
export const formArrivalArrivalScheduleEntryClientCodeSchema = typeMasterClientCodeSchema;
export type FormArrivalArrivalScheduleEntryClientCodeSchemaType = InferType<typeof formArrivalArrivalScheduleEntryClientCodeSchema>;
export const formArrivalArrivalScheduleEntryClientNameSchema = typeMasterClientNameSchema;
export type FormArrivalArrivalScheduleEntryClientNameSchemaType = InferType<typeof formArrivalArrivalScheduleEntryClientNameSchema>;
export const formArrivalArrivalScheduleEntryClientSenderIdSchema = typeMasterClientSenderIdSchema;
export type FormArrivalArrivalScheduleEntryClientSenderIdSchemaType = InferType<typeof formArrivalArrivalScheduleEntryClientSenderIdSchema>;
export const formArrivalArrivalScheduleEntryClientSenderCodeSchema = typeMasterClientSenderCodeSchema;
export type FormArrivalArrivalScheduleEntryClientSenderCodeSchemaType = InferType<typeof formArrivalArrivalScheduleEntryClientSenderCodeSchema>;
export const formArrivalArrivalScheduleEntryClientSenderNameSchema = typeMasterClientSenderNameSchema;
export type FormArrivalArrivalScheduleEntryClientSenderNameSchemaType = InferType<typeof formArrivalArrivalScheduleEntryClientSenderNameSchema>;
export const formArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailIdSchema = typeArrivalInspectionBeforeArrivalLocationDetailIdSchema.optional();
export type FormArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailIdSchemaType = InferType<typeof formArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailIdSchema>;
export const formArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailCodeSchema = typeArrivalInspectionBeforeArrivalLocationDetailCodeSchema.optional();
export type FormArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailCodeSchemaType = InferType<typeof formArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailCodeSchema>;
export const formArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailNameSchema = typeArrivalInspectionBeforeArrivalLocationDetailNameSchema.optional();
export type FormArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailNameSchemaType = InferType<typeof formArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailNameSchema>;
export const formArrivalArrivalScheduleEntryArrivalLocationDetailIdSchema = typeArrivalArrivalLocationDetailIdSchema.optional();
export type FormArrivalArrivalScheduleEntryArrivalLocationDetailIdSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalLocationDetailIdSchema>;
export const formArrivalArrivalScheduleEntryArrivalLocationDetailCodeSchema = typeArrivalArrivalLocationDetailCodeSchema.optional();
export type FormArrivalArrivalScheduleEntryArrivalLocationDetailCodeSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalLocationDetailCodeSchema>;
export const formArrivalArrivalScheduleEntryArrivalLocationDetailNameSchema = typeArrivalArrivalLocationDetailNameSchema.optional();
export type FormArrivalArrivalScheduleEntryArrivalLocationDetailNameSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalLocationDetailNameSchema>;
export const formArrivalArrivalScheduleEntryArrivalNoteSchema = typeCommonNoteSchema;
export type FormArrivalArrivalScheduleEntryArrivalNoteSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalNoteSchema>;
export const formArrivalArrivalScheduleEntryArrivalDetailValueArrivalDetailIdSchema = typeArrivalDetailIdSchema.optional();
export type FormArrivalArrivalScheduleEntryArrivalDetailValueArrivalDetailIdSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalDetailValueArrivalDetailIdSchema>;
export const formArrivalArrivalScheduleEntryArrivalDetailValueSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormArrivalArrivalScheduleEntryArrivalDetailValueSkuIdSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalDetailValueSkuIdSchema>;
export const formArrivalArrivalScheduleEntryArrivalDetailValueArrivalScheduledQuantitySchema = typeArrivalScheduledQuantitySchema.optional();
export type FormArrivalArrivalScheduleEntryArrivalDetailValueArrivalScheduledQuantitySchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalDetailValueArrivalScheduledQuantitySchema>;
export const formArrivalArrivalScheduleEntryArrivalDetailValueArrivalCategoryIdSchema = typeArrivalCategoryIdSchema.optional();
export type FormArrivalArrivalScheduleEntryArrivalDetailValueArrivalCategoryIdSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalDetailValueArrivalCategoryIdSchema>;
export const formArrivalArrivalScheduleEntryArrivalDetailValueArrivalDetailNoteSchema = typeCommonNoteSchema;
export type FormArrivalArrivalScheduleEntryArrivalDetailValueArrivalDetailNoteSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalDetailValueArrivalDetailNoteSchema>;
export const formArrivalArrivalScheduleEntryArrivalDetailValueProductIdSchema = typeMasterProductIdSchema.optional();
export type FormArrivalArrivalScheduleEntryArrivalDetailValueProductIdSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalDetailValueProductIdSchema>;
export const formArrivalArrivalScheduleEntryArrivalDetailValueSchema = yup.object({
	arrivalDetailId: formArrivalArrivalScheduleEntryArrivalDetailValueArrivalDetailIdSchema,
	skuId: formArrivalArrivalScheduleEntryArrivalDetailValueSkuIdSchema,
	arrivalScheduledQuantity: formArrivalArrivalScheduleEntryArrivalDetailValueArrivalScheduledQuantitySchema,
	arrivalCategoryId: formArrivalArrivalScheduleEntryArrivalDetailValueArrivalCategoryIdSchema,
	arrivalDetailNote: formArrivalArrivalScheduleEntryArrivalDetailValueArrivalDetailNoteSchema,
	productId: formArrivalArrivalScheduleEntryArrivalDetailValueProductIdSchema,
}).optional();
export type FormArrivalArrivalScheduleEntryArrivalDetailValueSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalDetailValueSchema>;
export const formArrivalArrivalScheduleEntryArrivalDetailSchema = yup.array(
	formArrivalArrivalScheduleEntryArrivalDetailValueSchema
).required();
export type FormArrivalArrivalScheduleEntryArrivalDetailSchemaType = InferType<typeof formArrivalArrivalScheduleEntryArrivalDetailSchema>;
export const formArrivalArrivalScheduleEntrySchema = yup.object({
	arrivalCode: formArrivalArrivalScheduleEntryArrivalCodeSchema,
	arrivalScheduledDate: formArrivalArrivalScheduleEntryArrivalScheduledDateSchema,
	clientId: formArrivalArrivalScheduleEntryClientIdSchema,
	clientCode: formArrivalArrivalScheduleEntryClientCodeSchema,
	clientName: formArrivalArrivalScheduleEntryClientNameSchema,
	clientSenderId: formArrivalArrivalScheduleEntryClientSenderIdSchema,
	clientSenderCode: formArrivalArrivalScheduleEntryClientSenderCodeSchema,
	clientSenderName: formArrivalArrivalScheduleEntryClientSenderNameSchema,
	inspectionBeforeArrivalLocationDetailId: formArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailIdSchema,
	inspectionBeforeArrivalLocationDetailCode: formArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailCodeSchema,
	inspectionBeforeArrivalLocationDetailName: formArrivalArrivalScheduleEntryInspectionBeforeArrivalLocationDetailNameSchema,
	arrivalLocationDetailId: formArrivalArrivalScheduleEntryArrivalLocationDetailIdSchema,
	arrivalLocationDetailCode: formArrivalArrivalScheduleEntryArrivalLocationDetailCodeSchema,
	arrivalLocationDetailName: formArrivalArrivalScheduleEntryArrivalLocationDetailNameSchema,
	arrivalNote: formArrivalArrivalScheduleEntryArrivalNoteSchema,
	arrivalDetail: formArrivalArrivalScheduleEntryArrivalDetailSchema,
}).required();
export type FormArrivalArrivalScheduleEntrySchemaType = InferType<typeof formArrivalArrivalScheduleEntrySchema>;
