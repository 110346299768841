// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterProductSkuGroupIdSchema, typeMasterProductSkuGroupCodeSchema, typeMasterProductSkuGroupNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonQuantitySchema } from "./../../common";

export const formStockSetProductScheduleEntrySkuGroupIdSchema = typeMasterProductSkuGroupIdSchema;
export type FormStockSetProductScheduleEntrySkuGroupIdSchemaType = InferType<typeof formStockSetProductScheduleEntrySkuGroupIdSchema>;
export const formStockSetProductScheduleEntrySkuGroupCodeSchema = typeMasterProductSkuGroupCodeSchema;
export type FormStockSetProductScheduleEntrySkuGroupCodeSchemaType = InferType<typeof formStockSetProductScheduleEntrySkuGroupCodeSchema>;
export const formStockSetProductScheduleEntrySkuGroupNameSchema = typeMasterProductSkuGroupNameSchema;
export type FormStockSetProductScheduleEntrySkuGroupNameSchemaType = InferType<typeof formStockSetProductScheduleEntrySkuGroupNameSchema>;
export const formStockSetProductScheduleEntryQuantitySchema = typeCommonQuantitySchema;
export type FormStockSetProductScheduleEntryQuantitySchemaType = InferType<typeof formStockSetProductScheduleEntryQuantitySchema>;
export const formStockSetProductScheduleEntrySchema = yup.object({
	skuGroupId: formStockSetProductScheduleEntrySkuGroupIdSchema,
	skuGroupCode: formStockSetProductScheduleEntrySkuGroupCodeSchema,
	skuGroupName: formStockSetProductScheduleEntrySkuGroupNameSchema,
	quantity: formStockSetProductScheduleEntryQuantitySchema,
}).required();
export type FormStockSetProductScheduleEntrySchemaType = InferType<typeof formStockSetProductScheduleEntrySchema>;
