import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterProductStatusEntrySchema, formMasterProductStatusListSchema } from '../../../schema/form/master/product';

export type ProductStatusListResponse = MasterResponse & {
	productStatusId: number; // 商品状態ID
	productStatusCode: string; // 商品状態コード
	productStatusName: string; // 商品状態名
	productStatusNote: string | undefined; // 備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: ProductStatusListResponse[] }>();

const listApi = createApiInterface().request(formMasterProductStatusListSchema).response<{
	list: ProductStatusListResponse[];
}>();

const createApi = createApiInterface().request(formMasterProductStatusEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterProductStatusEntrySchema }))
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const productStatusApi = createApiInterfaceMap({
	['/v1/master/product/status/find']: findApi,
	['/v1/master/product/status/list']: listApi,
	['/v1/master/product/status/create']: createApi,
	['/v1/master/product/status/update']: updateApi,
	['/v1/master/product/status/delete']: deleteApi,
});
