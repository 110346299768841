// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionQuantitySchema } from "./../../common";
import { InferType } from "yup";
import { typeMasterProductLotIdSchema } from "./../../master";

export const formReceivingReceivingInspectionMobileEntryInspectionQuantitySchema = typeCommonInspectionQuantitySchema.optional();
export type FormReceivingReceivingInspectionMobileEntryInspectionQuantitySchemaType = InferType<typeof formReceivingReceivingInspectionMobileEntryInspectionQuantitySchema>;
export const formReceivingReceivingInspectionMobileEntryLotIdSchema = typeMasterProductLotIdSchema.optional();
export type FormReceivingReceivingInspectionMobileEntryLotIdSchemaType = InferType<typeof formReceivingReceivingInspectionMobileEntryLotIdSchema>;
export const formReceivingReceivingInspectionMobileEntrySchema = yup.object({
	inspectionQuantity: formReceivingReceivingInspectionMobileEntryInspectionQuantitySchema,
	lotId: formReceivingReceivingInspectionMobileEntryLotIdSchema,
}).required();
export type FormReceivingReceivingInspectionMobileEntrySchemaType = InferType<typeof formReceivingReceivingInspectionMobileEntrySchema>;
