// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterProductIdSchema, typeMasterProductCodeSchema, typeMasterProductNameSchema, typeMasterWarehouseLocationDetailIdSchema, typeMasterWarehouseLocationDetailCodeSchema, typeMasterWarehouseLocationDetailNameSchema, typeMasterProductSkuIdSchema } from "./../../master";
import { InferType } from "yup";

export const formStockRestockListInputProductIdSchema = typeMasterProductIdSchema.optional();
export type FormStockRestockListInputProductIdSchemaType = InferType<typeof formStockRestockListInputProductIdSchema>;
export const formStockRestockListInputProductCodeSchema = typeMasterProductCodeSchema.optional();
export type FormStockRestockListInputProductCodeSchemaType = InferType<typeof formStockRestockListInputProductCodeSchema>;
export const formStockRestockListInputProductNameSchema = typeMasterProductNameSchema.optional();
export type FormStockRestockListInputProductNameSchemaType = InferType<typeof formStockRestockListInputProductNameSchema>;
export const formStockRestockListInputLocationDetailIdSchema = typeMasterWarehouseLocationDetailIdSchema.optional();
export type FormStockRestockListInputLocationDetailIdSchemaType = InferType<typeof formStockRestockListInputLocationDetailIdSchema>;
export const formStockRestockListInputLocationDetailCodeSchema = typeMasterWarehouseLocationDetailCodeSchema.optional();
export type FormStockRestockListInputLocationDetailCodeSchemaType = InferType<typeof formStockRestockListInputLocationDetailCodeSchema>;
export const formStockRestockListInputLocationDetailNameSchema = typeMasterWarehouseLocationDetailNameSchema.optional();
export type FormStockRestockListInputLocationDetailNameSchemaType = InferType<typeof formStockRestockListInputLocationDetailNameSchema>;
export const formStockRestockListInputSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormStockRestockListInputSkuIdSchemaType = InferType<typeof formStockRestockListInputSkuIdSchema>;
export const formStockRestockListInputSchema = yup.object({
	productId: formStockRestockListInputProductIdSchema,
	productCode: formStockRestockListInputProductCodeSchema,
	productName: formStockRestockListInputProductNameSchema,
	locationDetailId: formStockRestockListInputLocationDetailIdSchema,
	locationDetailCode: formStockRestockListInputLocationDetailCodeSchema,
	locationDetailName: formStockRestockListInputLocationDetailNameSchema,
	skuId: formStockRestockListInputSkuIdSchema,
}).required();
export type FormStockRestockListInputSchemaType = InferType<typeof formStockRestockListInputSchema>;
