import { number, object } from 'yup';
import { MasterResponse } from '../../../response';
import { formMasterClientTransportMethodEntrySchema, formMasterClientTransportMethodListSchema } from '../../../schema/form/master/client';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';

export type ClientTransportMethodResponse = MasterResponse & {
	transportMethodId: number;
	transportMethodCode: string;
	transportMethodName: string;
	transportMethodNote: string | undefined;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: ClientTransportMethodResponse[] }>();

const listApi = createApiInterface().request(formMasterClientTransportMethodListSchema.notRequired()).response<{
	list: ClientTransportMethodResponse[];
}>();

const createApi = createApiInterface().request(formMasterClientTransportMethodEntrySchema).response<{ isSuccess: boolean; transportMethodId?: number }>();

const updateApi = createApiInterface()
	.request(
		object({
			id: number().required().integer(),
			form: formMasterClientTransportMethodEntrySchema,
		})
	)
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const clientTransportMethodApi = createApiInterfaceMap({
	['/v1/master/client/transport-method/find']: findApi,
	['/v1/master/client/transport-method/list']: listApi,
	['/v1/master/client/transport-method/create']: createApi,
	['/v1/master/client/transport-method/update']: updateApi,
	['/v1/master/client/transport-method/delete']: deleteApi,
});
