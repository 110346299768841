// fv-generatorで生成されました
import * as yup from "yup";
import { typeArrivalCodeSchema, typeArrivalDateSchema, typeArrivalInspectionBeforeArrivalLocationDetailIdSchema, typeArrivalInspectionBeforeArrivalLocationDetailCodeSchema, typeArrivalInspectionBeforeArrivalLocationDetailNameSchema, typeArrivalArrivalLocationDetailIdSchema, typeArrivalArrivalLocationDetailCodeSchema, typeArrivalArrivalLocationDetailNameSchema, typeArrivalDetailIdSchema, typeArrivalScheduledQuantitySchema, typeArrivalQuantitySchema, typeArrivalCategoryIdSchema } from "./../../arrival";
import { InferType } from "yup";
import { typeMasterClientIdSchema, typeMasterClientCodeSchema, typeMasterClientNameSchema, typeMasterClientSenderIdSchema, typeMasterClientSenderCodeSchema, typeMasterClientSenderNameSchema, typeMasterProductSkuIdSchema, typeMasterProductIdSchema } from "./../../master";
import { typeCommonNoteSchema } from "./../../common";

export const formArrivalArrivalResultEntryArrivalCodeSchema = typeArrivalCodeSchema;
export type FormArrivalArrivalResultEntryArrivalCodeSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalCodeSchema>;
export const formArrivalArrivalResultEntryArrivalDateSchema = typeArrivalDateSchema;
export type FormArrivalArrivalResultEntryArrivalDateSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalDateSchema>;
export const formArrivalArrivalResultEntryClientIdSchema = typeMasterClientIdSchema;
export type FormArrivalArrivalResultEntryClientIdSchemaType = InferType<typeof formArrivalArrivalResultEntryClientIdSchema>;
export const formArrivalArrivalResultEntryClientCodeSchema = typeMasterClientCodeSchema;
export type FormArrivalArrivalResultEntryClientCodeSchemaType = InferType<typeof formArrivalArrivalResultEntryClientCodeSchema>;
export const formArrivalArrivalResultEntryClientNameSchema = typeMasterClientNameSchema;
export type FormArrivalArrivalResultEntryClientNameSchemaType = InferType<typeof formArrivalArrivalResultEntryClientNameSchema>;
export const formArrivalArrivalResultEntryClientSenderIdSchema = typeMasterClientSenderIdSchema;
export type FormArrivalArrivalResultEntryClientSenderIdSchemaType = InferType<typeof formArrivalArrivalResultEntryClientSenderIdSchema>;
export const formArrivalArrivalResultEntryClientSenderCodeSchema = typeMasterClientSenderCodeSchema;
export type FormArrivalArrivalResultEntryClientSenderCodeSchemaType = InferType<typeof formArrivalArrivalResultEntryClientSenderCodeSchema>;
export const formArrivalArrivalResultEntryClientSenderNameSchema = typeMasterClientSenderNameSchema;
export type FormArrivalArrivalResultEntryClientSenderNameSchemaType = InferType<typeof formArrivalArrivalResultEntryClientSenderNameSchema>;
export const formArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailIdSchema = typeArrivalInspectionBeforeArrivalLocationDetailIdSchema.optional();
export type FormArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailIdSchemaType = InferType<typeof formArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailIdSchema>;
export const formArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailCodeSchema = typeArrivalInspectionBeforeArrivalLocationDetailCodeSchema.optional();
export type FormArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailCodeSchemaType = InferType<typeof formArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailCodeSchema>;
export const formArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailNameSchema = typeArrivalInspectionBeforeArrivalLocationDetailNameSchema.optional();
export type FormArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailNameSchemaType = InferType<typeof formArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailNameSchema>;
export const formArrivalArrivalResultEntryArrivalLocationDetailIdSchema = typeArrivalArrivalLocationDetailIdSchema.optional();
export type FormArrivalArrivalResultEntryArrivalLocationDetailIdSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalLocationDetailIdSchema>;
export const formArrivalArrivalResultEntryArrivalLocationDetailCodeSchema = typeArrivalArrivalLocationDetailCodeSchema.optional();
export type FormArrivalArrivalResultEntryArrivalLocationDetailCodeSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalLocationDetailCodeSchema>;
export const formArrivalArrivalResultEntryArrivalLocationDetailNameSchema = typeArrivalArrivalLocationDetailNameSchema.optional();
export type FormArrivalArrivalResultEntryArrivalLocationDetailNameSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalLocationDetailNameSchema>;
export const formArrivalArrivalResultEntryArrivalNoteSchema = typeCommonNoteSchema;
export type FormArrivalArrivalResultEntryArrivalNoteSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalNoteSchema>;
export const formArrivalArrivalResultEntryArrivalDetailValueArrivalDetailIdSchema = typeArrivalDetailIdSchema.optional();
export type FormArrivalArrivalResultEntryArrivalDetailValueArrivalDetailIdSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalDetailValueArrivalDetailIdSchema>;
export const formArrivalArrivalResultEntryArrivalDetailValueSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormArrivalArrivalResultEntryArrivalDetailValueSkuIdSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalDetailValueSkuIdSchema>;
export const formArrivalArrivalResultEntryArrivalDetailValueArrivalScheduledQuantitySchema = typeArrivalScheduledQuantitySchema.optional();
export type FormArrivalArrivalResultEntryArrivalDetailValueArrivalScheduledQuantitySchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalDetailValueArrivalScheduledQuantitySchema>;
export const formArrivalArrivalResultEntryArrivalDetailValueArrivalQuantitySchema = typeArrivalQuantitySchema.optional();
export type FormArrivalArrivalResultEntryArrivalDetailValueArrivalQuantitySchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalDetailValueArrivalQuantitySchema>;
export const formArrivalArrivalResultEntryArrivalDetailValueArrivalCategoryIdSchema = typeArrivalCategoryIdSchema.optional();
export type FormArrivalArrivalResultEntryArrivalDetailValueArrivalCategoryIdSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalDetailValueArrivalCategoryIdSchema>;
export const formArrivalArrivalResultEntryArrivalDetailValueArrivalDetailNoteSchema = typeCommonNoteSchema;
export type FormArrivalArrivalResultEntryArrivalDetailValueArrivalDetailNoteSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalDetailValueArrivalDetailNoteSchema>;
export const formArrivalArrivalResultEntryArrivalDetailValueProductIdSchema = typeMasterProductIdSchema.optional();
export type FormArrivalArrivalResultEntryArrivalDetailValueProductIdSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalDetailValueProductIdSchema>;
export const formArrivalArrivalResultEntryArrivalDetailValueSchema = yup.object({
	arrivalDetailId: formArrivalArrivalResultEntryArrivalDetailValueArrivalDetailIdSchema,
	skuId: formArrivalArrivalResultEntryArrivalDetailValueSkuIdSchema,
	arrivalScheduledQuantity: formArrivalArrivalResultEntryArrivalDetailValueArrivalScheduledQuantitySchema,
	arrivalQuantity: formArrivalArrivalResultEntryArrivalDetailValueArrivalQuantitySchema,
	arrivalCategoryId: formArrivalArrivalResultEntryArrivalDetailValueArrivalCategoryIdSchema,
	arrivalDetailNote: formArrivalArrivalResultEntryArrivalDetailValueArrivalDetailNoteSchema,
	productId: formArrivalArrivalResultEntryArrivalDetailValueProductIdSchema,
}).optional();
export type FormArrivalArrivalResultEntryArrivalDetailValueSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalDetailValueSchema>;
export const formArrivalArrivalResultEntryArrivalDetailSchema = yup.array(
	formArrivalArrivalResultEntryArrivalDetailValueSchema
).required();
export type FormArrivalArrivalResultEntryArrivalDetailSchemaType = InferType<typeof formArrivalArrivalResultEntryArrivalDetailSchema>;
export const formArrivalArrivalResultEntrySchema = yup.object({
	arrivalCode: formArrivalArrivalResultEntryArrivalCodeSchema,
	arrivalDate: formArrivalArrivalResultEntryArrivalDateSchema,
	clientId: formArrivalArrivalResultEntryClientIdSchema,
	clientCode: formArrivalArrivalResultEntryClientCodeSchema,
	clientName: formArrivalArrivalResultEntryClientNameSchema,
	clientSenderId: formArrivalArrivalResultEntryClientSenderIdSchema,
	clientSenderCode: formArrivalArrivalResultEntryClientSenderCodeSchema,
	clientSenderName: formArrivalArrivalResultEntryClientSenderNameSchema,
	inspectionBeforeArrivalLocationDetailId: formArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailIdSchema,
	inspectionBeforeArrivalLocationDetailCode: formArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailCodeSchema,
	inspectionBeforeArrivalLocationDetailName: formArrivalArrivalResultEntryInspectionBeforeArrivalLocationDetailNameSchema,
	arrivalLocationDetailId: formArrivalArrivalResultEntryArrivalLocationDetailIdSchema,
	arrivalLocationDetailCode: formArrivalArrivalResultEntryArrivalLocationDetailCodeSchema,
	arrivalLocationDetailName: formArrivalArrivalResultEntryArrivalLocationDetailNameSchema,
	arrivalNote: formArrivalArrivalResultEntryArrivalNoteSchema,
	arrivalDetail: formArrivalArrivalResultEntryArrivalDetailSchema,
}).required();
export type FormArrivalArrivalResultEntrySchemaType = InferType<typeof formArrivalArrivalResultEntrySchema>;
