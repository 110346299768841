// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const typeMasterArrivalCategoryIdSchema = yup.number().required().label("schema.master.arrival.category.label.id").integer();
export type TypeMasterArrivalCategoryIdSchemaType = InferType<typeof typeMasterArrivalCategoryIdSchema>;
export const typeMasterArrivalCategoryCodeSchema = yup.string().required().label("schema.master.arrival.category.label.code");
export type TypeMasterArrivalCategoryCodeSchemaType = InferType<typeof typeMasterArrivalCategoryCodeSchema>;
export const typeMasterArrivalCategoryNameSchema = yup.string().required().label("schema.master.arrival.category.label.name");
export type TypeMasterArrivalCategoryNameSchemaType = InferType<typeof typeMasterArrivalCategoryNameSchema>;
export const typeMasterAssetCategoryIdSchema = yup.number().required().label("schema.master.asset.category.label.id").integer();
export type TypeMasterAssetCategoryIdSchemaType = InferType<typeof typeMasterAssetCategoryIdSchema>;
export const typeMasterAssetCategoryCodeSchema = yup.string().required().label("schema.master.asset.category.label.code");
export type TypeMasterAssetCategoryCodeSchemaType = InferType<typeof typeMasterAssetCategoryCodeSchema>;
export const typeMasterAssetCategoryNameSchema = yup.string().required().label("schema.master.asset.category.label.name");
export type TypeMasterAssetCategoryNameSchemaType = InferType<typeof typeMasterAssetCategoryNameSchema>;
export const typeMasterAssetIdSchema = yup.number().required().label("schema.master.asset.label.id").integer();
export type TypeMasterAssetIdSchemaType = InferType<typeof typeMasterAssetIdSchema>;
export const typeMasterAssetCodeSchema = yup.string().required().label("schema.master.asset.label.code");
export type TypeMasterAssetCodeSchemaType = InferType<typeof typeMasterAssetCodeSchema>;
export const typeMasterAssetNameSchema = yup.string().required().label("schema.master.asset.label.name");
export type TypeMasterAssetNameSchemaType = InferType<typeof typeMasterAssetNameSchema>;
export const typeMasterAssetStatusSchema = yup.number().required().label("schema.master.asset.label.status").integer();
export type TypeMasterAssetStatusSchemaType = InferType<typeof typeMasterAssetStatusSchema>;
export const typeMasterAssetQuantitySchema = yup.number().required().label("schema.master.asset.label.quantity").integer();
export type TypeMasterAssetQuantitySchemaType = InferType<typeof typeMasterAssetQuantitySchema>;
export const typeMasterAssetReturnScheduledDateSchema = yup.date().required().label("schema.master.asset.label.return.scheduled.date");
export type TypeMasterAssetReturnScheduledDateSchemaType = InferType<typeof typeMasterAssetReturnScheduledDateSchema>;
export const typeMasterAssetReturnDateSchema = yup.date().required().label("schema.master.asset.label.return.date");
export type TypeMasterAssetReturnDateSchemaType = InferType<typeof typeMasterAssetReturnDateSchema>;
export const typeMasterAssetCategoryId1Schema = typeMasterAssetCategoryIdSchema.label("schema.master.asset.category.label.id1").integer();
export type TypeMasterAssetCategoryId1SchemaType = InferType<typeof typeMasterAssetCategoryId1Schema>;
export const typeMasterAssetCategoryId2Schema = typeMasterAssetCategoryIdSchema.label("schema.master.asset.category.label.id2").integer();
export type TypeMasterAssetCategoryId2SchemaType = InferType<typeof typeMasterAssetCategoryId2Schema>;
export const typeMasterAssetCategoryId3Schema = typeMasterAssetCategoryIdSchema.label("schema.master.asset.category.label.id3").integer();
export type TypeMasterAssetCategoryId3SchemaType = InferType<typeof typeMasterAssetCategoryId3Schema>;
export const typeMasterClientCategoryIdSchema = yup.number().required().label("schema.master.client.category.label.id").integer();
export type TypeMasterClientCategoryIdSchemaType = InferType<typeof typeMasterClientCategoryIdSchema>;
export const typeMasterClientCategoryCodeSchema = yup.string().required().label("schema.master.client.category.label.code");
export type TypeMasterClientCategoryCodeSchemaType = InferType<typeof typeMasterClientCategoryCodeSchema>;
export const typeMasterClientCategoryNameSchema = yup.string().required().label("schema.master.client.category.label.name");
export type TypeMasterClientCategoryNameSchemaType = InferType<typeof typeMasterClientCategoryNameSchema>;
export const typeMasterClientSenderIdSchema = yup.number().required().label("schema.master.client.sender.label.id").integer();
export type TypeMasterClientSenderIdSchemaType = InferType<typeof typeMasterClientSenderIdSchema>;
export const typeMasterClientSenderCodeSchema = yup.string().required().label("schema.master.client.sender.label.code");
export type TypeMasterClientSenderCodeSchemaType = InferType<typeof typeMasterClientSenderCodeSchema>;
export const typeMasterClientSenderNameSchema = yup.string().required().label("schema.master.client.sender.label.name");
export type TypeMasterClientSenderNameSchemaType = InferType<typeof typeMasterClientSenderNameSchema>;
export const typeMasterClientTransportMethodIdSchema = yup.number().required().label("schema.master.client.transport.method.label.id").integer();
export type TypeMasterClientTransportMethodIdSchemaType = InferType<typeof typeMasterClientTransportMethodIdSchema>;
export const typeMasterClientTransportMethodCodeSchema = yup.string().required().label("schema.master.client.transport.method.label.code");
export type TypeMasterClientTransportMethodCodeSchemaType = InferType<typeof typeMasterClientTransportMethodCodeSchema>;
export const typeMasterClientTransportMethodNameSchema = yup.string().required().label("schema.master.client.transport.method.label.name");
export type TypeMasterClientTransportMethodNameSchemaType = InferType<typeof typeMasterClientTransportMethodNameSchema>;
export const typeMasterClientIdSchema = yup.number().required().label("schema.master.client.label.id").integer();
export type TypeMasterClientIdSchemaType = InferType<typeof typeMasterClientIdSchema>;
export const typeMasterClientCodeSchema = yup.string().required().label("schema.master.client.label.code");
export type TypeMasterClientCodeSchemaType = InferType<typeof typeMasterClientCodeSchema>;
export const typeMasterClientNameSchema = yup.string().required().label("schema.master.client.label.name");
export type TypeMasterClientNameSchemaType = InferType<typeof typeMasterClientNameSchema>;
export const typeMasterClientContactNameSchema = yup.string().required().label("schema.master.client.contact.name");
export type TypeMasterClientContactNameSchemaType = InferType<typeof typeMasterClientContactNameSchema>;
export const typeMasterCustomerCategoryIdSchema = yup.number().required().label("schema.master.customer.category.label.id").integer();
export type TypeMasterCustomerCategoryIdSchemaType = InferType<typeof typeMasterCustomerCategoryIdSchema>;
export const typeMasterCustomerCategoryCodeSchema = yup.string().required().label("schema.master.customer.category.label.code");
export type TypeMasterCustomerCategoryCodeSchemaType = InferType<typeof typeMasterCustomerCategoryCodeSchema>;
export const typeMasterCustomerCategoryNameSchema = yup.string().required().label("schema.master.customer.category.label.name");
export type TypeMasterCustomerCategoryNameSchemaType = InferType<typeof typeMasterCustomerCategoryNameSchema>;
export const typeMasterCustomerDeliveryDestinationIdSchema = yup.number().required().label("schema.master.customer.delivery.destination.label.id").integer();
export type TypeMasterCustomerDeliveryDestinationIdSchemaType = InferType<typeof typeMasterCustomerDeliveryDestinationIdSchema>;
export const typeMasterCustomerDeliveryDestinationCodeSchema = yup.string().required().label("schema.master.customer.delivery.destination.label.code");
export type TypeMasterCustomerDeliveryDestinationCodeSchemaType = InferType<typeof typeMasterCustomerDeliveryDestinationCodeSchema>;
export const typeMasterCustomerDeliveryDestinationNameSchema = yup.string().required().label("schema.master.customer.delivery.destination.label.name");
export type TypeMasterCustomerDeliveryDestinationNameSchemaType = InferType<typeof typeMasterCustomerDeliveryDestinationNameSchema>;
export const typeMasterCustomerDeliveryDestinationNameRubySchema = yup.string().required().label("schema.master.customer.delivery.destination.label.name.ruby");
export type TypeMasterCustomerDeliveryDestinationNameRubySchemaType = InferType<typeof typeMasterCustomerDeliveryDestinationNameRubySchema>;
export const typeMasterCustomerIdSchema = yup.number().required().label("schema.master.customer.label.id").integer();
export type TypeMasterCustomerIdSchemaType = InferType<typeof typeMasterCustomerIdSchema>;
export const typeMasterCustomerCodeSchema = yup.string().required().label("schema.master.customer.label.code");
export type TypeMasterCustomerCodeSchemaType = InferType<typeof typeMasterCustomerCodeSchema>;
export const typeMasterCustomerNameSchema = yup.string().required().label("schema.master.customer.label.name");
export type TypeMasterCustomerNameSchemaType = InferType<typeof typeMasterCustomerNameSchema>;
export const typeMasterCustomerNameRubySchema = yup.string().required().label("schema.master.customer.label.name.ruby");
export type TypeMasterCustomerNameRubySchemaType = InferType<typeof typeMasterCustomerNameRubySchema>;
export const typeMasterCustomerContactNameSchema = yup.string().required().label("schema.master.customer.contact.label.name");
export type TypeMasterCustomerContactNameSchemaType = InferType<typeof typeMasterCustomerContactNameSchema>;
export const typeMasterDeliveryCompanyBaseIdSchema = yup.number().required().label("schema.master.delivery.company.base.label.id").integer();
export type TypeMasterDeliveryCompanyBaseIdSchemaType = InferType<typeof typeMasterDeliveryCompanyBaseIdSchema>;
export const typeMasterDeliveryCompanyBaseCodeSchema = yup.string().required().label("schema.master.delivery.company.base.label.code");
export type TypeMasterDeliveryCompanyBaseCodeSchemaType = InferType<typeof typeMasterDeliveryCompanyBaseCodeSchema>;
export const typeMasterDeliveryCompanyBaseNameSchema = yup.string().required().label("schema.master.delivery.company.base.label.name");
export type TypeMasterDeliveryCompanyBaseNameSchemaType = InferType<typeof typeMasterDeliveryCompanyBaseNameSchema>;
export const typeMasterDeliveryCompanyBaseCustomerCodeSchema = yup.string().required().label("schema.master.delivery.company.base.label.customer.code");
export type TypeMasterDeliveryCompanyBaseCustomerCodeSchemaType = InferType<typeof typeMasterDeliveryCompanyBaseCustomerCodeSchema>;
export const typeMasterDeliveryCompanyIdSchema = yup.number().required().label("schema.master.delivery.company.label.id").integer();
export type TypeMasterDeliveryCompanyIdSchemaType = InferType<typeof typeMasterDeliveryCompanyIdSchema>;
export const typeMasterDeliveryCompanyCodeSchema = yup.string().required().label("schema.master.delivery.company.label.code");
export type TypeMasterDeliveryCompanyCodeSchemaType = InferType<typeof typeMasterDeliveryCompanyCodeSchema>;
export const typeMasterDeliveryCompanyNameSchema = yup.string().required().label("schema.master.delivery.company.label.name");
export type TypeMasterDeliveryCompanyNameSchemaType = InferType<typeof typeMasterDeliveryCompanyNameSchema>;
export const typeMasterDeliveryCompanyDeliveryCompanyUrlSchema = yup.string().required().label("schema.master.delivery.company.label.url.delivery.company");
export type TypeMasterDeliveryCompanyDeliveryCompanyUrlSchemaType = InferType<typeof typeMasterDeliveryCompanyDeliveryCompanyUrlSchema>;
export const typeMasterDeliveryCompanyBaggageTrackingUrlSchema = yup.string().required().label("schema.master.delivery.company.label.url.baggage.tracking");
export type TypeMasterDeliveryCompanyBaggageTrackingUrlSchemaType = InferType<typeof typeMasterDeliveryCompanyBaggageTrackingUrlSchema>;
export const typeMasterDeliveryCompanyDeliveryDateSchema = yup.date().required().label("schema.shipping.label.delivery.date");
export type TypeMasterDeliveryCompanyDeliveryDateSchemaType = InferType<typeof typeMasterDeliveryCompanyDeliveryDateSchema>;
export const typeMasterDeliveryCompanyTimeSpanIdSchema = yup.number().required().label("schema.master.delivery.time.span.label.id").integer();
export type TypeMasterDeliveryCompanyTimeSpanIdSchemaType = InferType<typeof typeMasterDeliveryCompanyTimeSpanIdSchema>;
export const typeMasterPackagingCategoryIdSchema = yup.number().required().label("schema.master.packaging.category.label.id").integer();
export type TypeMasterPackagingCategoryIdSchemaType = InferType<typeof typeMasterPackagingCategoryIdSchema>;
export const typeMasterPackagingCategoryCodeSchema = yup.string().required().label("schema.master.packaging.category.label.code");
export type TypeMasterPackagingCategoryCodeSchemaType = InferType<typeof typeMasterPackagingCategoryCodeSchema>;
export const typeMasterPackagingCategoryNameSchema = yup.string().required().label("schema.master.packaging.category.label.name");
export type TypeMasterPackagingCategoryNameSchemaType = InferType<typeof typeMasterPackagingCategoryNameSchema>;
export const typeMasterProductLargeCategoryIdSchema = yup.number().required().label("schema.master.product.large.category.label.id").integer();
export type TypeMasterProductLargeCategoryIdSchemaType = InferType<typeof typeMasterProductLargeCategoryIdSchema>;
export const typeMasterProductLargeCategoryCodeSchema = yup.string().required().label("schema.master.product.large.category.label.code");
export type TypeMasterProductLargeCategoryCodeSchemaType = InferType<typeof typeMasterProductLargeCategoryCodeSchema>;
export const typeMasterProductLargeCategoryNameSchema = yup.string().required().label("schema.master.product.large.category.label.name");
export type TypeMasterProductLargeCategoryNameSchemaType = InferType<typeof typeMasterProductLargeCategoryNameSchema>;
export const typeMasterProductLotIdSchema = yup.number().required().label("schema.master.lot.label.id").integer();
export type TypeMasterProductLotIdSchemaType = InferType<typeof typeMasterProductLotIdSchema>;
export const typeMasterProductLotNameSchema = yup.string().required().label("schema.master.lot.label.name");
export type TypeMasterProductLotNameSchemaType = InferType<typeof typeMasterProductLotNameSchema>;
export const typeMasterProductLotFreshnessDateSchema = yup.date().required().label("schema.master.lot.label.freshness.date");
export type TypeMasterProductLotFreshnessDateSchemaType = InferType<typeof typeMasterProductLotFreshnessDateSchema>;
export const typeMasterProductPartNumberCategoryIdSchema = yup.number().required().label("schema.master.partNumber.category.label.id").integer();
export type TypeMasterProductPartNumberCategoryIdSchemaType = InferType<typeof typeMasterProductPartNumberCategoryIdSchema>;
export const typeMasterProductPartNumberCategoryCodeSchema = yup.string().required().label("schema.master.partNumber.category.label.code");
export type TypeMasterProductPartNumberCategoryCodeSchemaType = InferType<typeof typeMasterProductPartNumberCategoryCodeSchema>;
export const typeMasterProductPartNumberCategoryNameSchema = yup.string().required().label("schema.master.partNumber.category.label.name");
export type TypeMasterProductPartNumberCategoryNameSchemaType = InferType<typeof typeMasterProductPartNumberCategoryNameSchema>;
export const typeMasterProductPartNumberIdSchema = yup.number().required().label("schema.master.partNumber.label.id").integer();
export type TypeMasterProductPartNumberIdSchemaType = InferType<typeof typeMasterProductPartNumberIdSchema>;
export const typeMasterProductPartNumberCodeSchema = yup.string().required().label("schema.master.partNumber.label.code");
export type TypeMasterProductPartNumberCodeSchemaType = InferType<typeof typeMasterProductPartNumberCodeSchema>;
export const typeMasterProductPartNumberNameSchema = yup.string().required().label("schema.master.partNumber.label.name");
export type TypeMasterProductPartNumberNameSchemaType = InferType<typeof typeMasterProductPartNumberNameSchema>;
export const typeMasterProductPartNumberSupplierPartNumberSchema = yup.string().required().label("schema.master.partNumber.label.supplier.partNumber");
export type TypeMasterProductPartNumberSupplierPartNumberSchemaType = InferType<typeof typeMasterProductPartNumberSupplierPartNumberSchema>;
export const typeMasterProductPartNumberQuantityUnitSchema = yup.string().required().label("schema.master.partNumber.label.quantity.unit");
export type TypeMasterProductPartNumberQuantityUnitSchemaType = InferType<typeof typeMasterProductPartNumberQuantityUnitSchema>;
export const typeMasterProductPartNumberQuantityPerCaseSchema = yup.number().required().label("schema.master.partNumber.label.case.quantity.per.carton").integer();
export type TypeMasterProductPartNumberQuantityPerCaseSchemaType = InferType<typeof typeMasterProductPartNumberQuantityPerCaseSchema>;
export const typeMasterProductPartNumberLocationAllocationMinimumQuantitySchema = yup.number().required().label("schema.master.partNumber.label.location.allocation.minimum.quantity").integer();
export type TypeMasterProductPartNumberLocationAllocationMinimumQuantitySchemaType = InferType<typeof typeMasterProductPartNumberLocationAllocationMinimumQuantitySchema>;
export const typeMasterProductPartNumberCategoryId1Schema = typeMasterProductPartNumberCategoryIdSchema.label("schema.master.partNumber.category.label.id1").integer();
export type TypeMasterProductPartNumberCategoryId1SchemaType = InferType<typeof typeMasterProductPartNumberCategoryId1Schema>;
export const typeMasterProductPartNumberCategoryId2Schema = typeMasterProductPartNumberCategoryIdSchema.label("schema.master.partNumber.category.label.id2").integer();
export type TypeMasterProductPartNumberCategoryId2SchemaType = InferType<typeof typeMasterProductPartNumberCategoryId2Schema>;
export const typeMasterProductPartNumberCategoryId3Schema = typeMasterProductPartNumberCategoryIdSchema.label("schema.master.partNumber.category.label.id3").integer();
export type TypeMasterProductPartNumberCategoryId3SchemaType = InferType<typeof typeMasterProductPartNumberCategoryId3Schema>;
export const typeMasterProductSkuGroupIdSchema = yup.number().required().label("schema.master.sku.group.label.id").integer();
export type TypeMasterProductSkuGroupIdSchemaType = InferType<typeof typeMasterProductSkuGroupIdSchema>;
export const typeMasterProductSkuGroupCodeSchema = yup.string().required().label("schema.master.sku.group.label.code");
export type TypeMasterProductSkuGroupCodeSchemaType = InferType<typeof typeMasterProductSkuGroupCodeSchema>;
export const typeMasterProductSkuGroupNameSchema = yup.string().required().label("schema.master.sku.group.label.name");
export type TypeMasterProductSkuGroupNameSchemaType = InferType<typeof typeMasterProductSkuGroupNameSchema>;
export const typeMasterProductSkuIdSchema = yup.number().required().label("schema.master.sku.label.id").integer();
export type TypeMasterProductSkuIdSchemaType = InferType<typeof typeMasterProductSkuIdSchema>;
export const typeMasterProductSkuCodeSchema = yup.string().required().label("schema.master.sku.label.code");
export type TypeMasterProductSkuCodeSchemaType = InferType<typeof typeMasterProductSkuCodeSchema>;
export const typeMasterProductSkuNameSchema = yup.string().required().label("schema.master.sku.label.name");
export type TypeMasterProductSkuNameSchemaType = InferType<typeof typeMasterProductSkuNameSchema>;
export const typeMasterProductSmallCategoryIdSchema = yup.number().required().label("schema.master.product.small.category.label.id").integer();
export type TypeMasterProductSmallCategoryIdSchemaType = InferType<typeof typeMasterProductSmallCategoryIdSchema>;
export const typeMasterProductSmallCategoryCodeSchema = yup.string().required().label("schema.master.product.small.category.label.code");
export type TypeMasterProductSmallCategoryCodeSchemaType = InferType<typeof typeMasterProductSmallCategoryCodeSchema>;
export const typeMasterProductSmallCategoryNameSchema = yup.string().required().label("schema.master.product.small.category.label.name");
export type TypeMasterProductSmallCategoryNameSchemaType = InferType<typeof typeMasterProductSmallCategoryNameSchema>;
export const typeMasterProductStatusIdSchema = yup.number().required().label("schema.master.product.status.label.id").integer();
export type TypeMasterProductStatusIdSchemaType = InferType<typeof typeMasterProductStatusIdSchema>;
export const typeMasterProductStatusCodeSchema = yup.string().required().label("schema.master.product.status.label.code");
export type TypeMasterProductStatusCodeSchemaType = InferType<typeof typeMasterProductStatusCodeSchema>;
export const typeMasterProductStatusNameSchema = yup.string().required().label("schema.master.product.status.label.name");
export type TypeMasterProductStatusNameSchemaType = InferType<typeof typeMasterProductStatusNameSchema>;
export const typeMasterProductIdSchema = yup.number().required().label("schema.master.product.label.id").integer();
export type TypeMasterProductIdSchemaType = InferType<typeof typeMasterProductIdSchema>;
export const typeMasterProductCodeSchema = yup.string().required().label("schema.master.product.label.code");
export type TypeMasterProductCodeSchemaType = InferType<typeof typeMasterProductCodeSchema>;
export const typeMasterProductNameSchema = yup.string().required().label("schema.master.product.label.name");
export type TypeMasterProductNameSchemaType = InferType<typeof typeMasterProductNameSchema>;
export const typeMasterProductJanCodeSchema = yup.string().required().label("schema.master.product.label.jan");
export type TypeMasterProductJanCodeSchemaType = InferType<typeof typeMasterProductJanCodeSchema>;
export const typeMasterProductThumbnailSchema = yup.string().required().label("schema.master.product.label.thumbnail");
export type TypeMasterProductThumbnailSchemaType = InferType<typeof typeMasterProductThumbnailSchema>;
export const typeMasterSearchConditionIdSchema = yup.number().required().label("schema.master.searchCondition.label.id").integer();
export type TypeMasterSearchConditionIdSchemaType = InferType<typeof typeMasterSearchConditionIdSchema>;
export const typeMasterSearchConditionCodeSchema = yup.string().required().label("schema.master.searchCondition.label.code");
export type TypeMasterSearchConditionCodeSchemaType = InferType<typeof typeMasterSearchConditionCodeSchema>;
export const typeMasterSearchConditionNameSchema = yup.string().required().label("schema.master.searchCondition.label.name");
export type TypeMasterSearchConditionNameSchemaType = InferType<typeof typeMasterSearchConditionNameSchema>;
export const typeMasterShipperContractDateSchema = yup.date().required().label("schema.master.shipper.contract.label.date");
export type TypeMasterShipperContractDateSchemaType = InferType<typeof typeMasterShipperContractDateSchema>;
export const typeMasterShipperContractOperationStartDateSchema = yup.date().required().label("schema.master.shipper.contract.label.start.date");
export type TypeMasterShipperContractOperationStartDateSchemaType = InferType<typeof typeMasterShipperContractOperationStartDateSchema>;
export const typeMasterShipperContractWithdrawalDateSchema = yup.date().required().label("schema.master.shipper.contract.label.withdrawal.date");
export type TypeMasterShipperContractWithdrawalDateSchemaType = InferType<typeof typeMasterShipperContractWithdrawalDateSchema>;
export const typeMasterShipperIdSchema = yup.number().required().label("schema.master.shipper.label.id").integer();
export type TypeMasterShipperIdSchemaType = InferType<typeof typeMasterShipperIdSchema>;
export const typeMasterShipperCodeSchema = yup.string().required().label("schema.master.shipper.label.code");
export type TypeMasterShipperCodeSchemaType = InferType<typeof typeMasterShipperCodeSchema>;
export const typeMasterShipperNameSchema = yup.string().required().label("schema.master.shipper.label.name");
export type TypeMasterShipperNameSchemaType = InferType<typeof typeMasterShipperNameSchema>;
export const typeMasterUserAuthorityIdSchema = yup.number().required().label("schema.master.user.authority.label.id").integer();
export type TypeMasterUserAuthorityIdSchemaType = InferType<typeof typeMasterUserAuthorityIdSchema>;
export const typeMasterUserAuthorityCodeSchema = yup.string().required().label("schema.master.user.authority.label.code");
export type TypeMasterUserAuthorityCodeSchemaType = InferType<typeof typeMasterUserAuthorityCodeSchema>;
export const typeMasterUserAuthorityNameSchema = yup.string().required().label("schema.master.user.authority.label.name");
export type TypeMasterUserAuthorityNameSchemaType = InferType<typeof typeMasterUserAuthorityNameSchema>;
export const typeMasterUserCodeSchema = yup.string().required().label("schema.master.user.label.code");
export type TypeMasterUserCodeSchemaType = InferType<typeof typeMasterUserCodeSchema>;
export const typeMasterUserNameSchema = yup.string().required().label("schema.master.user.label.name");
export type TypeMasterUserNameSchemaType = InferType<typeof typeMasterUserNameSchema>;
export const typeMasterUserPasswordSchema = yup.string().required().label("schema.common.label.password");
export type TypeMasterUserPasswordSchemaType = InferType<typeof typeMasterUserPasswordSchema>;
export const typeMasterWarehouseAisleIdSchema = yup.number().required().label("schema.master.aisle.label.id").integer();
export type TypeMasterWarehouseAisleIdSchemaType = InferType<typeof typeMasterWarehouseAisleIdSchema>;
export const typeMasterWarehouseAisleCodeSchema = yup.string().required().label("schema.master.aisle.label.code");
export type TypeMasterWarehouseAisleCodeSchemaType = InferType<typeof typeMasterWarehouseAisleCodeSchema>;
export const typeMasterWarehouseAisleNameSchema = yup.string().required().label("schema.master.aisle.label.name");
export type TypeMasterWarehouseAisleNameSchemaType = InferType<typeof typeMasterWarehouseAisleNameSchema>;
export const typeMasterWarehouseCategoryIdSchema = yup.number().required().label("schema.master.warehouse.category.label.id").integer();
export type TypeMasterWarehouseCategoryIdSchemaType = InferType<typeof typeMasterWarehouseCategoryIdSchema>;
export const typeMasterWarehouseCategoryCodeSchema = yup.string().required().label("schema.master.warehouse.category.label.code");
export type TypeMasterWarehouseCategoryCodeSchemaType = InferType<typeof typeMasterWarehouseCategoryCodeSchema>;
export const typeMasterWarehouseCategoryNameSchema = yup.string().required().label("schema.master.warehouse.category.label.name");
export type TypeMasterWarehouseCategoryNameSchemaType = InferType<typeof typeMasterWarehouseCategoryNameSchema>;
export const typeMasterWarehouseLocationCategoryIdSchema = yup.number().required().label("schema.master.location.category.label.id").integer();
export type TypeMasterWarehouseLocationCategoryIdSchemaType = InferType<typeof typeMasterWarehouseLocationCategoryIdSchema>;
export const typeMasterWarehouseLocationCategoryCodeSchema = yup.string().required().label("schema.master.location.category.label.code");
export type TypeMasterWarehouseLocationCategoryCodeSchemaType = InferType<typeof typeMasterWarehouseLocationCategoryCodeSchema>;
export const typeMasterWarehouseLocationCategoryNameSchema = yup.string().required().label("schema.master.location.category.label.name");
export type TypeMasterWarehouseLocationCategoryNameSchemaType = InferType<typeof typeMasterWarehouseLocationCategoryNameSchema>;
export const typeMasterWarehouseLocationCategoryAllocationPriorityFlagSchema = yup.boolean().required().label("schema.master.location.label.flag.allocation.priority");
export type TypeMasterWarehouseLocationCategoryAllocationPriorityFlagSchemaType = InferType<typeof typeMasterWarehouseLocationCategoryAllocationPriorityFlagSchema>;
export const typeMasterWarehouseLocationCategoryAllocationImpossibleFlagSchema = yup.boolean().required().label("schema.master.location.label.flag.allocation.impossible");
export type TypeMasterWarehouseLocationCategoryAllocationImpossibleFlagSchemaType = InferType<typeof typeMasterWarehouseLocationCategoryAllocationImpossibleFlagSchema>;
export const typeMasterWarehouseLocationCategoryMovementProhibitedFlagSchema = yup.boolean().required().label("schema.master.location.label.flag.movement.prohibited");
export type TypeMasterWarehouseLocationCategoryMovementProhibitedFlagSchemaType = InferType<typeof typeMasterWarehouseLocationCategoryMovementProhibitedFlagSchema>;
export const typeMasterWarehouseLocationCategoryAutomaticAllocationDisabledFlagSchema = yup.boolean().required().label("schema.master.location.label.flag.automatic.allocation.disabled");
export type TypeMasterWarehouseLocationCategoryAutomaticAllocationDisabledFlagSchemaType = InferType<typeof typeMasterWarehouseLocationCategoryAutomaticAllocationDisabledFlagSchema>;
export const typeMasterWarehouseLocationCategoryTotalPickingFlagSchema = yup.boolean().required().label("schema.master.location.label.flag.total.picking");
export type TypeMasterWarehouseLocationCategoryTotalPickingFlagSchemaType = InferType<typeof typeMasterWarehouseLocationCategoryTotalPickingFlagSchema>;
export const typeMasterWarehouseLocationDetailIdSchema = yup.number().required().label("schema.master.location.detail.label.id").integer();
export type TypeMasterWarehouseLocationDetailIdSchemaType = InferType<typeof typeMasterWarehouseLocationDetailIdSchema>;
export const typeMasterWarehouseLocationDetailCodeSchema = yup.string().required().label("schema.master.location.detail.label.code");
export type TypeMasterWarehouseLocationDetailCodeSchemaType = InferType<typeof typeMasterWarehouseLocationDetailCodeSchema>;
export const typeMasterWarehouseLocationDetailNameSchema = yup.string().required().label("schema.master.location.detail.label.name");
export type TypeMasterWarehouseLocationDetailNameSchemaType = InferType<typeof typeMasterWarehouseLocationDetailNameSchema>;
export const typeMasterWarehouseLocationDetailAllocationPrioritySchema = yup.number().required().label("schema.master.location.detail.label.allocation.priority").integer();
export type TypeMasterWarehouseLocationDetailAllocationPrioritySchemaType = InferType<typeof typeMasterWarehouseLocationDetailAllocationPrioritySchema>;
export const typeMasterWarehouseLocationDetailMixedLocationFlagSchema = yup.boolean().required().label("schema.master.location.detail.label.mixed.location.flag");
export type TypeMasterWarehouseLocationDetailMixedLocationFlagSchemaType = InferType<typeof typeMasterWarehouseLocationDetailMixedLocationFlagSchema>;
export const typeMasterWarehouseLocationDetailDepthMSchema = yup.number().required().label("schema.master.location.detail.label.depthM").integer();
export type TypeMasterWarehouseLocationDetailDepthMSchemaType = InferType<typeof typeMasterWarehouseLocationDetailDepthMSchema>;
export const typeMasterWarehouseLocationDetailWidthMSchema = yup.number().required().label("schema.master.location.detail.label.widthM").integer();
export type TypeMasterWarehouseLocationDetailWidthMSchemaType = InferType<typeof typeMasterWarehouseLocationDetailWidthMSchema>;
export const typeMasterWarehouseLocationDetailHeightMSchema = yup.number().required().label("schema.master.location.detail.label.heightM").integer();
export type TypeMasterWarehouseLocationDetailHeightMSchemaType = InferType<typeof typeMasterWarehouseLocationDetailHeightMSchema>;
export const typeMasterWarehouseLocationIdSchema = yup.number().required().label("schema.master.location.label.id").integer();
export type TypeMasterWarehouseLocationIdSchemaType = InferType<typeof typeMasterWarehouseLocationIdSchema>;
export const typeMasterWarehouseLocationCodeSchema = yup.string().required().label("schema.master.location.label.code");
export type TypeMasterWarehouseLocationCodeSchemaType = InferType<typeof typeMasterWarehouseLocationCodeSchema>;
export const typeMasterWarehouseLocationNameSchema = yup.string().required().label("schema.master.location.label.name");
export type TypeMasterWarehouseLocationNameSchemaType = InferType<typeof typeMasterWarehouseLocationNameSchema>;
export const typeMasterWarehouseStoringConditionIdSchema = yup.number().required().label("schema.master.storingCondition.label.id").integer();
export type TypeMasterWarehouseStoringConditionIdSchemaType = InferType<typeof typeMasterWarehouseStoringConditionIdSchema>;
export const typeMasterWarehouseStoringConditionCodeSchema = yup.string().required().label("schema.master.storingCondition.label.code");
export type TypeMasterWarehouseStoringConditionCodeSchemaType = InferType<typeof typeMasterWarehouseStoringConditionCodeSchema>;
export const typeMasterWarehouseStoringConditionNameSchema = yup.string().required().label("schema.master.storingCondition.label.name");
export type TypeMasterWarehouseStoringConditionNameSchemaType = InferType<typeof typeMasterWarehouseStoringConditionNameSchema>;
export const typeMasterWarehouseStoringConditionDifferenceFlagSchema = yup.boolean().required();
export type TypeMasterWarehouseStoringConditionDifferenceFlagSchemaType = InferType<typeof typeMasterWarehouseStoringConditionDifferenceFlagSchema>;
export const typeMasterWarehouseIdSchema = yup.number().required().label("schema.master.warehouse.label.id").integer();
export type TypeMasterWarehouseIdSchemaType = InferType<typeof typeMasterWarehouseIdSchema>;
export const typeMasterWarehouseCodeSchema = yup.string().required().label("schema.master.warehouse.label.code");
export type TypeMasterWarehouseCodeSchemaType = InferType<typeof typeMasterWarehouseCodeSchema>;
export const typeMasterWarehouseNameSchema = yup.string().required().label("schema.master.warehouse.label.name");
export type TypeMasterWarehouseNameSchemaType = InferType<typeof typeMasterWarehouseNameSchema>;
