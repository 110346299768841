// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const typeSystemMaintenanceCompanyCodeSchema = yup.string().required().label("schema.system.maintenance.company.label.code");
export type TypeSystemMaintenanceCompanyCodeSchemaType = InferType<typeof typeSystemMaintenanceCompanyCodeSchema>;
export const typeSystemMaintenanceCompanyNameSchema = yup.string().required().label("schema.system.maintenance.company.label.name");
export type TypeSystemMaintenanceCompanyNameSchemaType = InferType<typeof typeSystemMaintenanceCompanyNameSchema>;
export const typeSystemMaintenanceFeatureCategoryIdSchema = yup.number().required().label("schema.system.maintenance.feature.category.label.id").integer();
export type TypeSystemMaintenanceFeatureCategoryIdSchemaType = InferType<typeof typeSystemMaintenanceFeatureCategoryIdSchema>;
export const typeSystemMaintenanceFeatureCategoryCodeSchema = yup.string().required().label("schema.system.maintenance.feature.category.label.code");
export type TypeSystemMaintenanceFeatureCategoryCodeSchemaType = InferType<typeof typeSystemMaintenanceFeatureCategoryCodeSchema>;
export const typeSystemMaintenanceFeatureCategoryNameSchema = yup.string().required().label("schema.system.maintenance.feature.category.label.name");
export type TypeSystemMaintenanceFeatureCategoryNameSchemaType = InferType<typeof typeSystemMaintenanceFeatureCategoryNameSchema>;
export const typeSystemMaintenanceFeatureCategoryTerminalTypeSchema = yup.number().required().label("schema.system.maintenance.feature.category.label.terminal.type").integer();
export type TypeSystemMaintenanceFeatureCategoryTerminalTypeSchemaType = InferType<typeof typeSystemMaintenanceFeatureCategoryTerminalTypeSchema>;
export const typeSystemMaintenanceFeatureCategoryForDeveloperSchema = yup.boolean().required().label("schema.system.maintenance.feature.category.label.forDeveloper");
export type TypeSystemMaintenanceFeatureCategoryForDeveloperSchemaType = InferType<typeof typeSystemMaintenanceFeatureCategoryForDeveloperSchema>;
export const typeSystemMaintenanceFeatureIdSchema = yup.number().required().label("schema.system.maintenance.feature.label.id").integer();
export type TypeSystemMaintenanceFeatureIdSchemaType = InferType<typeof typeSystemMaintenanceFeatureIdSchema>;
export const typeSystemMaintenanceFeatureCodeSchema = yup.string().required().label("schema.system.maintenance.feature.label.code");
export type TypeSystemMaintenanceFeatureCodeSchemaType = InferType<typeof typeSystemMaintenanceFeatureCodeSchema>;
export const typeSystemMaintenanceFeatureNameSchema = yup.string().required().label("schema.system.maintenance.feature.label.name");
export type TypeSystemMaintenanceFeatureNameSchemaType = InferType<typeof typeSystemMaintenanceFeatureNameSchema>;
export const typeSystemMaintenanceFeatureCategoryId1Schema = typeSystemMaintenanceFeatureCategoryIdSchema.label("schema.system.maintenance.feature.category.label.id1").integer();
export type TypeSystemMaintenanceFeatureCategoryId1SchemaType = InferType<typeof typeSystemMaintenanceFeatureCategoryId1Schema>;
export const typeSystemMaintenanceFeatureCategoryId2Schema = typeSystemMaintenanceFeatureCategoryIdSchema.label("schema.system.maintenance.feature.category.label.id2").integer();
export type TypeSystemMaintenanceFeatureCategoryId2SchemaType = InferType<typeof typeSystemMaintenanceFeatureCategoryId2Schema>;
export const typeSystemMaintenanceFeatureCategoryId3Schema = typeSystemMaintenanceFeatureCategoryIdSchema.label("schema.system.maintenance.feature.category.label.id3").integer();
export type TypeSystemMaintenanceFeatureCategoryId3SchemaType = InferType<typeof typeSystemMaintenanceFeatureCategoryId3Schema>;
export const typeSystemMaintenanceRegionCountryIdSchema = yup.number().required().label("schema.system.maintenance.region.country.label.id").integer();
export type TypeSystemMaintenanceRegionCountryIdSchemaType = InferType<typeof typeSystemMaintenanceRegionCountryIdSchema>;
export const typeSystemMaintenanceRegionCountryCodeSchema = yup.string().required().label("schema.system.maintenance.region.country.label.code");
export type TypeSystemMaintenanceRegionCountryCodeSchemaType = InferType<typeof typeSystemMaintenanceRegionCountryCodeSchema>;
export const typeSystemMaintenanceRegionCountryNameSchema = yup.string().required().label("schema.system.maintenance.region.country.label.name");
export type TypeSystemMaintenanceRegionCountryNameSchemaType = InferType<typeof typeSystemMaintenanceRegionCountryNameSchema>;
export const typeSystemMaintenanceRegionPrefectureIdSchema = yup.number().required().label("schema.system.maintenance.region.prefecture.label.id").integer();
export type TypeSystemMaintenanceRegionPrefectureIdSchemaType = InferType<typeof typeSystemMaintenanceRegionPrefectureIdSchema>;
export const typeSystemMaintenanceRegionPrefectureCodeSchema = yup.string().required().label("schema.system.maintenance.region.prefecture.label.code");
export type TypeSystemMaintenanceRegionPrefectureCodeSchemaType = InferType<typeof typeSystemMaintenanceRegionPrefectureCodeSchema>;
export const typeSystemMaintenanceRegionPrefectureNameSchema = yup.string().required().label("schema.system.maintenance.region.prefecture.label.name");
export type TypeSystemMaintenanceRegionPrefectureNameSchemaType = InferType<typeof typeSystemMaintenanceRegionPrefectureNameSchema>;
