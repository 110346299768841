// fv-generatorで生成されました
import * as yup from "yup";
import { typeReceivingScheduledDateSchema, typeReceivingDateSchema, typeReceivingCodeSchema, typeReceivingNameSchema, typeReceivingCategoryIdSchema } from "./../../receiving";
import { InferType } from "yup";
import { typeSearchItemsSchema } from "./../../search";

export const formReceivingReceivingListReceivingScheduledDateFromSchema = typeReceivingScheduledDateSchema.optional();
export type FormReceivingReceivingListReceivingScheduledDateFromSchemaType = InferType<typeof formReceivingReceivingListReceivingScheduledDateFromSchema>;
export const formReceivingReceivingListReceivingScheduledDateToSchema = typeReceivingScheduledDateSchema.optional();
export type FormReceivingReceivingListReceivingScheduledDateToSchemaType = InferType<typeof formReceivingReceivingListReceivingScheduledDateToSchema>;
export const formReceivingReceivingListReceivingDateFromSchema = typeReceivingDateSchema.optional();
export type FormReceivingReceivingListReceivingDateFromSchemaType = InferType<typeof formReceivingReceivingListReceivingDateFromSchema>;
export const formReceivingReceivingListReceivingDateToSchema = typeReceivingDateSchema.optional();
export type FormReceivingReceivingListReceivingDateToSchemaType = InferType<typeof formReceivingReceivingListReceivingDateToSchema>;
export const formReceivingReceivingListReceiveIssueCodeSchema = typeReceivingCodeSchema.optional();
export type FormReceivingReceivingListReceiveIssueCodeSchemaType = InferType<typeof formReceivingReceivingListReceiveIssueCodeSchema>;
export const formReceivingReceivingListReceiveIssueNameSchema = typeReceivingNameSchema.optional();
export type FormReceivingReceivingListReceiveIssueNameSchemaType = InferType<typeof formReceivingReceivingListReceiveIssueNameSchema>;
export const formReceivingReceivingListReceiveIssueCategoryIdSchema = typeReceivingCategoryIdSchema.optional();
export type FormReceivingReceivingListReceiveIssueCategoryIdSchemaType = InferType<typeof formReceivingReceivingListReceiveIssueCategoryIdSchema>;
export const formReceivingReceivingListSearchItemsSchema = typeSearchItemsSchema;
export type FormReceivingReceivingListSearchItemsSchemaType = InferType<typeof formReceivingReceivingListSearchItemsSchema>;
export const formReceivingReceivingListSchema = yup.object({
	receivingScheduledDateFrom: formReceivingReceivingListReceivingScheduledDateFromSchema,
	receivingScheduledDateTo: formReceivingReceivingListReceivingScheduledDateToSchema,
	receivingDateFrom: formReceivingReceivingListReceivingDateFromSchema,
	receivingDateTo: formReceivingReceivingListReceivingDateToSchema,
	receiveIssueCode: formReceivingReceivingListReceiveIssueCodeSchema,
	receiveIssueName: formReceivingReceivingListReceiveIssueNameSchema,
	receiveIssueCategoryId: formReceivingReceivingListReceiveIssueCategoryIdSchema,
	searchItems: formReceivingReceivingListSearchItemsSchema,
}).required();
export type FormReceivingReceivingListSchemaType = InferType<typeof formReceivingReceivingListSchema>;
