import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterDeliveryCompanyBaseEntrySchema, formMasterDeliveryCompanyBaseListSchema } from '../../../schema/form/master/deliveryCompany';

export type DeliveryCompanyBaseListResponse = MasterResponse & {
	deliveryCompanyBaseId: number; // 配送拠点ID
	deliveryCompanyBaseCode: string; // 配送拠点コード
	deliveryCompanyBaseName: string; // 配送拠点名
	deliveryCompanyId: number; // 配送業者ID
	deliveryCompanyCode: string; // 配送業者コード
	deliveryCompanyName: string; // 配送業者名
	deliveryCompanyBasePostalCode: string | undefined; // 郵便番号
	deliveryCompanyBaseAddress: string; // 住所
	deliveryCompanyBaseTel: string | undefined; // TEL
	deliveryCompanyBaseFax: string | undefined; // FAX
	deliveryCompanyBaseCustomerCode: string | undefined; // 配送拠点顧客コード
	deliveryCompanyBaseNote: string | undefined; // 備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: DeliveryCompanyBaseListResponse[] }>();

const listApi = createApiInterface().request(formMasterDeliveryCompanyBaseListSchema).response<{
	list: DeliveryCompanyBaseListResponse[];
}>();

const createApi = createApiInterface().request(formMasterDeliveryCompanyBaseEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterDeliveryCompanyBaseEntrySchema }))
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const deliveryCompanyBaseApi = createApiInterfaceMap({
	['/v1/master/delivery/company-base/find']: findApi,
	['/v1/master/delivery/company-base/list']: listApi,
	['/v1/master/delivery/company-base/create']: createApi,
	['/v1/master/delivery/company-base/update']: updateApi,
	['/v1/master/delivery/company-base/delete']: deleteApi,
});
