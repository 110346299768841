export async function fetchTokenJSON() {
	const response = await fetch(`/api/v1/auth/token`, {
		method: 'GET',
		mode: 'cors',
		cache: 'no-cache',
		credentials: 'same-origin',
		redirect: 'follow',
		referrerPolicy: 'no-referrer',
	});
	const { token } = await response.json();
	return token;
}
