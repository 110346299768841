import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { array, number, object } from 'yup';
import { BasicResponse } from '../../response';

export type SagawaShippingSummaryLabelResponse = BasicResponse & {
	receiveIssueId: number; // 入出庫ID
	shippingDate: string // 出荷日

	deliveryCompanyContractAddress: string // ご依頼主住所
	deliveryCompanyContractName: string // ご依頼主名称
	deliveryCompanyContractTel: string // ご依頼主電話番号
	deliveryCompanyContractDisplayCode: string // お客様コード
	shippingCount: string // 総出荷件数
	packagingAmount: string // 総出荷個数
	salesOfficeName: string // 集荷営業所名
	salesOfficeAskPhoneNumber: string // お問い合せ電話番号
	// 入出庫明細
	sagawaShippingSummaryLabelDetail: SagawaShippingSummaryLabelDetailResponse[];
};

type SagawaShippingSummaryLabelDetailResponse = {
	deliveryCompanyContractAddress: string // ご依頼主住所
	deliveryCompanyContractName: string // ご依頼主名称
	deliveryCompanyContractTel: string // ご依頼主電話番号
	deliveryCompanyContractDisplayCode: string // お客様コード
	shippingCount: string // 総出荷件数
	packagingAmount: string // 総出荷個数
	salesOfficeName: string // 集荷営業所名
	salesOfficeAskPhoneNumber: string // お問い合せ電話番号
};

const listApi = createApiInterface()
	.request(object({ id: array().of(number().integer().required()) }))
	.response<{
		list: SagawaShippingSummaryLabelResponse[];
	}>();

export const sagawaShippingSummaryLabelApi = createApiInterfaceMap({
	['/v1/print/sagawa-shipping-summary-label/list']: listApi,
});
