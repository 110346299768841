// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const typeArrivalDateSchema = yup.date().required().label("schema.arrival.label.date");
export type TypeArrivalDateSchemaType = InferType<typeof typeArrivalDateSchema>;
export const typeArrivalScheduledDateSchema = yup.date().required().label("schema.arrival.label.scheduled.date");
export type TypeArrivalScheduledDateSchemaType = InferType<typeof typeArrivalScheduledDateSchema>;
export const typeArrivalIdSchema = yup.number().required().label("schema.arrival.label.id").integer();
export type TypeArrivalIdSchemaType = InferType<typeof typeArrivalIdSchema>;
export const typeArrivalCodeSchema = yup.string().required().label("schema.arrival.label.code");
export type TypeArrivalCodeSchemaType = InferType<typeof typeArrivalCodeSchema>;
export const typeArrivalQuantitySchema = yup.number().required().label("schema.arrival.label.quantity").integer();
export type TypeArrivalQuantitySchemaType = InferType<typeof typeArrivalQuantitySchema>;
export const typeArrivalScheduledQuantitySchema = yup.number().required().label("schema.arrival.label.scheduled.quantity").integer();
export type TypeArrivalScheduledQuantitySchemaType = InferType<typeof typeArrivalScheduledQuantitySchema>;
export const typeArrivalDetailIdSchema = yup.number().required().integer();
export type TypeArrivalDetailIdSchemaType = InferType<typeof typeArrivalDetailIdSchema>;
export const typeArrivalStatusIdSchema = yup.number().required().label("schema.arrival.label.status").integer();
export type TypeArrivalStatusIdSchemaType = InferType<typeof typeArrivalStatusIdSchema>;
export const typeArrivalCategoryIdSchema = yup.number().required().label("schema.arrival.category.label.id").integer();
export type TypeArrivalCategoryIdSchemaType = InferType<typeof typeArrivalCategoryIdSchema>;
export const typeArrivalInspectionBeforeArrivalLocationDetailIdSchema = yup.number().required().label("schema.arrival.label.inspection.location.detail.id").integer();
export type TypeArrivalInspectionBeforeArrivalLocationDetailIdSchemaType = InferType<typeof typeArrivalInspectionBeforeArrivalLocationDetailIdSchema>;
export const typeArrivalInspectionBeforeArrivalLocationDetailCodeSchema = yup.string().required().label("schema.arrival.label.inspection.location.detail.code");
export type TypeArrivalInspectionBeforeArrivalLocationDetailCodeSchemaType = InferType<typeof typeArrivalInspectionBeforeArrivalLocationDetailCodeSchema>;
export const typeArrivalInspectionBeforeArrivalLocationDetailNameSchema = yup.string().required().label("schema.arrival.label.inspection.location.detail.name");
export type TypeArrivalInspectionBeforeArrivalLocationDetailNameSchemaType = InferType<typeof typeArrivalInspectionBeforeArrivalLocationDetailNameSchema>;
export const typeArrivalArrivalLocationDetailIdSchema = yup.number().required().label("schema.arrival.label.location.detail.id").integer();
export type TypeArrivalArrivalLocationDetailIdSchemaType = InferType<typeof typeArrivalArrivalLocationDetailIdSchema>;
export const typeArrivalArrivalLocationDetailCodeSchema = yup.string().required().label("schema.arrival.label.location.detail.code");
export type TypeArrivalArrivalLocationDetailCodeSchemaType = InferType<typeof typeArrivalArrivalLocationDetailCodeSchema>;
export const typeArrivalArrivalLocationDetailNameSchema = yup.string().required().label("schema.arrival.label.location.detail.name");
export type TypeArrivalArrivalLocationDetailNameSchemaType = InferType<typeof typeArrivalArrivalLocationDetailNameSchema>;
