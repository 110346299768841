// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionNumberSchema } from "./../../common";
import { InferType } from "yup";

export const formStockSetProductInspectionSkuGroupIdSchema = typeCommonInspectionNumberSchema;
export type FormStockSetProductInspectionSkuGroupIdSchemaType = InferType<typeof formStockSetProductInspectionSkuGroupIdSchema>;
export const formStockSetProductInspectionSchema = yup.object({
	skuGroupId: formStockSetProductInspectionSkuGroupIdSchema,
}).required();
export type FormStockSetProductInspectionSchemaType = InferType<typeof formStockSetProductInspectionSchema>;
