// fv-generatorで生成されました
import * as yup from "yup";
import { typeArrivalDateSchema, typeArrivalScheduledDateSchema, typeArrivalCodeSchema, typeArrivalStatusIdSchema } from "./../../arrival";
import { InferType } from "yup";
import { typeMasterClientIdSchema, typeMasterClientCodeSchema, typeMasterClientNameSchema, typeMasterWarehouseIdSchema, typeMasterWarehouseCodeSchema, typeMasterWarehouseNameSchema } from "./../../master";
import { typeSearchItemsSchema } from "./../../search";

export const formArrivalArrivalListArrivalDateFromSchema = typeArrivalDateSchema.optional();
export type FormArrivalArrivalListArrivalDateFromSchemaType = InferType<typeof formArrivalArrivalListArrivalDateFromSchema>;
export const formArrivalArrivalListArrivalDateToSchema = typeArrivalDateSchema.optional();
export type FormArrivalArrivalListArrivalDateToSchemaType = InferType<typeof formArrivalArrivalListArrivalDateToSchema>;
export const formArrivalArrivalListArrivalScheduledDateFromSchema = typeArrivalScheduledDateSchema.optional();
export type FormArrivalArrivalListArrivalScheduledDateFromSchemaType = InferType<typeof formArrivalArrivalListArrivalScheduledDateFromSchema>;
export const formArrivalArrivalListArrivalScheduledDateToSchema = typeArrivalScheduledDateSchema.optional();
export type FormArrivalArrivalListArrivalScheduledDateToSchemaType = InferType<typeof formArrivalArrivalListArrivalScheduledDateToSchema>;
export const formArrivalArrivalListArrivalCodeSchema = typeArrivalCodeSchema.optional();
export type FormArrivalArrivalListArrivalCodeSchemaType = InferType<typeof formArrivalArrivalListArrivalCodeSchema>;
export const formArrivalArrivalListArrivalStatusIdSchema = typeArrivalStatusIdSchema.optional();
export type FormArrivalArrivalListArrivalStatusIdSchemaType = InferType<typeof formArrivalArrivalListArrivalStatusIdSchema>;
export const formArrivalArrivalListClientIdSchema = typeMasterClientIdSchema.optional();
export type FormArrivalArrivalListClientIdSchemaType = InferType<typeof formArrivalArrivalListClientIdSchema>;
export const formArrivalArrivalListClientCodeSchema = typeMasterClientCodeSchema.optional();
export type FormArrivalArrivalListClientCodeSchemaType = InferType<typeof formArrivalArrivalListClientCodeSchema>;
export const formArrivalArrivalListClientNameSchema = typeMasterClientNameSchema.optional();
export type FormArrivalArrivalListClientNameSchemaType = InferType<typeof formArrivalArrivalListClientNameSchema>;
export const formArrivalArrivalListWarehouseIdSchema = typeMasterWarehouseIdSchema.optional();
export type FormArrivalArrivalListWarehouseIdSchemaType = InferType<typeof formArrivalArrivalListWarehouseIdSchema>;
export const formArrivalArrivalListWarehouseCodeSchema = typeMasterWarehouseCodeSchema.optional();
export type FormArrivalArrivalListWarehouseCodeSchemaType = InferType<typeof formArrivalArrivalListWarehouseCodeSchema>;
export const formArrivalArrivalListWarehouseNameSchema = typeMasterWarehouseNameSchema.optional();
export type FormArrivalArrivalListWarehouseNameSchemaType = InferType<typeof formArrivalArrivalListWarehouseNameSchema>;
export const formArrivalArrivalListSearchItemsSchema = typeSearchItemsSchema;
export type FormArrivalArrivalListSearchItemsSchemaType = InferType<typeof formArrivalArrivalListSearchItemsSchema>;
export const formArrivalArrivalListSchema = yup.object({
	arrivalDateFrom: formArrivalArrivalListArrivalDateFromSchema,
	arrivalDateTo: formArrivalArrivalListArrivalDateToSchema,
	arrivalScheduledDateFrom: formArrivalArrivalListArrivalScheduledDateFromSchema,
	arrivalScheduledDateTo: formArrivalArrivalListArrivalScheduledDateToSchema,
	arrivalCode: formArrivalArrivalListArrivalCodeSchema,
	arrivalStatusId: formArrivalArrivalListArrivalStatusIdSchema,
	clientId: formArrivalArrivalListClientIdSchema,
	clientCode: formArrivalArrivalListClientCodeSchema,
	clientName: formArrivalArrivalListClientNameSchema,
	warehouseId: formArrivalArrivalListWarehouseIdSchema,
	warehouseCode: formArrivalArrivalListWarehouseCodeSchema,
	warehouseName: formArrivalArrivalListWarehouseNameSchema,
	searchItems: formArrivalArrivalListSearchItemsSchema,
}).required();
export type FormArrivalArrivalListSchemaType = InferType<typeof formArrivalArrivalListSchema>;
