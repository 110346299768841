import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { formIssuingIssuingListInputSchema } from '../../schema/form/issuing/issuingListInput';
import { ListResponse } from '../../response';
import { number, object } from 'yup';
import { formIssuingIssuingScheduleEntrySchema } from '../../schema/form/issuing/issuingScheduleEntry';
import { formIssuingIssuingResultEntrySchema } from '../../schema/form/issuing/issuingResultEntry';

export type IssuingListResponse = ListResponse & {
	receiveIssueId: number; // 入出庫ID
	receiveIssueCode: string; // 入出庫コード
	receiveIssueName: string; // 入出庫名
	receiveIssueCategoryId: number; // 入出庫区分ID
	receiveIssueCategoryCode: string; // 入出庫区分コード
	receiveIssueCategoryName: string; // 入出庫区分名
	issuingScheduledDate: string; // 出庫予定日
	issuingDate: string | undefined; // 出庫日
	receiveIssueNote: string; // 入出庫備考
	movingSourceLocationDetailId: number; // 移動元ロケーション明細ID
	movingSourceLocationDetailCode: string; // 移動元ロケーション明細コード
	movingSourceLocationDetailName: string; // 移動元ロケーション明細名
	destinationLocationDetailId: number | undefined; // 移動先ロケーション明細ID
	destinationLocationDetailCode: string | undefined; // 移動先ロケーション明細コード
	destinationLocationDetailName: string | undefined; // 移動先ロケーション明細名
};

export type IssuingDetailListResponse = IssuingListResponse & { issuingDetail: IssuingDetail[] };

export type IssuingDetail = {
	receiveIssueDetailId: number | undefined; // 入出庫明細ID
	receiveIssueDetailNote: string | undefined; // 入出庫明細備考
	issuingScheduledQuantity: number | undefined; // 出庫予定数量
	issuingQuantity: number | undefined; // 出庫数量
	skuId: number | undefined; // SKUID
	skuCode: number | undefined; // SKUコード
	productId: number | undefined; // 商品ID
	productCode: string | undefined; // 商品コード
	productName: string | undefined; // 商品名
};

// export type IssuingDetailListResponse = IssuingListResponse & {
// 	receiveIssueDetailId: number[] | undefined; // 入出庫明細ID
// 	receiveIssueDetailNote: string[] | undefined; // 入出庫明細備考
// 	issuingScheduledQuantity: number[] | undefined; // 出庫予定数量
// 	issuingQuantity: number[] | undefined; // 出庫数量
// 	skuId: number[] | undefined; // SKUID
// 	productId: number[] | undefined; // 商品ID
// };

/** 出庫一覧 */
const listApi = createApiInterface().request(formIssuingIssuingListInputSchema).response<{
	list: IssuingListResponse[];
}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

/** 出庫予定入力 */
const scheduleCreateApi = createApiInterface().request(formIssuingIssuingScheduleEntrySchema).response<{ isSuccess: boolean }>();

const scheduleUpdateApi = createApiInterface()
	.request(object({ id: number().integer().required(), form: formIssuingIssuingScheduleEntrySchema }))
	.response<{ isSuccess: boolean }>();

const scheduleFindApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: IssuingDetailListResponse[];
	}>();

/** 出庫実績入力 */
const resultCreateApi = createApiInterface().request(formIssuingIssuingResultEntrySchema).response<{
	isSuccess: boolean;
}>();

const resultUpdateApi = createApiInterface()
	.request(object({ id: number().integer().required(), form: formIssuingIssuingResultEntrySchema }))
	.response<{ isSuccess: boolean }>();

const resultFindApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: IssuingDetailListResponse[];
	}>();

export const issuingApi = createApiInterfaceMap({
	['/v1/issuing/list']: listApi,
	['/v1/issuing/delete']: deleteApi,
	['/v1/issuing/entry-schedule/create']: scheduleCreateApi,
	['/v1/issuing/entry-schedule/update']: scheduleUpdateApi,
	['/v1/issuing/entry-schedule/find']: scheduleFindApi,
	['/v1/issuing/entry-result/create']: resultCreateApi,
	['/v1/issuing/entry-result/update']: resultUpdateApi,
	['/v1/issuing/entry-result/find']: resultFindApi,
});
