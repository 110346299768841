let serviceWorker: ServiceWorkerContainer | null = null;

function registerServiceWorker() {
	if (process.env.NODE_ENV === 'development') {
		if (!('serviceWorker' in navigator)) {
			return false;
		}
		serviceWorker = navigator.serviceWorker;
		serviceWorker
			.register('/sw.js')
			.then((v) => {
				console.info('サービスワーカーの登録に成功しました', v);
			})
			.catch((e) => {
				console.error('サービスワーカーの登録に失敗しました', e);
			});
		serviceWorker.addEventListener('message', (event) => {
			if (event.data === 'requireReload') {
				window.location.reload();
			}
		});

		/*
		mode一覧
		dummy - サービスワーカーがそれっぽい返り値を返してくれる
		proxy - サーバーにリクエストします
		*/

		// @ts-ignore
		window.setServiceWorkerModeDummy = () => {
			serviceWorker?.controller?.postMessage('dummyMode');
			window.location.reload();
		};

		// @ts-ignore
		window.setServiceWorkerModeProxy = () => {
			serviceWorker?.controller?.postMessage('proxyMode');
			window.location.reload();
		};
	}
	return true;
}
export default registerServiceWorker;
