// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingPickingCodeSchema, typeShippingPickingNameSchema } from "./../../shipping";
import { InferType } from "yup";

export const formShippingShippingSinglePickingListPickingCodeSchema = typeShippingPickingCodeSchema.optional();
export type FormShippingShippingSinglePickingListPickingCodeSchemaType = InferType<typeof formShippingShippingSinglePickingListPickingCodeSchema>;
export const formShippingShippingSinglePickingListPickingNameSchema = typeShippingPickingNameSchema.optional();
export type FormShippingShippingSinglePickingListPickingNameSchemaType = InferType<typeof formShippingShippingSinglePickingListPickingNameSchema>;
export const formShippingShippingSinglePickingListSchema = yup.object({
	pickingCode: formShippingShippingSinglePickingListPickingCodeSchema,
	pickingName: formShippingShippingSinglePickingListPickingNameSchema,
}).required();
export type FormShippingShippingSinglePickingListSchemaType = InferType<typeof formShippingShippingSinglePickingListSchema>;
