import { object, number } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterProductPartNumberCategoryEntrySchema, formMasterProductPartNumberCategoryListSchema } from '../../../schema/form/master/product';

export type PartNumberCategoryResponse = MasterResponse & {
	partNumberCategoryId: number; // 品番区分Id
	partNumberCategoryCode: string; // 品番区分コード
	partNumberCategoryName: string; // 品番区分名
	storingConditionId: number; // 保管条件Id
	storingConditionCode: string; // 保管条件コード
	storingConditionName: string; // 保管条件名
	partNumberCategoryNote: string | undefined; // 備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: PartNumberCategoryResponse[] }>();

const listApi = createApiInterface().request(formMasterProductPartNumberCategoryListSchema).response<{
	list: PartNumberCategoryResponse[];
}>();

const createApi = createApiInterface().request(formMasterProductPartNumberCategoryEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterProductPartNumberCategoryEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const partNumberCategoryApi = createApiInterfaceMap({
	['/v1/master/product/part-number-category/find']: findApi,
	['/v1/master/product/part-number-category/list']: listApi,
	['/v1/master/product/part-number-category/create']: createApi,
	['/v1/master/product/part-number-category/update']: updateApi,
	['/v1/master/product/part-number-category/delete']: deleteApi,
});
