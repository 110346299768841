// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";
import { typeStockTakingScheduledDateSchema, typeStockTakingDateSchema } from "./../../stock";
import { typeMasterWarehouseLocationDetailIdSchema, typeMasterWarehouseLocationDetailCodeSchema, typeMasterWarehouseLocationDetailNameSchema } from "./../../master";

export const formStockStockTakingListInputChoiceIssueListSchema = yup.string().optional().label("schema.common.search.label.target");
export type FormStockStockTakingListInputChoiceIssueListSchemaType = InferType<typeof formStockStockTakingListInputChoiceIssueListSchema>;
export const formStockStockTakingListInputStockTakingScheduleDateSchema = typeStockTakingScheduledDateSchema.optional();
export type FormStockStockTakingListInputStockTakingScheduleDateSchemaType = InferType<typeof formStockStockTakingListInputStockTakingScheduleDateSchema>;
export const formStockStockTakingListInputStockTakingDateSchema = typeStockTakingDateSchema.optional();
export type FormStockStockTakingListInputStockTakingDateSchemaType = InferType<typeof formStockStockTakingListInputStockTakingDateSchema>;
export const formStockStockTakingListInputLocationDetailIdSchema = typeMasterWarehouseLocationDetailIdSchema.optional();
export type FormStockStockTakingListInputLocationDetailIdSchemaType = InferType<typeof formStockStockTakingListInputLocationDetailIdSchema>;
export const formStockStockTakingListInputLocationDetailCodeSchema = typeMasterWarehouseLocationDetailCodeSchema.optional();
export type FormStockStockTakingListInputLocationDetailCodeSchemaType = InferType<typeof formStockStockTakingListInputLocationDetailCodeSchema>;
export const formStockStockTakingListInputLocationDetailNameSchema = typeMasterWarehouseLocationDetailNameSchema.optional();
export type FormStockStockTakingListInputLocationDetailNameSchemaType = InferType<typeof formStockStockTakingListInputLocationDetailNameSchema>;
export const formStockStockTakingListInputSchema = yup.object({
	choiceIssueList: formStockStockTakingListInputChoiceIssueListSchema,
	stockTakingScheduleDate: formStockStockTakingListInputStockTakingScheduleDateSchema,
	stockTakingDate: formStockStockTakingListInputStockTakingDateSchema,
	locationDetailId: formStockStockTakingListInputLocationDetailIdSchema,
	locationDetailCode: formStockStockTakingListInputLocationDetailCodeSchema,
	locationDetailName: formStockStockTakingListInputLocationDetailNameSchema,
}).required();
export type FormStockStockTakingListInputSchemaType = InferType<typeof formStockStockTakingListInputSchema>;
