import { Backdrop, CircularProgress } from '@mui/material';
import { createContext, ReactNode, Suspense, TransitionStartFunction, useMemo, useTransition } from 'react';
import { boolean } from 'yup';

type LoadingContextValue = {
	isLoading: boolean;
	startTransition: TransitionStartFunction;
};

export const loadingContext = createContext<LoadingContextValue>({
	isLoading: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	startTransition: () => {},
});

// ページ遷移時の読み込みなど
function OverlayLoading() {
	return (
		<Backdrop
			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={true}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
}

// // 初回読み込み時など
// function FullScreenLoading() {
// 	return (
// 		<>
// 			<CircularProgress />
// 		</>
// 	);
// }

type LoadingProps = {
	children: ReactNode;
};
export function LoadingProvider({ children }: LoadingProps) {
	const [isLoading, startTransition] = useTransition();

	const Provider = loadingContext.Provider;

	const contextValue = useMemo(() => {
		return {
			isLoading,
			startTransition,
		};
	}, [isLoading, startTransition]);

	return (
		<Provider value={contextValue}>
			<Suspense fallback={<OverlayLoading />}>
				{children}
				{isLoading && <OverlayLoading />}
			</Suspense>
		</Provider>
	);
}
