// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterProductLargeCategoryCodeSchema, typeMasterProductLargeCategoryNameSchema, typeMasterProductPartNumberCategoryIdSchema, typeMasterProductPartNumberCategoryCodeSchema, typeMasterProductPartNumberCategoryNameSchema, typeMasterWarehouseStoringConditionIdSchema, typeMasterProductPartNumberCodeSchema, typeMasterProductPartNumberNameSchema, typeMasterProductPartNumberCategoryId1Schema, typeMasterProductPartNumberCategoryId2Schema, typeMasterProductPartNumberCategoryId3Schema, typeMasterShipperIdSchema, typeMasterShipperCodeSchema, typeMasterShipperNameSchema, typeMasterProductPartNumberSupplierPartNumberSchema, typeMasterProductPartNumberQuantityUnitSchema, typeMasterProductPartNumberQuantityPerCaseSchema, typeMasterProductPartNumberLocationAllocationMinimumQuantitySchema, typeMasterProductSkuGroupCodeSchema, typeMasterProductSkuGroupNameSchema, typeMasterProductSkuIdSchema, typeMasterProductLargeCategoryIdSchema, typeMasterProductSmallCategoryCodeSchema, typeMasterProductSmallCategoryNameSchema, typeMasterProductStatusCodeSchema, typeMasterProductStatusNameSchema, typeMasterProductCodeSchema, typeMasterProductNameSchema, typeMasterProductPartNumberIdSchema, typeMasterProductJanCodeSchema, typeMasterProductSmallCategoryIdSchema, typeMasterProductThumbnailSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonDisplayOrderSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema } from "./../../common";
import { typeSearchItemsSchema } from "./../../search";
import { typeDetailedConditionItemsSchema } from "./../../detailedCondition";

export const formMasterProductLargeCategoryListProductLargeCategoryCodeSchema = typeMasterProductLargeCategoryCodeSchema.optional();
export type FormMasterProductLargeCategoryListProductLargeCategoryCodeSchemaType = InferType<typeof formMasterProductLargeCategoryListProductLargeCategoryCodeSchema>;
export const formMasterProductLargeCategoryListProductLargeCategoryNameSchema = typeMasterProductLargeCategoryNameSchema.optional();
export type FormMasterProductLargeCategoryListProductLargeCategoryNameSchemaType = InferType<typeof formMasterProductLargeCategoryListProductLargeCategoryNameSchema>;
export const formMasterProductLargeCategoryListPartNumberCategoryIdSchema = typeMasterProductPartNumberCategoryIdSchema.optional();
export type FormMasterProductLargeCategoryListPartNumberCategoryIdSchemaType = InferType<typeof formMasterProductLargeCategoryListPartNumberCategoryIdSchema>;
export const formMasterProductLargeCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterProductLargeCategoryListIsAvailableSchemaType = InferType<typeof formMasterProductLargeCategoryListIsAvailableSchema>;
export const formMasterProductLargeCategoryListSchema = yup.object({
	productLargeCategoryCode: formMasterProductLargeCategoryListProductLargeCategoryCodeSchema,
	productLargeCategoryName: formMasterProductLargeCategoryListProductLargeCategoryNameSchema,
	partNumberCategoryId: formMasterProductLargeCategoryListPartNumberCategoryIdSchema,
	isAvailable: formMasterProductLargeCategoryListIsAvailableSchema,
}).required();
export type FormMasterProductLargeCategoryListSchemaType = InferType<typeof formMasterProductLargeCategoryListSchema>;
export const formMasterProductLargeCategoryEntryProductLargeCategoryCodeSchema = typeMasterProductLargeCategoryCodeSchema;
export type FormMasterProductLargeCategoryEntryProductLargeCategoryCodeSchemaType = InferType<typeof formMasterProductLargeCategoryEntryProductLargeCategoryCodeSchema>;
export const formMasterProductLargeCategoryEntryProductLargeCategoryNameSchema = typeMasterProductLargeCategoryNameSchema;
export type FormMasterProductLargeCategoryEntryProductLargeCategoryNameSchemaType = InferType<typeof formMasterProductLargeCategoryEntryProductLargeCategoryNameSchema>;
export const formMasterProductLargeCategoryEntryPartNumberCategoryIdSchema = typeMasterProductPartNumberCategoryIdSchema;
export type FormMasterProductLargeCategoryEntryPartNumberCategoryIdSchemaType = InferType<typeof formMasterProductLargeCategoryEntryPartNumberCategoryIdSchema>;
export const formMasterProductLargeCategoryEntryProductLargeCategoryDisplayOrderSchema = typeCommonDisplayOrderSchema.required();
export type FormMasterProductLargeCategoryEntryProductLargeCategoryDisplayOrderSchemaType = InferType<typeof formMasterProductLargeCategoryEntryProductLargeCategoryDisplayOrderSchema>;
export const formMasterProductLargeCategoryEntryProductLargeCategoryNoteSchema = typeCommonNoteSchema;
export type FormMasterProductLargeCategoryEntryProductLargeCategoryNoteSchemaType = InferType<typeof formMasterProductLargeCategoryEntryProductLargeCategoryNoteSchema>;
export const formMasterProductLargeCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterProductLargeCategoryEntryAvailableFromSchemaType = InferType<typeof formMasterProductLargeCategoryEntryAvailableFromSchema>;
export const formMasterProductLargeCategoryEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterProductLargeCategoryEntryAvailableToSchemaType = InferType<typeof formMasterProductLargeCategoryEntryAvailableToSchema>;
export const formMasterProductLargeCategoryEntrySchema = yup.object({
	productLargeCategoryCode: formMasterProductLargeCategoryEntryProductLargeCategoryCodeSchema,
	productLargeCategoryName: formMasterProductLargeCategoryEntryProductLargeCategoryNameSchema,
	partNumberCategoryId: formMasterProductLargeCategoryEntryPartNumberCategoryIdSchema,
	productLargeCategoryDisplayOrder: formMasterProductLargeCategoryEntryProductLargeCategoryDisplayOrderSchema,
	productLargeCategoryNote: formMasterProductLargeCategoryEntryProductLargeCategoryNoteSchema,
	availableFrom: formMasterProductLargeCategoryEntryAvailableFromSchema,
	availableTo: formMasterProductLargeCategoryEntryAvailableToSchema,
}).required();
export type FormMasterProductLargeCategoryEntrySchemaType = InferType<typeof formMasterProductLargeCategoryEntrySchema>;
export const formMasterProductPartNumberCategoryListPartNumberCategoryCodeSchema = typeMasterProductPartNumberCategoryCodeSchema.optional();
export type FormMasterProductPartNumberCategoryListPartNumberCategoryCodeSchemaType = InferType<typeof formMasterProductPartNumberCategoryListPartNumberCategoryCodeSchema>;
export const formMasterProductPartNumberCategoryListPartNumberCategoryNameSchema = typeMasterProductPartNumberCategoryNameSchema.optional();
export type FormMasterProductPartNumberCategoryListPartNumberCategoryNameSchemaType = InferType<typeof formMasterProductPartNumberCategoryListPartNumberCategoryNameSchema>;
export const formMasterProductPartNumberCategoryListStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema.optional();
export type FormMasterProductPartNumberCategoryListStoringConditionIdSchemaType = InferType<typeof formMasterProductPartNumberCategoryListStoringConditionIdSchema>;
export const formMasterProductPartNumberCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterProductPartNumberCategoryListIsAvailableSchemaType = InferType<typeof formMasterProductPartNumberCategoryListIsAvailableSchema>;
export const formMasterProductPartNumberCategoryListSchema = yup.object({
	partNumberCategoryCode: formMasterProductPartNumberCategoryListPartNumberCategoryCodeSchema,
	partNumberCategoryName: formMasterProductPartNumberCategoryListPartNumberCategoryNameSchema,
	storingConditionId: formMasterProductPartNumberCategoryListStoringConditionIdSchema,
	isAvailable: formMasterProductPartNumberCategoryListIsAvailableSchema,
}).required();
export type FormMasterProductPartNumberCategoryListSchemaType = InferType<typeof formMasterProductPartNumberCategoryListSchema>;
export const formMasterProductPartNumberCategoryEntryPartNumberCategoryCodeSchema = typeMasterProductPartNumberCategoryCodeSchema;
export type FormMasterProductPartNumberCategoryEntryPartNumberCategoryCodeSchemaType = InferType<typeof formMasterProductPartNumberCategoryEntryPartNumberCategoryCodeSchema>;
export const formMasterProductPartNumberCategoryEntryPartNumberCategoryNameSchema = typeMasterProductPartNumberCategoryNameSchema;
export type FormMasterProductPartNumberCategoryEntryPartNumberCategoryNameSchemaType = InferType<typeof formMasterProductPartNumberCategoryEntryPartNumberCategoryNameSchema>;
export const formMasterProductPartNumberCategoryEntryStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema;
export type FormMasterProductPartNumberCategoryEntryStoringConditionIdSchemaType = InferType<typeof formMasterProductPartNumberCategoryEntryStoringConditionIdSchema>;
export const formMasterProductPartNumberCategoryEntryPartNumberCategoryNoteSchema = typeCommonNoteSchema;
export type FormMasterProductPartNumberCategoryEntryPartNumberCategoryNoteSchemaType = InferType<typeof formMasterProductPartNumberCategoryEntryPartNumberCategoryNoteSchema>;
export const formMasterProductPartNumberCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterProductPartNumberCategoryEntryAvailableFromSchemaType = InferType<typeof formMasterProductPartNumberCategoryEntryAvailableFromSchema>;
export const formMasterProductPartNumberCategoryEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterProductPartNumberCategoryEntryAvailableToSchemaType = InferType<typeof formMasterProductPartNumberCategoryEntryAvailableToSchema>;
export const formMasterProductPartNumberCategoryEntrySchema = yup.object({
	partNumberCategoryCode: formMasterProductPartNumberCategoryEntryPartNumberCategoryCodeSchema,
	partNumberCategoryName: formMasterProductPartNumberCategoryEntryPartNumberCategoryNameSchema,
	storingConditionId: formMasterProductPartNumberCategoryEntryStoringConditionIdSchema,
	partNumberCategoryNote: formMasterProductPartNumberCategoryEntryPartNumberCategoryNoteSchema,
	availableFrom: formMasterProductPartNumberCategoryEntryAvailableFromSchema,
	availableTo: formMasterProductPartNumberCategoryEntryAvailableToSchema,
}).required();
export type FormMasterProductPartNumberCategoryEntrySchemaType = InferType<typeof formMasterProductPartNumberCategoryEntrySchema>;
export const formMasterProductPartNumberListPartNumberCodeSchema = typeMasterProductPartNumberCodeSchema.optional();
export type FormMasterProductPartNumberListPartNumberCodeSchemaType = InferType<typeof formMasterProductPartNumberListPartNumberCodeSchema>;
export const formMasterProductPartNumberListPartNumberNameSchema = typeMasterProductPartNumberNameSchema.optional();
export type FormMasterProductPartNumberListPartNumberNameSchemaType = InferType<typeof formMasterProductPartNumberListPartNumberNameSchema>;
export const formMasterProductPartNumberListPartNumberCategoryId1Schema = typeMasterProductPartNumberCategoryId1Schema.optional();
export type FormMasterProductPartNumberListPartNumberCategoryId1SchemaType = InferType<typeof formMasterProductPartNumberListPartNumberCategoryId1Schema>;
export const formMasterProductPartNumberListPartNumberCategoryId2Schema = typeMasterProductPartNumberCategoryId2Schema.optional();
export type FormMasterProductPartNumberListPartNumberCategoryId2SchemaType = InferType<typeof formMasterProductPartNumberListPartNumberCategoryId2Schema>;
export const formMasterProductPartNumberListPartNumberCategoryId3Schema = typeMasterProductPartNumberCategoryId3Schema.optional();
export type FormMasterProductPartNumberListPartNumberCategoryId3SchemaType = InferType<typeof formMasterProductPartNumberListPartNumberCategoryId3Schema>;
export const formMasterProductPartNumberListStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema.optional();
export type FormMasterProductPartNumberListStoringConditionIdSchemaType = InferType<typeof formMasterProductPartNumberListStoringConditionIdSchema>;
export const formMasterProductPartNumberListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterProductPartNumberListIsAvailableSchemaType = InferType<typeof formMasterProductPartNumberListIsAvailableSchema>;
export const formMasterProductPartNumberListSearchItemsSchema = typeSearchItemsSchema;
export type FormMasterProductPartNumberListSearchItemsSchemaType = InferType<typeof formMasterProductPartNumberListSearchItemsSchema>;
export const formMasterProductPartNumberListDetailedConditionsSchema = typeDetailedConditionItemsSchema;
export type FormMasterProductPartNumberListDetailedConditionsSchemaType = InferType<typeof formMasterProductPartNumberListDetailedConditionsSchema>;
export const formMasterProductPartNumberListSchema = yup.object({
	partNumberCode: formMasterProductPartNumberListPartNumberCodeSchema,
	partNumberName: formMasterProductPartNumberListPartNumberNameSchema,
	partNumberCategoryId1: formMasterProductPartNumberListPartNumberCategoryId1Schema,
	partNumberCategoryId2: formMasterProductPartNumberListPartNumberCategoryId2Schema,
	partNumberCategoryId3: formMasterProductPartNumberListPartNumberCategoryId3Schema,
	storingConditionId: formMasterProductPartNumberListStoringConditionIdSchema,
	isAvailable: formMasterProductPartNumberListIsAvailableSchema,
	searchItems: formMasterProductPartNumberListSearchItemsSchema,
	detailedConditions: formMasterProductPartNumberListDetailedConditionsSchema,
}).required();
export type FormMasterProductPartNumberListSchemaType = InferType<typeof formMasterProductPartNumberListSchema>;
export const formMasterProductPartNumberEntryPartNumberCodeSchema = typeMasterProductPartNumberCodeSchema;
export type FormMasterProductPartNumberEntryPartNumberCodeSchemaType = InferType<typeof formMasterProductPartNumberEntryPartNumberCodeSchema>;
export const formMasterProductPartNumberEntryPartNumberNameSchema = typeMasterProductPartNumberNameSchema;
export type FormMasterProductPartNumberEntryPartNumberNameSchemaType = InferType<typeof formMasterProductPartNumberEntryPartNumberNameSchema>;
export const formMasterProductPartNumberEntryShipperIdSchema = typeMasterShipperIdSchema;
export type FormMasterProductPartNumberEntryShipperIdSchemaType = InferType<typeof formMasterProductPartNumberEntryShipperIdSchema>;
export const formMasterProductPartNumberEntryShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormMasterProductPartNumberEntryShipperCodeSchemaType = InferType<typeof formMasterProductPartNumberEntryShipperCodeSchema>;
export const formMasterProductPartNumberEntryShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormMasterProductPartNumberEntryShipperNameSchemaType = InferType<typeof formMasterProductPartNumberEntryShipperNameSchema>;
export const formMasterProductPartNumberEntryPartNumberCategoryId1Schema = typeMasterProductPartNumberCategoryId1Schema;
export type FormMasterProductPartNumberEntryPartNumberCategoryId1SchemaType = InferType<typeof formMasterProductPartNumberEntryPartNumberCategoryId1Schema>;
export const formMasterProductPartNumberEntryPartNumberCategoryId2Schema = typeMasterProductPartNumberCategoryId2Schema.optional();
export type FormMasterProductPartNumberEntryPartNumberCategoryId2SchemaType = InferType<typeof formMasterProductPartNumberEntryPartNumberCategoryId2Schema>;
export const formMasterProductPartNumberEntryPartNumberCategoryId3Schema = typeMasterProductPartNumberCategoryId3Schema.optional();
export type FormMasterProductPartNumberEntryPartNumberCategoryId3SchemaType = InferType<typeof formMasterProductPartNumberEntryPartNumberCategoryId3Schema>;
export const formMasterProductPartNumberEntrySupplierPartNumberSchema = typeMasterProductPartNumberSupplierPartNumberSchema.optional();
export type FormMasterProductPartNumberEntrySupplierPartNumberSchemaType = InferType<typeof formMasterProductPartNumberEntrySupplierPartNumberSchema>;
export const formMasterProductPartNumberEntryStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema;
export type FormMasterProductPartNumberEntryStoringConditionIdSchemaType = InferType<typeof formMasterProductPartNumberEntryStoringConditionIdSchema>;
export const formMasterProductPartNumberEntryQuantityUnitSchema = typeMasterProductPartNumberQuantityUnitSchema;
export type FormMasterProductPartNumberEntryQuantityUnitSchemaType = InferType<typeof formMasterProductPartNumberEntryQuantityUnitSchema>;
export const formMasterProductPartNumberEntryQuantityPerCaseSchema = typeMasterProductPartNumberQuantityPerCaseSchema;
export type FormMasterProductPartNumberEntryQuantityPerCaseSchemaType = InferType<typeof formMasterProductPartNumberEntryQuantityPerCaseSchema>;
export const formMasterProductPartNumberEntryLocationAllocationMinimumQuantitySchema = typeMasterProductPartNumberLocationAllocationMinimumQuantitySchema;
export type FormMasterProductPartNumberEntryLocationAllocationMinimumQuantitySchemaType = InferType<typeof formMasterProductPartNumberEntryLocationAllocationMinimumQuantitySchema>;
export const formMasterProductPartNumberEntryPartNumberNoteSchema = typeCommonNoteSchema;
export type FormMasterProductPartNumberEntryPartNumberNoteSchemaType = InferType<typeof formMasterProductPartNumberEntryPartNumberNoteSchema>;
export const formMasterProductPartNumberEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterProductPartNumberEntryAvailableFromSchemaType = InferType<typeof formMasterProductPartNumberEntryAvailableFromSchema>;
export const formMasterProductPartNumberEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterProductPartNumberEntryAvailableToSchemaType = InferType<typeof formMasterProductPartNumberEntryAvailableToSchema>;
export const formMasterProductPartNumberEntrySchema = yup.object({
	partNumberCode: formMasterProductPartNumberEntryPartNumberCodeSchema,
	partNumberName: formMasterProductPartNumberEntryPartNumberNameSchema,
	shipperId: formMasterProductPartNumberEntryShipperIdSchema,
	shipperCode: formMasterProductPartNumberEntryShipperCodeSchema,
	shipperName: formMasterProductPartNumberEntryShipperNameSchema,
	partNumberCategoryId1: formMasterProductPartNumberEntryPartNumberCategoryId1Schema,
	partNumberCategoryId2: formMasterProductPartNumberEntryPartNumberCategoryId2Schema,
	partNumberCategoryId3: formMasterProductPartNumberEntryPartNumberCategoryId3Schema,
	supplierPartNumber: formMasterProductPartNumberEntrySupplierPartNumberSchema,
	storingConditionId: formMasterProductPartNumberEntryStoringConditionIdSchema,
	quantityUnit: formMasterProductPartNumberEntryQuantityUnitSchema,
	quantityPerCase: formMasterProductPartNumberEntryQuantityPerCaseSchema,
	locationAllocationMinimumQuantity: formMasterProductPartNumberEntryLocationAllocationMinimumQuantitySchema,
	partNumberNote: formMasterProductPartNumberEntryPartNumberNoteSchema,
	availableFrom: formMasterProductPartNumberEntryAvailableFromSchema,
	availableTo: formMasterProductPartNumberEntryAvailableToSchema,
}).required();
export type FormMasterProductPartNumberEntrySchemaType = InferType<typeof formMasterProductPartNumberEntrySchema>;
export const formMasterProductSkuGroupListSkuGroupCodeSchema = typeMasterProductSkuGroupCodeSchema.optional();
export type FormMasterProductSkuGroupListSkuGroupCodeSchemaType = InferType<typeof formMasterProductSkuGroupListSkuGroupCodeSchema>;
export const formMasterProductSkuGroupListSkuGroupNameSchema = typeMasterProductSkuGroupNameSchema.optional();
export type FormMasterProductSkuGroupListSkuGroupNameSchemaType = InferType<typeof formMasterProductSkuGroupListSkuGroupNameSchema>;
export const formMasterProductSkuGroupListSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormMasterProductSkuGroupListSkuIdSchemaType = InferType<typeof formMasterProductSkuGroupListSkuIdSchema>;
export const formMasterProductSkuGroupListStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema.optional();
export type FormMasterProductSkuGroupListStoringConditionIdSchemaType = InferType<typeof formMasterProductSkuGroupListStoringConditionIdSchema>;
export const formMasterProductSkuGroupListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterProductSkuGroupListIsAvailableSchemaType = InferType<typeof formMasterProductSkuGroupListIsAvailableSchema>;
export const formMasterProductSkuGroupListSchema = yup.object({
	skuGroupCode: formMasterProductSkuGroupListSkuGroupCodeSchema,
	skuGroupName: formMasterProductSkuGroupListSkuGroupNameSchema,
	skuId: formMasterProductSkuGroupListSkuIdSchema,
	storingConditionId: formMasterProductSkuGroupListStoringConditionIdSchema,
	isAvailable: formMasterProductSkuGroupListIsAvailableSchema,
}).required();
export type FormMasterProductSkuGroupListSchemaType = InferType<typeof formMasterProductSkuGroupListSchema>;
export const formMasterProductSkuGroupEntrySkuGroupCodeSchema = typeMasterProductSkuGroupCodeSchema;
export type FormMasterProductSkuGroupEntrySkuGroupCodeSchemaType = InferType<typeof formMasterProductSkuGroupEntrySkuGroupCodeSchema>;
export const formMasterProductSkuGroupEntrySkuGroupNameSchema = typeMasterProductSkuGroupNameSchema;
export type FormMasterProductSkuGroupEntrySkuGroupNameSchemaType = InferType<typeof formMasterProductSkuGroupEntrySkuGroupNameSchema>;
export const formMasterProductSkuGroupEntrySkuIdSchema = typeMasterProductSkuIdSchema;
export type FormMasterProductSkuGroupEntrySkuIdSchemaType = InferType<typeof formMasterProductSkuGroupEntrySkuIdSchema>;
export const formMasterProductSkuGroupEntryStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema.optional();
export type FormMasterProductSkuGroupEntryStoringConditionIdSchemaType = InferType<typeof formMasterProductSkuGroupEntryStoringConditionIdSchema>;
export const formMasterProductSkuGroupEntrySkuGroupNoteSchema = typeCommonNoteSchema.optional();
export type FormMasterProductSkuGroupEntrySkuGroupNoteSchemaType = InferType<typeof formMasterProductSkuGroupEntrySkuGroupNoteSchema>;
export const formMasterProductSkuGroupEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterProductSkuGroupEntryAvailableFromSchemaType = InferType<typeof formMasterProductSkuGroupEntryAvailableFromSchema>;
export const formMasterProductSkuGroupEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterProductSkuGroupEntryAvailableToSchemaType = InferType<typeof formMasterProductSkuGroupEntryAvailableToSchema>;
export const formMasterProductSkuGroupEntrySkuGroupDetailValueSkuGroupDetailIdSchema = yup.number().optional().integer();
export type FormMasterProductSkuGroupEntrySkuGroupDetailValueSkuGroupDetailIdSchemaType = InferType<typeof formMasterProductSkuGroupEntrySkuGroupDetailValueSkuGroupDetailIdSchema>;
export const formMasterProductSkuGroupEntrySkuGroupDetailValueSkuIdSchema = yup.number().optional().integer();
export type FormMasterProductSkuGroupEntrySkuGroupDetailValueSkuIdSchemaType = InferType<typeof formMasterProductSkuGroupEntrySkuGroupDetailValueSkuIdSchema>;
export const formMasterProductSkuGroupEntrySkuGroupDetailValueSetRequiredQuantitySchema = yup.number().optional().integer();
export type FormMasterProductSkuGroupEntrySkuGroupDetailValueSetRequiredQuantitySchemaType = InferType<typeof formMasterProductSkuGroupEntrySkuGroupDetailValueSetRequiredQuantitySchema>;
export const formMasterProductSkuGroupEntrySkuGroupDetailValueProductIdSchema = yup.number().optional().integer();
export type FormMasterProductSkuGroupEntrySkuGroupDetailValueProductIdSchemaType = InferType<typeof formMasterProductSkuGroupEntrySkuGroupDetailValueProductIdSchema>;
export const formMasterProductSkuGroupEntrySkuGroupDetailValueSchema = yup.object({
	skuGroupDetailId: formMasterProductSkuGroupEntrySkuGroupDetailValueSkuGroupDetailIdSchema,
	skuId: formMasterProductSkuGroupEntrySkuGroupDetailValueSkuIdSchema,
	setRequiredQuantity: formMasterProductSkuGroupEntrySkuGroupDetailValueSetRequiredQuantitySchema,
	productId: formMasterProductSkuGroupEntrySkuGroupDetailValueProductIdSchema,
}).optional();
export type FormMasterProductSkuGroupEntrySkuGroupDetailValueSchemaType = InferType<typeof formMasterProductSkuGroupEntrySkuGroupDetailValueSchema>;
export const formMasterProductSkuGroupEntrySkuGroupDetailSchema = yup.array(
	formMasterProductSkuGroupEntrySkuGroupDetailValueSchema
).required();
export type FormMasterProductSkuGroupEntrySkuGroupDetailSchemaType = InferType<typeof formMasterProductSkuGroupEntrySkuGroupDetailSchema>;
export const formMasterProductSkuGroupEntrySchema = yup.object({
	skuGroupCode: formMasterProductSkuGroupEntrySkuGroupCodeSchema,
	skuGroupName: formMasterProductSkuGroupEntrySkuGroupNameSchema,
	skuId: formMasterProductSkuGroupEntrySkuIdSchema,
	storingConditionId: formMasterProductSkuGroupEntryStoringConditionIdSchema,
	skuGroupNote: formMasterProductSkuGroupEntrySkuGroupNoteSchema,
	availableFrom: formMasterProductSkuGroupEntryAvailableFromSchema,
	availableTo: formMasterProductSkuGroupEntryAvailableToSchema,
	skuGroupDetail: formMasterProductSkuGroupEntrySkuGroupDetailSchema,
}).required();
export type FormMasterProductSkuGroupEntrySchemaType = InferType<typeof formMasterProductSkuGroupEntrySchema>;
export const formMasterProductSmallCategoryListProductLargeCategoryIdSchema = typeMasterProductLargeCategoryIdSchema.optional();
export type FormMasterProductSmallCategoryListProductLargeCategoryIdSchemaType = InferType<typeof formMasterProductSmallCategoryListProductLargeCategoryIdSchema>;
export const formMasterProductSmallCategoryListProductLargeCategoryCodeSchema = typeMasterProductLargeCategoryCodeSchema.optional();
export type FormMasterProductSmallCategoryListProductLargeCategoryCodeSchemaType = InferType<typeof formMasterProductSmallCategoryListProductLargeCategoryCodeSchema>;
export const formMasterProductSmallCategoryListProductLargeCategoryNameSchema = typeMasterProductLargeCategoryNameSchema.optional();
export type FormMasterProductSmallCategoryListProductLargeCategoryNameSchemaType = InferType<typeof formMasterProductSmallCategoryListProductLargeCategoryNameSchema>;
export const formMasterProductSmallCategoryListProductSmallCategoryCodeSchema = typeMasterProductSmallCategoryCodeSchema.optional();
export type FormMasterProductSmallCategoryListProductSmallCategoryCodeSchemaType = InferType<typeof formMasterProductSmallCategoryListProductSmallCategoryCodeSchema>;
export const formMasterProductSmallCategoryListProductSmallCategoryNameSchema = typeMasterProductSmallCategoryNameSchema.optional();
export type FormMasterProductSmallCategoryListProductSmallCategoryNameSchemaType = InferType<typeof formMasterProductSmallCategoryListProductSmallCategoryNameSchema>;
export const formMasterProductSmallCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterProductSmallCategoryListIsAvailableSchemaType = InferType<typeof formMasterProductSmallCategoryListIsAvailableSchema>;
export const formMasterProductSmallCategoryListSchema = yup.object({
	productLargeCategoryId: formMasterProductSmallCategoryListProductLargeCategoryIdSchema,
	productLargeCategoryCode: formMasterProductSmallCategoryListProductLargeCategoryCodeSchema,
	productLargeCategoryName: formMasterProductSmallCategoryListProductLargeCategoryNameSchema,
	productSmallCategoryCode: formMasterProductSmallCategoryListProductSmallCategoryCodeSchema,
	productSmallCategoryName: formMasterProductSmallCategoryListProductSmallCategoryNameSchema,
	isAvailable: formMasterProductSmallCategoryListIsAvailableSchema,
}).required();
export type FormMasterProductSmallCategoryListSchemaType = InferType<typeof formMasterProductSmallCategoryListSchema>;
export const formMasterProductSmallCategoryEntryProductLargeCategoryIdSchema = typeMasterProductLargeCategoryIdSchema;
export type FormMasterProductSmallCategoryEntryProductLargeCategoryIdSchemaType = InferType<typeof formMasterProductSmallCategoryEntryProductLargeCategoryIdSchema>;
export const formMasterProductSmallCategoryEntryProductLargeCategoryCodeSchema = typeMasterProductLargeCategoryCodeSchema;
export type FormMasterProductSmallCategoryEntryProductLargeCategoryCodeSchemaType = InferType<typeof formMasterProductSmallCategoryEntryProductLargeCategoryCodeSchema>;
export const formMasterProductSmallCategoryEntryProductLargeCategoryNameSchema = typeMasterProductLargeCategoryNameSchema;
export type FormMasterProductSmallCategoryEntryProductLargeCategoryNameSchemaType = InferType<typeof formMasterProductSmallCategoryEntryProductLargeCategoryNameSchema>;
export const formMasterProductSmallCategoryEntryProductSmallCategoryCodeSchema = typeMasterProductSmallCategoryCodeSchema;
export type FormMasterProductSmallCategoryEntryProductSmallCategoryCodeSchemaType = InferType<typeof formMasterProductSmallCategoryEntryProductSmallCategoryCodeSchema>;
export const formMasterProductSmallCategoryEntryProductSmallCategoryNameSchema = typeMasterProductSmallCategoryNameSchema;
export type FormMasterProductSmallCategoryEntryProductSmallCategoryNameSchemaType = InferType<typeof formMasterProductSmallCategoryEntryProductSmallCategoryNameSchema>;
export const formMasterProductSmallCategoryEntryProductSmallCategoryDisplayOrderSchema = typeCommonDisplayOrderSchema.required();
export type FormMasterProductSmallCategoryEntryProductSmallCategoryDisplayOrderSchemaType = InferType<typeof formMasterProductSmallCategoryEntryProductSmallCategoryDisplayOrderSchema>;
export const formMasterProductSmallCategoryEntryProductSmallCategoryNoteSchema = typeCommonNoteSchema;
export type FormMasterProductSmallCategoryEntryProductSmallCategoryNoteSchemaType = InferType<typeof formMasterProductSmallCategoryEntryProductSmallCategoryNoteSchema>;
export const formMasterProductSmallCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterProductSmallCategoryEntryAvailableFromSchemaType = InferType<typeof formMasterProductSmallCategoryEntryAvailableFromSchema>;
export const formMasterProductSmallCategoryEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterProductSmallCategoryEntryAvailableToSchemaType = InferType<typeof formMasterProductSmallCategoryEntryAvailableToSchema>;
export const formMasterProductSmallCategoryEntrySchema = yup.object({
	productLargeCategoryId: formMasterProductSmallCategoryEntryProductLargeCategoryIdSchema,
	productLargeCategoryCode: formMasterProductSmallCategoryEntryProductLargeCategoryCodeSchema,
	productLargeCategoryName: formMasterProductSmallCategoryEntryProductLargeCategoryNameSchema,
	productSmallCategoryCode: formMasterProductSmallCategoryEntryProductSmallCategoryCodeSchema,
	productSmallCategoryName: formMasterProductSmallCategoryEntryProductSmallCategoryNameSchema,
	productSmallCategoryDisplayOrder: formMasterProductSmallCategoryEntryProductSmallCategoryDisplayOrderSchema,
	productSmallCategoryNote: formMasterProductSmallCategoryEntryProductSmallCategoryNoteSchema,
	availableFrom: formMasterProductSmallCategoryEntryAvailableFromSchema,
	availableTo: formMasterProductSmallCategoryEntryAvailableToSchema,
}).required();
export type FormMasterProductSmallCategoryEntrySchemaType = InferType<typeof formMasterProductSmallCategoryEntrySchema>;
export const formMasterProductStatusListProductStatusCodeSchema = typeMasterProductStatusCodeSchema.optional();
export type FormMasterProductStatusListProductStatusCodeSchemaType = InferType<typeof formMasterProductStatusListProductStatusCodeSchema>;
export const formMasterProductStatusListProductStatusNameSchema = typeMasterProductStatusNameSchema.optional();
export type FormMasterProductStatusListProductStatusNameSchemaType = InferType<typeof formMasterProductStatusListProductStatusNameSchema>;
export const formMasterProductStatusListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterProductStatusListIsAvailableSchemaType = InferType<typeof formMasterProductStatusListIsAvailableSchema>;
export const formMasterProductStatusListSchema = yup.object({
	productStatusCode: formMasterProductStatusListProductStatusCodeSchema,
	productStatusName: formMasterProductStatusListProductStatusNameSchema,
	isAvailable: formMasterProductStatusListIsAvailableSchema,
}).required();
export type FormMasterProductStatusListSchemaType = InferType<typeof formMasterProductStatusListSchema>;
export const formMasterProductStatusEntryProductStatusCodeSchema = typeMasterProductStatusCodeSchema;
export type FormMasterProductStatusEntryProductStatusCodeSchemaType = InferType<typeof formMasterProductStatusEntryProductStatusCodeSchema>;
export const formMasterProductStatusEntryProductStatusNameSchema = typeMasterProductStatusNameSchema;
export type FormMasterProductStatusEntryProductStatusNameSchemaType = InferType<typeof formMasterProductStatusEntryProductStatusNameSchema>;
export const formMasterProductStatusEntryProductStatusNoteSchema = typeCommonNoteSchema;
export type FormMasterProductStatusEntryProductStatusNoteSchemaType = InferType<typeof formMasterProductStatusEntryProductStatusNoteSchema>;
export const formMasterProductStatusEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterProductStatusEntryAvailableFromSchemaType = InferType<typeof formMasterProductStatusEntryAvailableFromSchema>;
export const formMasterProductStatusEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterProductStatusEntryAvailableToSchemaType = InferType<typeof formMasterProductStatusEntryAvailableToSchema>;
export const formMasterProductStatusEntrySchema = yup.object({
	productStatusCode: formMasterProductStatusEntryProductStatusCodeSchema,
	productStatusName: formMasterProductStatusEntryProductStatusNameSchema,
	productStatusNote: formMasterProductStatusEntryProductStatusNoteSchema,
	availableFrom: formMasterProductStatusEntryAvailableFromSchema,
	availableTo: formMasterProductStatusEntryAvailableToSchema,
}).required();
export type FormMasterProductStatusEntrySchemaType = InferType<typeof formMasterProductStatusEntrySchema>;
export const formMasterProductListProductCodeSchema = typeMasterProductCodeSchema.optional();
export type FormMasterProductListProductCodeSchemaType = InferType<typeof formMasterProductListProductCodeSchema>;
export const formMasterProductListProductNameSchema = typeMasterProductNameSchema.optional();
export type FormMasterProductListProductNameSchemaType = InferType<typeof formMasterProductListProductNameSchema>;
export const formMasterProductListPartNumberIdSchema = typeMasterProductPartNumberIdSchema.optional();
export type FormMasterProductListPartNumberIdSchemaType = InferType<typeof formMasterProductListPartNumberIdSchema>;
export const formMasterProductListPartNumberCodeSchema = typeMasterProductPartNumberCodeSchema.optional();
export type FormMasterProductListPartNumberCodeSchemaType = InferType<typeof formMasterProductListPartNumberCodeSchema>;
export const formMasterProductListPartNumberNameSchema = typeMasterProductPartNumberNameSchema.optional();
export type FormMasterProductListPartNumberNameSchemaType = InferType<typeof formMasterProductListPartNumberNameSchema>;
export const formMasterProductListSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormMasterProductListSkuIdSchemaType = InferType<typeof formMasterProductListSkuIdSchema>;
export const formMasterProductListJanCodeSchema = typeMasterProductJanCodeSchema.optional();
export type FormMasterProductListJanCodeSchemaType = InferType<typeof formMasterProductListJanCodeSchema>;
export const formMasterProductListProductLargeCategoryIdSchema = typeMasterProductLargeCategoryIdSchema.optional();
export type FormMasterProductListProductLargeCategoryIdSchemaType = InferType<typeof formMasterProductListProductLargeCategoryIdSchema>;
export const formMasterProductListProductLargeCategoryCodeSchema = typeMasterProductLargeCategoryCodeSchema.optional();
export type FormMasterProductListProductLargeCategoryCodeSchemaType = InferType<typeof formMasterProductListProductLargeCategoryCodeSchema>;
export const formMasterProductListProductLargeCategoryNameSchema = typeMasterProductLargeCategoryNameSchema.optional();
export type FormMasterProductListProductLargeCategoryNameSchemaType = InferType<typeof formMasterProductListProductLargeCategoryNameSchema>;
export const formMasterProductListProductSmallCategoryIdSchema = typeMasterProductSmallCategoryIdSchema.optional();
export type FormMasterProductListProductSmallCategoryIdSchemaType = InferType<typeof formMasterProductListProductSmallCategoryIdSchema>;
export const formMasterProductListProductSmallCategoryCodeSchema = typeMasterProductSmallCategoryCodeSchema.optional();
export type FormMasterProductListProductSmallCategoryCodeSchemaType = InferType<typeof formMasterProductListProductSmallCategoryCodeSchema>;
export const formMasterProductListProductSmallCategoryNameSchema = typeMasterProductSmallCategoryNameSchema.optional();
export type FormMasterProductListProductSmallCategoryNameSchemaType = InferType<typeof formMasterProductListProductSmallCategoryNameSchema>;
export const formMasterProductListPartNumberCategoryIdSchema = typeMasterProductPartNumberCategoryIdSchema.optional();
export type FormMasterProductListPartNumberCategoryIdSchemaType = InferType<typeof formMasterProductListPartNumberCategoryIdSchema>;
export const formMasterProductListStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema.optional();
export type FormMasterProductListStoringConditionIdSchemaType = InferType<typeof formMasterProductListStoringConditionIdSchema>;
export const formMasterProductListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterProductListIsAvailableSchemaType = InferType<typeof formMasterProductListIsAvailableSchema>;
export const formMasterProductListSearchItemsSchema = typeSearchItemsSchema;
export type FormMasterProductListSearchItemsSchemaType = InferType<typeof formMasterProductListSearchItemsSchema>;
export const formMasterProductListDetailedConditionsSchema = typeDetailedConditionItemsSchema;
export type FormMasterProductListDetailedConditionsSchemaType = InferType<typeof formMasterProductListDetailedConditionsSchema>;
export const formMasterProductListSchema = yup.object({
	productCode: formMasterProductListProductCodeSchema,
	productName: formMasterProductListProductNameSchema,
	partNumberId: formMasterProductListPartNumberIdSchema,
	partNumberCode: formMasterProductListPartNumberCodeSchema,
	partNumberName: formMasterProductListPartNumberNameSchema,
	skuId: formMasterProductListSkuIdSchema,
	janCode: formMasterProductListJanCodeSchema,
	productLargeCategoryId: formMasterProductListProductLargeCategoryIdSchema,
	productLargeCategoryCode: formMasterProductListProductLargeCategoryCodeSchema,
	productLargeCategoryName: formMasterProductListProductLargeCategoryNameSchema,
	productSmallCategoryId: formMasterProductListProductSmallCategoryIdSchema,
	productSmallCategoryCode: formMasterProductListProductSmallCategoryCodeSchema,
	productSmallCategoryName: formMasterProductListProductSmallCategoryNameSchema,
	partNumberCategoryId: formMasterProductListPartNumberCategoryIdSchema,
	storingConditionId: formMasterProductListStoringConditionIdSchema,
	isAvailable: formMasterProductListIsAvailableSchema,
	searchItems: formMasterProductListSearchItemsSchema,
	detailedConditions: formMasterProductListDetailedConditionsSchema,
}).required();
export type FormMasterProductListSchemaType = InferType<typeof formMasterProductListSchema>;
export const formMasterProductEntryProductCodeSchema = typeMasterProductCodeSchema;
export type FormMasterProductEntryProductCodeSchemaType = InferType<typeof formMasterProductEntryProductCodeSchema>;
export const formMasterProductEntryProductNameSchema = typeMasterProductNameSchema;
export type FormMasterProductEntryProductNameSchemaType = InferType<typeof formMasterProductEntryProductNameSchema>;
export const formMasterProductEntryPartNumberIdSchema = typeMasterProductPartNumberIdSchema;
export type FormMasterProductEntryPartNumberIdSchemaType = InferType<typeof formMasterProductEntryPartNumberIdSchema>;
export const formMasterProductEntryPartNumberCodeSchema = typeMasterProductPartNumberCodeSchema;
export type FormMasterProductEntryPartNumberCodeSchemaType = InferType<typeof formMasterProductEntryPartNumberCodeSchema>;
export const formMasterProductEntryPartNumberNameSchema = typeMasterProductPartNumberNameSchema;
export type FormMasterProductEntryPartNumberNameSchemaType = InferType<typeof formMasterProductEntryPartNumberNameSchema>;
export const formMasterProductEntrySkuIdSchema = typeMasterProductSkuIdSchema;
export type FormMasterProductEntrySkuIdSchemaType = InferType<typeof formMasterProductEntrySkuIdSchema>;
export const formMasterProductEntryJanCodeSchema = typeMasterProductJanCodeSchema.optional();
export type FormMasterProductEntryJanCodeSchemaType = InferType<typeof formMasterProductEntryJanCodeSchema>;
export const formMasterProductEntryProductThumbnailSchema = typeMasterProductThumbnailSchema.optional();
export type FormMasterProductEntryProductThumbnailSchemaType = InferType<typeof formMasterProductEntryProductThumbnailSchema>;
export const formMasterProductEntryProductNoteSchema = typeCommonNoteSchema;
export type FormMasterProductEntryProductNoteSchemaType = InferType<typeof formMasterProductEntryProductNoteSchema>;
export const formMasterProductEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterProductEntryAvailableFromSchemaType = InferType<typeof formMasterProductEntryAvailableFromSchema>;
export const formMasterProductEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterProductEntryAvailableToSchemaType = InferType<typeof formMasterProductEntryAvailableToSchema>;
export const formMasterProductEntrySchema = yup.object({
	productCode: formMasterProductEntryProductCodeSchema,
	productName: formMasterProductEntryProductNameSchema,
	partNumberId: formMasterProductEntryPartNumberIdSchema,
	partNumberCode: formMasterProductEntryPartNumberCodeSchema,
	partNumberName: formMasterProductEntryPartNumberNameSchema,
	skuId: formMasterProductEntrySkuIdSchema,
	janCode: formMasterProductEntryJanCodeSchema,
	productThumbnail: formMasterProductEntryProductThumbnailSchema,
	productNote: formMasterProductEntryProductNoteSchema,
	availableFrom: formMasterProductEntryAvailableFromSchema,
	availableTo: formMasterProductEntryAvailableToSchema,
}).required();
export type FormMasterProductEntrySchemaType = InferType<typeof formMasterProductEntrySchema>;
