import { object, number } from 'yup';
import { MasterResponse } from '../../../response';
import { formMasterProductEntrySchema, formMasterProductListSchema } from '../../../schema/form/master/product';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';

export type ProductListResponse = MasterResponse & {
	productId: number; // 商品id
	productCode: string; // 商品コード
	productName: string; // 商品名
	partNumberId: number; // 品番Id
	partNumberCode: string; // 品番コード
	partNumberName: string; // 品名
	productThumbnail: string | undefined; // 商品画像
	skuId: number; // SKUId
	skuCode: string; // SKUコード
	janCode: string | undefined; // JANコード
	productLargeCategoryId: number; // 商品大項目Id
	productLargeCategoryCode: string; // 商品大項目コード
	productLargeCategoryName: string; // 商品大項目名
	productSmallCategoryId: number; // 商品小項目Id
	productSmallCategoryCode: string; // 商品小項目コード
	productSmallCategoryName: string; // 商品小項目名
	partNumberCategoryId1: number; // 品番区分Id1
	partNumberCategoryCode1: string; // 品番区分コード1
	partNumberCategoryName1: string; // 品番区分名1
	partNumberCategoryId2: number | undefined; // 品番区分Id2
	partNumberCategoryCode2: string | undefined; // 品番区分コード2
	partNumberCategoryName2: string | undefined; // 品番区分名2
	partNumberCategoryId3: number | undefined; // 品番区分Id3
	partNumberCategoryCode3: string | undefined; // 品番区分コード3
	partNumberCategoryName3: string | undefined; // 品番区分名3
	storingConditionId: number; // 保管条件Id
	storingConditionCode: string; // 保管条件コード
	storingConditionName: string; // 保管条件名
	// 以下の項目は品番？
	quantityUnit: string; // 数量単位
	quantityPerCase: number; // ケース入数
	locationAllocationMinimumQuantity: number; // ロケ割当最小数量
	caseDepth: number; // ケース縦
	caseWidth: number; // ケース横
	caseHeight: number; // ケース高
	productDepth: number; // 商品縦
	productWidth: number; // 商品横
	productHeight: number; // 商品高
	caseIncludedWeight: number; // ケース込み重さ
	weightOnlyProduct: number; // 商品のみ重さ
	productNote: string | undefined; // 備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: ProductListResponse[] }>();

const listApi = createApiInterface().request(formMasterProductListSchema).response<{
	list: ProductListResponse[];
}>();

const createApi = createApiInterface().request(formMasterProductEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterProductEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const productApi = createApiInterfaceMap({
	['/v1/master/product/product/find']: findApi,
	['/v1/master/product/product/list']: listApi,
	['/v1/master/product/product/create']: createApi,
	['/v1/master/product/product/update']: updateApi,
	['/v1/master/product/product/delete']: deleteApi,
});
