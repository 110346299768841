import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { array, number, object } from 'yup';
import { BasicResponse } from '../../response';

export type StockLabelResponse = BasicResponse & {
	// stockId: number;
	// 入出庫情報
	receiveIssueId: number | undefined; // 入出庫ID

	// 入出庫明細
	receiveIssueDetailId: number;
	receivingScheduledQuantity: number | undefined; // 入庫予定数量

	// SKU
	skuId: number;
	skuCode: number;

	// 移動先
	destinationWarehouseId: number | undefined; // 移動先倉庫ID
	destinationWarehouseCode: string | undefined; // 移動先倉庫コード
	destinationWarehouseName: string | undefined; // 移動先倉庫名
	destinationLocationId: number | undefined; // 移動先ロケーションID
	destinationLocationCode: string | undefined; // 移動先ロケーションコード
	destinationLocationName: string | undefined; // 移動先ロケーション名
	destinationLocationDetailId: number | undefined; // 移動先ロケーション明細ID
	destinationLocationDetailCode: string | undefined; // 移動先ロケーション明細コード
	destinationLocationDetailName: string | undefined; // 移動先ロケーション明細名

	// 移動元
	moveSourceWarehouseId: number | undefined; // 移動元倉庫ID
	moveSourceWarehouseCode: string | undefined; // 移動元倉庫コード
	moveSourceWarehouseName: string | undefined; // 移動元倉庫名
	moveSourceLocationId: number | undefined; // 移動元ロケーションID
	moveSourceLocationCode: string | undefined; // 移動元ロケーションコード
	moveSourceLocationName: string | undefined; // 移動元ロケーション名
	moveSourceLocationDetailId: number | undefined; // 移動元ロケーション明細ID
	moveSourceLocationDetailCode: string | undefined; // 移動元ロケーション明細コード
	moveSourceLocationDetailName: string | undefined; // 移動元ロケーション明細名
};

const listApi = createApiInterface()
	.request(object({ id: array().of(number().integer().required()) }))
	.response<{
		list: StockLabelResponse[];
	}>();

export const stockLabelApi = createApiInterfaceMap({
	['/v1/print/stock/list']: listApi,
});
