import { number, object } from 'yup';
import { MasterResponse } from '../../../response';
import { formMasterClientSenderEntrySchema, formMasterClientSenderListSchema } from '../../../schema/form/master/client';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';

export type ClientSenderResponse = MasterResponse & {
	clientSenderId: number;
	clientSenderCode: string;
	clientSenderName: string;
	clientId: number;
	clientCode: string;
	clientName: string;
	transportMethodId: number;
	transportMethodCode: string;
	transportMethodName: string;
	clientSenderPostalCode: string | undefined;
	clientSenderAddress: string;
	clientSenderTel: string | undefined;
	clientSenderFax: string | undefined;
	clientSenderNote: string | undefined;
	countryId: number;
	countryName: string;
	prefectureId: number | undefined;
	prefectureName: string | undefined;
};

const findApi = createApiInterface()
	.request(
		object({
			id: number().required().integer(),
		})
	)
	.response<{
		list: ClientSenderResponse[];
	}>();
const listApi = createApiInterface().request(formMasterClientSenderListSchema.notRequired()).response<{
	list: ClientSenderResponse[];
}>();

const createApi = createApiInterface()
	.request(
		// fv-generatorで生成されたスキーマです
		// master.yamlのclient-sender-inputフォームの型になります
		formMasterClientSenderEntrySchema
	)
	.response<{
		// レスポンスの型です
		isSuccess: boolean;
		clientSenderId?: boolean;
	}>();
const updateApi = createApiInterface()
	.request(
		object({
			id: number().required().integer(),
			form: formMasterClientSenderEntrySchema,
		})
	)
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const clientSenderApi = createApiInterfaceMap({
	// プロパティ名はAPIのURLにしてください
	['/v1/master/client/client-sender/find']: findApi,
	['/v1/master/client/client-sender/list']: listApi,
	['/v1/master/client/client-sender/create']: createApi,
	['/v1/master/client/client-sender/update']: updateApi,
	['/v1/master/client/client-sender/delete']: deleteApi,
});
