// config.ts 的なものをつくって外に出しても良いしここにまとめても良い
export const langInfo = {
	'ja-jp': {
		name: '日本語',
	},
	'en-us': {
		name: 'English',
	},
};

// 翻訳関係関数群(ファイル名/パスは変えても良い)
export type Language = keyof typeof langInfo;
export const langList: Array<Language> = Object.keys(langInfo) as Array<Language>;

export function isTranslateableLanguage(lang: string): lang is Language {
	return (langList as Array<string>).includes(lang);
}

function getDefaultLanguage() {
	const storage = localStorage.getItem('lang');
	if (storage !== null) {
		return isTranslateableLanguage(storage) ? storage : 'ja-jp';
	}
	const nav = navigator.language;
	return isTranslateableLanguage(nav) ? nav : 'ja-jp';
}

export function saveLangauge(lang: Language) {
	localStorage.setItem('lang', lang);
}

export const defaultLanguage: Language = getDefaultLanguage();

export type LanguageMap = Record<string, string>;
