import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterWarehouseAisleEntrySchema, formMasterWarehouseAisleListSchema } from '../../../schema/form/master/warehouse';

export type AisleListResponse = MasterResponse & {
	aisleId: number; // 通路ID
	aisleCode: string; // 通路コード
	aisleName: string; // 通路名
	aisleNote: string | undefined; // 備考
	warehouseId: number; // 倉庫ID
	warehouseCode: string; // 倉庫コード
	warehouseName: string; // 倉庫名
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: AisleListResponse[] }>();

const listApi = createApiInterface().request(formMasterWarehouseAisleListSchema).response<{
	list: AisleListResponse[];
}>();

const createApi = createApiInterface().request(formMasterWarehouseAisleEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterWarehouseAisleEntrySchema }))
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const aisleApi = createApiInterfaceMap({
	['/v1/master/warehouse/aisle/find']: findApi,
	['/v1/master/warehouse/aisle/list']: listApi,
	['/v1/master/warehouse/aisle/create']: createApi,
	['/v1/master/warehouse/aisle/update']: updateApi,
	['/v1/master/warehouse/aisle/delete']: deleteApi,
});
