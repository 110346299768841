/** ロケーション区分マスタ */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterWarehouseLocationCategoryEntrySchema, formMasterWarehouseLocationCategoryListSchema } from '../../../schema/form/master/warehouse';

export type LocationCategoryListResponse = MasterResponse & {
	locationCategoryId: number; // ロケーション区分ID
	locationCategoryCode: string; // ロケーション区分コード
	locationCategoryName: string; // ロケーション区分名
	allocationPriorityFlag: boolean; // 引当優先フラグ
	allocationImpossibleFlag: boolean; // 引き当て不可フラグ
	movementProhibitedFlag: boolean; // 移動不可フラグ
	automaticAllocationDisabledFlag: boolean; // 入庫先自動割当不可フラグ
	totalPickingFlag: boolean; // トータルピッキングフラグ
	locationCategoryNote: string | undefined;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{
		list: LocationCategoryListResponse[];
	}>();

const listApi = createApiInterface().request(formMasterWarehouseLocationCategoryListSchema.notRequired()).response<{ list: LocationCategoryListResponse[] }>();

const createApi = createApiInterface().request(formMasterWarehouseLocationCategoryEntrySchema).response<{
	isSuccess: boolean;
	locationCategoryId?: number;
}>();

const updateApi = createApiInterface()
	.request(
		object({
			id: number().required().integer(),
			form: formMasterWarehouseLocationCategoryEntrySchema,
		})
	)
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const locationCategoryApi = createApiInterfaceMap({
	['/v1/master/warehouse/location/category/find']: findApi,
	['/v1/master/warehouse/location/category/list']: listApi,
	['/v1/master/warehouse/location/category/create']: createApi,
	['/v1/master/warehouse/location/category/update']: updateApi,
	['/v1/master/warehouse/location/category/delete']: deleteApi,
});
