// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterPackagingCategoryCodeSchema, typeMasterPackagingCategoryNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema } from "./../../common";

export const formMasterPackagingCategoryListPackagingCategoryCodeSchema = typeMasterPackagingCategoryCodeSchema.optional();
export type FormMasterPackagingCategoryListPackagingCategoryCodeSchemaType = InferType<typeof formMasterPackagingCategoryListPackagingCategoryCodeSchema>;
export const formMasterPackagingCategoryListPackagingCategoryNameSchema = typeMasterPackagingCategoryNameSchema.optional();
export type FormMasterPackagingCategoryListPackagingCategoryNameSchemaType = InferType<typeof formMasterPackagingCategoryListPackagingCategoryNameSchema>;
export const formMasterPackagingCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterPackagingCategoryListIsAvailableSchemaType = InferType<typeof formMasterPackagingCategoryListIsAvailableSchema>;
export const formMasterPackagingCategoryListSchema = yup.object({
	packagingCategoryCode: formMasterPackagingCategoryListPackagingCategoryCodeSchema,
	packagingCategoryName: formMasterPackagingCategoryListPackagingCategoryNameSchema,
	isAvailable: formMasterPackagingCategoryListIsAvailableSchema,
}).required();
export type FormMasterPackagingCategoryListSchemaType = InferType<typeof formMasterPackagingCategoryListSchema>;
export const formMasterPackagingCategoryEntryPackagingCategoryCodeSchema = typeMasterPackagingCategoryCodeSchema;
export type FormMasterPackagingCategoryEntryPackagingCategoryCodeSchemaType = InferType<typeof formMasterPackagingCategoryEntryPackagingCategoryCodeSchema>;
export const formMasterPackagingCategoryEntryPackagingCategoryNameSchema = typeMasterPackagingCategoryNameSchema;
export type FormMasterPackagingCategoryEntryPackagingCategoryNameSchemaType = InferType<typeof formMasterPackagingCategoryEntryPackagingCategoryNameSchema>;
export const formMasterPackagingCategoryEntryPackagingCategoryNoteSchema = typeCommonNoteSchema;
export type FormMasterPackagingCategoryEntryPackagingCategoryNoteSchemaType = InferType<typeof formMasterPackagingCategoryEntryPackagingCategoryNoteSchema>;
export const formMasterPackagingCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterPackagingCategoryEntryAvailableFromSchemaType = InferType<typeof formMasterPackagingCategoryEntryAvailableFromSchema>;
export const formMasterPackagingCategoryEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterPackagingCategoryEntryAvailableToSchemaType = InferType<typeof formMasterPackagingCategoryEntryAvailableToSchema>;
export const formMasterPackagingCategoryEntrySchema = yup.object({
	packagingCategoryCode: formMasterPackagingCategoryEntryPackagingCategoryCodeSchema,
	packagingCategoryName: formMasterPackagingCategoryEntryPackagingCategoryNameSchema,
	packagingCategoryNote: formMasterPackagingCategoryEntryPackagingCategoryNoteSchema,
	availableFrom: formMasterPackagingCategoryEntryAvailableFromSchema,
	availableTo: formMasterPackagingCategoryEntryAvailableToSchema,
}).required();
export type FormMasterPackagingCategoryEntrySchemaType = InferType<typeof formMasterPackagingCategoryEntrySchema>;
