/** 入庫前検品入力 */
import { number, object } from 'yup';
import { BasicResponse } from '../../response';
import { formReceivingReceivingInspectionSchema } from '../../schema/form/receiving/receivingInspection';
import { formReceivingReceivingInspectionMobileEntrySchema } from '../../schema/form/receiving/receivingInspectionMobileEntry';
import { formReceivingReceivingInspectionResultSchema } from '../../schema/form/receiving/receivingInspectionResult';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';

export type ReceivingInspectionResponse = BasicResponse & {
	inspectionId: number;
	inspectionLog: ReceivingInspectionLog[];

	receiveIssueDetailId: number; // 入出庫明細ID
	receivingScheduledQuantity: number; // 出庫予定数量
	receivingQuantity: number | undefined;
	skuId: number; // SKUID
	skuCode: string; // SKUコード
	productId: number; // 商品ID
	productCode: string; // 商品コード
	productName: string; // 商品名

	receiveIssueId: number; // 入出庫ID
	receiveIssueCode: string; // 入出庫コード
	receivingScheduledDate: string;
	receivingDate: string | undefined;
};

type ReceivingInspectionLog = {
	inspectionLogId: number;
	lotId: number;
	inspectionQuantity: number;
};

const findApi = createApiInterface().request(formReceivingReceivingInspectionSchema).response<{
	list: ReceivingInspectionResponse[];
}>();

const registApi = createApiInterface()
	.request(object({ form: formReceivingReceivingInspectionResultSchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const mobileRegistApi = createApiInterface()
	.request(object({ form: formReceivingReceivingInspectionMobileEntrySchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

export const receivingInspectionApi = createApiInterfaceMap({
	['/v1/receiving/inspection/find']: findApi,
	['/v1/receiving/inspection/regist']: registApi,
	['/v1/receiving/inspection/delete']: deleteApi,
	['/v1/receiving/inspection/mobile']: mobileRegistApi,
});
