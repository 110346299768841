/** 棚卸一覧 */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formStockStockTakingListInputSchema } from '../../../schema/form/stock/stockTakingListInput';
import { formStockStockTakingScheduleEntrySchema } from '../../../schema/form/stock/stockTakingScheduleEntry';
import { formStockStockTakingResultEntrySchema } from '../../../schema/form/stock/stockTakingResultEntry';
import { ListResponse } from '../../../response';

export type StockTakingListResponse = ListResponse & {
	stockTakingId: number; // 棚卸ID
	stockTakingScheduledDate: string; // 棚卸予定日時
	stockTakingDate: string; // 棚卸日時
	locationId: number;
	locationCode: string;
	locationName: string; // ロケーション名
	locationDetailId: number;
	locationDetailCode: string;
	locationDetailName: string; // 棚名
	skuId: number; // SKUID
	skuCode: string; // SKUコード
	stockQuantity: number; // 在庫数量
	stockTakingQuantity: number; // 棚卸数量
};

// export type StockTakingScheduleListData = {
// 	id: number;
// 	stockTakingScheduledDate: string; // 棚卸予定日
// 	locationName: string; // ロケーション名
// 	locationDetailsName: string; // 棚名
// 	sku: string; // SKU
// 	stockQuantity: number; // 在庫数量
// };

const listApi = createApiInterface().request(formStockStockTakingListInputSchema).response<{
	list: StockTakingListResponse[];
}>();

// const scheduleListApi = createApiInterface().request(object().required()).response<{
// 	list: StockTakingScheduleListData[];
// }>();
const scheduleCreateApi = createApiInterface().request(formStockStockTakingScheduleEntrySchema).response<{
	isSuccess: boolean;
}>();

// const resultListApi = createApiInterface().request(object().required()).response<{
// 	list: StockTakingScheduleListData[];
// }>();
const resultCreateApi = createApiInterface().request(formStockStockTakingResultEntrySchema).response<{
	isSuccess: boolean;
}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const stockTakingApi = createApiInterfaceMap({
	['/v1/stock/stock-taking/list']: listApi,
	['/v1/stock/stock-taking/delete']: deleteApi,
	// ['/v1/stock/stock-taking/entry-schedule/list']: scheduleListApi,
	['/v1/stock/stock-taking/entry-schedule/create']: scheduleCreateApi,
	// ['/v1/stock/stock-taking/entry-result/list']: resultListApi,
	['/v1/stock/stock-taking/entry-result/create']: resultCreateApi,
});
