// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingPickingCodeSchema, typeShippingPickingNameSchema } from "./../../shipping";
import { InferType } from "yup";

export const formShippingShippingTotalPickingListPickingCodeSchema = typeShippingPickingCodeSchema.optional();
export type FormShippingShippingTotalPickingListPickingCodeSchemaType = InferType<typeof formShippingShippingTotalPickingListPickingCodeSchema>;
export const formShippingShippingTotalPickingListPickingNameSchema = typeShippingPickingNameSchema.optional();
export type FormShippingShippingTotalPickingListPickingNameSchemaType = InferType<typeof formShippingShippingTotalPickingListPickingNameSchema>;
export const formShippingShippingTotalPickingListSchema = yup.object({
	pickingCode: formShippingShippingTotalPickingListPickingCodeSchema,
	pickingName: formShippingShippingTotalPickingListPickingNameSchema,
}).required();
export type FormShippingShippingTotalPickingListSchemaType = InferType<typeof formShippingShippingTotalPickingListSchema>;
