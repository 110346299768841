/** ロケーション明細マスタ */

import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterWarehouseLocationDetailEntrySchema, formMasterWarehouseLocationDetailListSchema } from '../../../schema/form/master/warehouse';

export type LocationDetailListResponse = MasterResponse & {
	locationDetailId: number; // ロケーション明細ID
	locationDetailCode: string; // ロケーション明細コード
	locationDetailName: string; // ロケーション明細名
	locationDetailNote: string | undefined; // 備考
	shipperId: number | undefined; // 荷主ID
	shipperCode: string | undefined; // 荷主コード
	shipperName: string | undefined; // 荷主名
	locationId: number; // ロケーションID
	locationCode: string; // ロケーションコード
	locationName: string; // ロケーション名
	aisleId: number | undefined; // 通路ID
	aisleCode: string | undefined; // 通路コード
	aisleName: string | undefined; // 通路名
	locationDetailDepthM: number | undefined; // ロケーション明細の縦幅(m)
	locationDetailWidthM: number | undefined; // ロケーション明細の横幅(m)
	locationDetailHeightM: number | undefined; // ロケーション明細の高さ(m)
	allocationPriority: number | undefined; // 割り当て優先順位
	mixedLocationFlag: boolean; // 混在ロケーション
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: LocationDetailListResponse[] }>();

const listApi = createApiInterface().request(formMasterWarehouseLocationDetailListSchema).response<{
	list: LocationDetailListResponse[];
}>();

const createApi = createApiInterface().request(formMasterWarehouseLocationDetailEntrySchema).response<{
	isSuccess: boolean;
	locationDetailId?: number;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterWarehouseLocationDetailEntrySchema }))
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const locationDetailsApi = createApiInterfaceMap({
	['/v1/master/warehouse/location/detail/find']: findApi,
	['/v1/master/warehouse/location/detail/list']: listApi,
	['/v1/master/warehouse/location/detail/create']: createApi,
	['/v1/master/warehouse/location/detail/update']: updateApi,
	['/v1/master/warehouse/location/detail/delete']: deleteApi,
});
