import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterWarehouseStoringConditionEntrySchema, formMasterWarehouseStoringConditionListSchema } from '../../../schema/form/master/warehouse';

export type StoringConditionListResponse = MasterResponse & {
	storingConditionId: number; // 保管条件ID
	storingConditionCode: string; // 保管条件コード
	storingConditionName: string; // 保管条件名
	storingConditionDifferenceFlag: boolean; // 保管条件相違時の登録
	storingConditionNote: string | undefined;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: StoringConditionListResponse[] }>();

const listApi = createApiInterface().request(formMasterWarehouseStoringConditionListSchema.notRequired()).response<{
	list: StoringConditionListResponse[];
}>();

const createApi = createApiInterface().request(formMasterWarehouseStoringConditionEntrySchema).response<{
	isSuccess: boolean;
	storingConditionId?: number;
}>();

const updateApi = createApiInterface()
	.request(
		object({
			id: number().required().integer(),
			form: formMasterWarehouseStoringConditionEntrySchema,
		})
	)
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const storingConditionApi = createApiInterfaceMap({
	['/v1/master/warehouse/storing-condition/find']: findApi,
	['/v1/master/warehouse/storing-condition/list']: listApi,
	['/v1/master/warehouse/storing-condition/create']: createApi,
	['/v1/master/warehouse/storing-condition/update']: updateApi,
	['/v1/master/warehouse/storing-condition/delete']: deleteApi,
});
