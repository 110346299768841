/** トータルピッキング */
import { number, object } from 'yup';
import { ListResponse } from '../../response';
import { formShippingShippingTotalPickingListSchema } from '../../schema/form/shipping/shippingTotalPickingList';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { formShippingShippingPickingEntrySchema } from '../../schema/form/shipping/shippingPickingEntry';

export type TotalPickingListResponse = ListResponse & {
	id: number;
	shipperId: number; // 荷主Id
	shipperCode: string; // 荷主コード
	shipperName: string; // 荷主名
	pickingId: number; // ピッキングID
	pickingCode: string; // ピッキングコード
	pickingName: string; // ピッキング名
	pickingStateId: number; // ピッキングステータスID
	pickingStateCode: string; // ピッキングステータスコード
	pickingStateName: string; // ピッキングステータス名
	pickingCategoryId: number; // ピッキングカテゴリID
	pickingCategoryCode: string; // ピッキングカテゴリコード
	pickingCategoryName: string; // ピッキングカテゴリ名
	pickingQuantity: number; // ピック予定数
	pickingStartTime: string; // ピッキング開始日時
	pickingEndTime: string; // ピッキング終了日時
	pickingNote: string | undefined; // ピッキング備考
	shippingCount: number; // 出荷件数
};

const listApi = createApiInterface().request(formShippingShippingTotalPickingListSchema).response<{
	list: TotalPickingListResponse[];
}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const entryApi = createApiInterface()
	.request(object({ form: formShippingShippingPickingEntrySchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

export const totalPickingApi = createApiInterfaceMap({
	['/v1/shipping/total-picking/list']: listApi,
	['/v1/shipping/total-picking/delete']: deleteApi,
	['/v1/shipping/total-picking/entry']: entryApi,
});
