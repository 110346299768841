import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterDeliveryCompanyEntrySchema, formMasterDeliveryCompanyListSchema } from '../../../schema/form/master/deliveryCompany';

export type DeliveryCompanyListResponse = MasterResponse & {
	deliveryCompanyId: number;
	deliveryCompanyCode: string; // 配送業者コード
	deliveryCompanyName: string; // 配送業者名
	deliveryCompanyUrl: string | undefined; // 配送業者URL
	baggageTrackingUrl: string | undefined; // 荷物追跡URL
	deliveryCompanyNote: string | undefined; // 備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: DeliveryCompanyListResponse[] }>();

const listApi = createApiInterface().request(formMasterDeliveryCompanyListSchema).response<{
	list: DeliveryCompanyListResponse[];
}>();

const createApi = createApiInterface().request(formMasterDeliveryCompanyEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterDeliveryCompanyEntrySchema }))
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const deliveryCompanyApi = createApiInterfaceMap({
	['/v1/master/delivery/company/find']: findApi,
	['/v1/master/delivery/company/list']: listApi,
	['/v1/master/delivery/company/create']: createApi,
	['/v1/master/delivery/company/update']: updateApi,
	['/v1/master/delivery/company/delete']: deleteApi,
});
