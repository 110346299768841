/** ロケーションマスタ */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterWarehouseLocationEntrySchema, formMasterWarehouseLocationEntryUpdateSchema, formMasterWarehouseLocationListSchema } from '../../../schema/form/master/warehouse';

export type LocationListResponse = MasterResponse & {
	locationId: number; // ロケーションID
	locationCode: string; // ロケーションコード
	locationName: string; // ロケーション名
	locationNote: string | undefined; // 備考
	shipperId: number; // 荷主ID
	shipperCode: string; // 荷主コード
	shipperName: string; // 荷主名
	warehouseId: number; // 倉庫ID
	warehouseCode: string; // 倉庫コード
	warehouseName: string; // 倉庫名
	locationCategoryId: number; // ロケーション区分ID
	locationCategoryCode: string; // ロケーション区分コード
	locationCategoryName: string; // ロケーション区分名
	storingConditionId: number; // 保管条件ID
	storingConditionCode: string; // 保管条件コード
	storingConditionName: string; // 保管条件名
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: LocationListResponse[] }>();

const listApi = createApiInterface().request(formMasterWarehouseLocationListSchema).response<{ list: LocationListResponse[] }>();

const createApi = createApiInterface().request(formMasterWarehouseLocationEntrySchema).response<{ isSuccess: boolean }>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterWarehouseLocationEntryUpdateSchema }))
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const locationApi = createApiInterfaceMap({
	['/v1/master/warehouse/location/find']: findApi,
	['/v1/master/warehouse/location/list']: listApi,
	['/v1/master/warehouse/location/create']: createApi,
	['/v1/master/warehouse/location/update']: updateApi,
	['/v1/master/warehouse/location/delete']: deleteApi,
});
