/** 入出庫グループ */
import { number, object } from 'yup';
import { ListResponse } from '../../response';
import { formReceivingReceivingAndIssuingGroupEntrySchema } from '../../schema/form/receiving/receivingAndIssuingGroupEntry';
import { formReceivingReceivingAndIssuingGroupListSchema } from '../../schema/form/receiving/receivingAndIssuingGroupList';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';

export type ReceivingIssueGroupListResponse = ListResponse & {
	receiveIssueGroupId: number; // 入出庫グループID
	receiveIssueGroupName: string; // 入出庫グループ名
	receiveIssueGroupNote: string; // 備考
};

// export type ReceivingIssueGroupDetailListResponse = ListResponse & {
// 	receiveIssueGroupDetailId: number; // 入出庫グループ明細ID
// 	receiveIssueGroupId: number; // 入出庫グループID
// 	receiveIssueGroupName: string; // 入出庫グループ名
// 	receiveIssueId: number; // 入出庫ID
// 	receiveIssueCode: string; // 入出庫コード
// 	receiveIssueName: string; // 入出庫名
// };

export type ReceivingIssueGroupDetailListResponse = ReceivingIssueGroupListResponse & {
	receivingDetail: ReceivingDetail[];
	issuingDetail: IssuingDetail[];
};

export type ReceivingDetail = {
	receiveIssueGroupDetailId: number | undefined; // 入出庫グループ明細ID
	receiveIssueId: number | undefined;
	receiveIssueCode: string | undefined;
	receiveIssueName: string | undefined;
	receiveIssueDetailId: number | undefined;
	receiveIssueDetailCode: string | undefined;
	receiveIssueDetailName: string | undefined;
	receiveIssueCategoryId: number | undefined;
	receiveIssueCategoryCode: string | undefined;
	receiveIssueCategoryName: string | undefined;
	receivingScheduledDate: string | undefined;
	receivingDate: string | undefined;
};
export type IssuingDetail = {
	receiveIssueGroupDetailId: number | undefined; // 入出庫グループ明細ID
	receiveIssueId: number | undefined;
	receiveIssueCode: string | undefined;
	receiveIssueName: string | undefined;
	receiveIssueDetailId: number | undefined;
	receiveIssueDetailCode: string | undefined;
	receiveIssueDetailName: string | undefined;
	receiveIssueCategoryId: number | undefined;
	receiveIssueCategoryCode: string | undefined;
	receiveIssueCategoryName: string | undefined;
	issuingScheduledDate: string | undefined;
	issuingDate: string | undefined;
};

export type ReceivingIssueListResponse = ListResponse & {
	receiveIssueId: number;
	receiveIssueCode: string;
	receiveIssueName: string;
	receiveIssueCategoryId: number;
	receiveIssueCategoryCode: string;
	receiveIssueCategoryName: string;
	issuingScheduledDate: string | undefined;
	issuingDate: string | undefined;
	receivingScheduledDate: string | undefined;
	receivingDate: string | undefined;
	movingSourceLocationDetailId: number;
	movingSourseLocationDetailCode: string;
	movingSourseLocationDetailName: string;
	destiNationLocationDetailId: number | undefined;
	destiNationLocationDetailCode: string | undefined;
	destiNationLocationDetailName: string | undefined;
};

const listApi = createApiInterface().request(formReceivingReceivingAndIssuingGroupListSchema).response<{
	list: ReceivingIssueGroupListResponse[];
}>();

const createApi = createApiInterface().request(formReceivingReceivingAndIssuingGroupEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().integer().required(), form: formReceivingReceivingAndIssuingGroupEntrySchema }))
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

const findApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: ReceivingIssueGroupDetailListResponse[];
	}>();

// const addTableApi = createApiInterface().request(object().required()).response<{
// 	list: ReceivingIssueListResponse[];
// }>();

export const receivingAndIssuingGroupApi = createApiInterfaceMap({
	['/v1/receiving/group/list']: listApi,
	['/v1/receiving/group/create']: createApi,
	['/v1/receiving/group/update']: updateApi,
	['/v1/receiving/group/delete']: deleteApi,
	['/v1/receiving/group/find']: findApi,
	// ['/v1/receiving/group/add-table']: addTableApi,
});
