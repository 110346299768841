import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { array, number, object } from 'yup';
import { BasicResponse } from '../../response';

export type ReceiveIssueLabelResponse = BasicResponse & {
	// 入出庫情報
	receiveIssueId: number; // 入出庫ID
	receiveIssueCode: string; // 入出庫コード
	receiveIssueName: string; // 入出庫名
	receivingScheduledDate: string; // 入庫予定日
	issuingScheduledDate: string; // 出庫予定日

	// 移動先
	destinationLocationDetailId: number | undefined; // 移動先ロケーション明細ID
	destinationLocationDetailCode: string | undefined; // 移動先ロケーション明細コード
	destinationLocationDetailName: string | undefined; // 移動先ロケーション明細名
	warehouseId: number | undefined;
	warehouseCode: string | undefined;
	warehouseName: string | undefined;
	locationId: number | undefined;
	locationCode: string | undefined;
	locationName: string | undefined;

	// 入出庫明細
	receiveIssueLabelDetail: ReceiveIssueLabelDetailResponse[];
};

type ReceiveIssueLabelDetailResponse = {
	// 入出庫明細
	receiveIssueDetailId: number | undefined; // 入出庫明細ID
	receivingScheduledQuantity: number | undefined; // 入庫予定数量
	receivingQuantity: number | undefined; // 入庫数量
};

const listApi = createApiInterface()
	.request(object({ id: array().of(number().integer().required()) }))
	.response<{
		list: ReceiveIssueLabelResponse[];
	}>();

export const receiveIssueLabelApi = createApiInterfaceMap({
	['/v1/print/receive-issue-label/list']: listApi,
});
