// fv-generatorで生成されました
import * as yup from "yup";
import { typeReceivingCodeSchema, typeReceivingNameSchema, typeReceivingDateSchema, typeReceivingCategoryIdSchema, typeReceivingMovingSourceLocationDetailIdSchema, typeReceivingMovingSourceLocationDetailCodeSchema, typeReceivingMovingSourceLocationDetailNameSchema, typeReceivingDestinationLocationDetailIdSchema, typeReceivingDestinationLocationDetailCodeSchema, typeReceivingDestinationLocationDetailNameSchema, typeReceivingDetailIdSchema, typeReceivingScheduledQuantitySchema, typeReceivingQuantitySchema } from "./../../receiving";
import { InferType } from "yup";
import { typeCommonNoteSchema } from "./../../common";
import { typeMasterProductSkuIdSchema, typeMasterProductIdSchema } from "./../../master";

export const formReceivingReceivingResultEntryReceiveIssueCodeSchema = typeReceivingCodeSchema;
export type FormReceivingReceivingResultEntryReceiveIssueCodeSchemaType = InferType<typeof formReceivingReceivingResultEntryReceiveIssueCodeSchema>;
export const formReceivingReceivingResultEntryReceiveIssueNameSchema = typeReceivingNameSchema;
export type FormReceivingReceivingResultEntryReceiveIssueNameSchemaType = InferType<typeof formReceivingReceivingResultEntryReceiveIssueNameSchema>;
export const formReceivingReceivingResultEntryReceivingDateSchema = typeReceivingDateSchema;
export type FormReceivingReceivingResultEntryReceivingDateSchemaType = InferType<typeof formReceivingReceivingResultEntryReceivingDateSchema>;
export const formReceivingReceivingResultEntryReceiveIssueCategoryIdSchema = typeReceivingCategoryIdSchema;
export type FormReceivingReceivingResultEntryReceiveIssueCategoryIdSchemaType = InferType<typeof formReceivingReceivingResultEntryReceiveIssueCategoryIdSchema>;
export const formReceivingReceivingResultEntryMovingSourceLocationDetailIdSchema = typeReceivingMovingSourceLocationDetailIdSchema.optional();
export type FormReceivingReceivingResultEntryMovingSourceLocationDetailIdSchemaType = InferType<typeof formReceivingReceivingResultEntryMovingSourceLocationDetailIdSchema>;
export const formReceivingReceivingResultEntryMovingSourceLocationDetailCodeSchema = typeReceivingMovingSourceLocationDetailCodeSchema.optional();
export type FormReceivingReceivingResultEntryMovingSourceLocationDetailCodeSchemaType = InferType<typeof formReceivingReceivingResultEntryMovingSourceLocationDetailCodeSchema>;
export const formReceivingReceivingResultEntryMovingSourceLocationDetailNameSchema = typeReceivingMovingSourceLocationDetailNameSchema.optional();
export type FormReceivingReceivingResultEntryMovingSourceLocationDetailNameSchemaType = InferType<typeof formReceivingReceivingResultEntryMovingSourceLocationDetailNameSchema>;
export const formReceivingReceivingResultEntryDestinationLocationDetailIdSchema = typeReceivingDestinationLocationDetailIdSchema.optional();
export type FormReceivingReceivingResultEntryDestinationLocationDetailIdSchemaType = InferType<typeof formReceivingReceivingResultEntryDestinationLocationDetailIdSchema>;
export const formReceivingReceivingResultEntryDestinationLocationDetailCodeSchema = typeReceivingDestinationLocationDetailCodeSchema.optional();
export type FormReceivingReceivingResultEntryDestinationLocationDetailCodeSchemaType = InferType<typeof formReceivingReceivingResultEntryDestinationLocationDetailCodeSchema>;
export const formReceivingReceivingResultEntryDestinationLocationDetailNameSchema = typeReceivingDestinationLocationDetailNameSchema.optional();
export type FormReceivingReceivingResultEntryDestinationLocationDetailNameSchemaType = InferType<typeof formReceivingReceivingResultEntryDestinationLocationDetailNameSchema>;
export const formReceivingReceivingResultEntryReceiveIssueNoteSchema = typeCommonNoteSchema;
export type FormReceivingReceivingResultEntryReceiveIssueNoteSchemaType = InferType<typeof formReceivingReceivingResultEntryReceiveIssueNoteSchema>;
export const formReceivingReceivingResultEntryReceivingDetailValueReceiveIssueDetailIdSchema = typeReceivingDetailIdSchema.optional();
export type FormReceivingReceivingResultEntryReceivingDetailValueReceiveIssueDetailIdSchemaType = InferType<typeof formReceivingReceivingResultEntryReceivingDetailValueReceiveIssueDetailIdSchema>;
export const formReceivingReceivingResultEntryReceivingDetailValueSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormReceivingReceivingResultEntryReceivingDetailValueSkuIdSchemaType = InferType<typeof formReceivingReceivingResultEntryReceivingDetailValueSkuIdSchema>;
export const formReceivingReceivingResultEntryReceivingDetailValueReceivingScheduledQuantitySchema = typeReceivingScheduledQuantitySchema.optional();
export type FormReceivingReceivingResultEntryReceivingDetailValueReceivingScheduledQuantitySchemaType = InferType<typeof formReceivingReceivingResultEntryReceivingDetailValueReceivingScheduledQuantitySchema>;
export const formReceivingReceivingResultEntryReceivingDetailValueReceivingQuantitySchema = typeReceivingQuantitySchema.optional();
export type FormReceivingReceivingResultEntryReceivingDetailValueReceivingQuantitySchemaType = InferType<typeof formReceivingReceivingResultEntryReceivingDetailValueReceivingQuantitySchema>;
export const formReceivingReceivingResultEntryReceivingDetailValueReceiveIssueDetailNoteSchema = typeCommonNoteSchema;
export type FormReceivingReceivingResultEntryReceivingDetailValueReceiveIssueDetailNoteSchemaType = InferType<typeof formReceivingReceivingResultEntryReceivingDetailValueReceiveIssueDetailNoteSchema>;
export const formReceivingReceivingResultEntryReceivingDetailValueProductIdSchema = typeMasterProductIdSchema.optional();
export type FormReceivingReceivingResultEntryReceivingDetailValueProductIdSchemaType = InferType<typeof formReceivingReceivingResultEntryReceivingDetailValueProductIdSchema>;
export const formReceivingReceivingResultEntryReceivingDetailValueSchema = yup.object({
	receiveIssueDetailId: formReceivingReceivingResultEntryReceivingDetailValueReceiveIssueDetailIdSchema,
	skuId: formReceivingReceivingResultEntryReceivingDetailValueSkuIdSchema,
	receivingScheduledQuantity: formReceivingReceivingResultEntryReceivingDetailValueReceivingScheduledQuantitySchema,
	receivingQuantity: formReceivingReceivingResultEntryReceivingDetailValueReceivingQuantitySchema,
	receiveIssueDetailNote: formReceivingReceivingResultEntryReceivingDetailValueReceiveIssueDetailNoteSchema,
	productId: formReceivingReceivingResultEntryReceivingDetailValueProductIdSchema,
}).optional();
export type FormReceivingReceivingResultEntryReceivingDetailValueSchemaType = InferType<typeof formReceivingReceivingResultEntryReceivingDetailValueSchema>;
export const formReceivingReceivingResultEntryReceivingDetailSchema = yup.array(
	formReceivingReceivingResultEntryReceivingDetailValueSchema
).required();
export type FormReceivingReceivingResultEntryReceivingDetailSchemaType = InferType<typeof formReceivingReceivingResultEntryReceivingDetailSchema>;
export const formReceivingReceivingResultEntrySchema = yup.object({
	receiveIssueCode: formReceivingReceivingResultEntryReceiveIssueCodeSchema,
	receiveIssueName: formReceivingReceivingResultEntryReceiveIssueNameSchema,
	receivingDate: formReceivingReceivingResultEntryReceivingDateSchema,
	receiveIssueCategoryId: formReceivingReceivingResultEntryReceiveIssueCategoryIdSchema,
	movingSourceLocationDetailId: formReceivingReceivingResultEntryMovingSourceLocationDetailIdSchema,
	movingSourceLocationDetailCode: formReceivingReceivingResultEntryMovingSourceLocationDetailCodeSchema,
	movingSourceLocationDetailName: formReceivingReceivingResultEntryMovingSourceLocationDetailNameSchema,
	destinationLocationDetailId: formReceivingReceivingResultEntryDestinationLocationDetailIdSchema,
	destinationLocationDetailCode: formReceivingReceivingResultEntryDestinationLocationDetailCodeSchema,
	destinationLocationDetailName: formReceivingReceivingResultEntryDestinationLocationDetailNameSchema,
	receiveIssueNote: formReceivingReceivingResultEntryReceiveIssueNoteSchema,
	receivingDetail: formReceivingReceivingResultEntryReceivingDetailSchema,
}).required();
export type FormReceivingReceivingResultEntrySchemaType = InferType<typeof formReceivingReceivingResultEntrySchema>;
