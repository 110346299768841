/** セット崩し検品入力 */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formStockBreakSetProductInspectionSchema } from '../../../schema/form/stock/breakSetProductInspection';
import { BasicResponse } from '../../../response';
import { formStockBreakSetProductInspectionResultSchema } from '../../../schema/form/stock/breakSetProductInspectionResult';
import { formStockBreakSetProductInspectionMobileEntrySchema } from '../../../schema/form/stock/breakSetProductInspectionMobileEntry';

export type BreakSetProductInspectionListResponse = BasicResponse & {
	// 検品
	inspectionId: number;
	inspectionLog: BreakSetProductInspectionLog[];
	// SKUグループ
	skuGroupId: number;
	skuGroupCode: string;
	// SKUグループ明細
	skuGroupDetailId: number;
	setRequiredQuantity: number; // セット必要数量
	inspectionQuantity: number | undefined; // 検品数量
	// SKU（明細）
	skuId: number;
	skuCode: string;
	// 商品
	productId: number;
	productCode: string;
	productName: string;
};

type BreakSetProductInspectionLog = {
	inspectionLogId: number;
	lotId: number;
	inspectionQuantity: number;
};

const findApi = createApiInterface().request(formStockBreakSetProductInspectionSchema).response<{
	list: BreakSetProductInspectionListResponse[];
}>();

const registApi = createApiInterface()
	.request(object({ form: formStockBreakSetProductInspectionResultSchema, id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const mobileRegistApi = createApiInterface()
	.request(object({ form: formStockBreakSetProductInspectionMobileEntrySchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

export const breakSetProductInspectionApi = createApiInterfaceMap({
	['/v1/stock/break-set-product/inspection/find']: findApi,
	['/v1/stock/break-set-product/inspection/regist']: registApi,
	['/v1/stock/break-set-product/inspection/delete']: deleteApi,
	['/v1/stock/break-set-product/inspection/mobile']: mobileRegistApi,
});
