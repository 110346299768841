/** 欠品一覧 */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formStockStockOutListInputSchema } from '../../../schema/form/stock/stockOutListInput';
import { ListResponse } from '../../../response';

export type StockOutListResponse = ListResponse & {
	stockId: number; // 在庫ID
	warehouseId: number; // 倉庫ID
	warehouseCode: string; // 倉庫コード
	warehouseName: string; // 倉庫名
	locationId: number; // ロケーションID
	locationCode: string; // ロケーションコード
	locationName: string; // ロケーション名
	locationDetailId: number; // ロケーション明細ID
	locationDetailCode: string; // ロケーション明細コード
	locationDetailName: string; // ロケーション明細名
	skuId: number; // SKUID
	skuCode: string; // SKUコード
	productId: number; // 商品ID
	productCode: string; // 商品コード
	productName: string; // 商品名
	partNumberId: number; // 品番ID
	partNumberCode: string; // 品番
	partNumberName: string; // 品名
	janCode: string; // JANコード
};

const listApi = createApiInterface().request(formStockStockOutListInputSchema).response<{ list: StockOutListResponse[] }>();
const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{ isSuccess: boolean }>();

export const stockOutApi = createApiInterfaceMap({
	['/v1/stock/stock-out/list']: listApi,
	['/v1/stock/stock-out/delete']: deleteApi,
});
