// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterArrivalCategoryCodeSchema, typeMasterArrivalCategoryNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema } from "./../../common";

export const formMasterArrivalCategoryListArrivalCategoryCodeSchema = typeMasterArrivalCategoryCodeSchema.optional();
export type FormMasterArrivalCategoryListArrivalCategoryCodeSchemaType = InferType<typeof formMasterArrivalCategoryListArrivalCategoryCodeSchema>;
export const formMasterArrivalCategoryListArrivalCategoryNameSchema = typeMasterArrivalCategoryNameSchema.optional();
export type FormMasterArrivalCategoryListArrivalCategoryNameSchemaType = InferType<typeof formMasterArrivalCategoryListArrivalCategoryNameSchema>;
export const formMasterArrivalCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterArrivalCategoryListIsAvailableSchemaType = InferType<typeof formMasterArrivalCategoryListIsAvailableSchema>;
export const formMasterArrivalCategoryListSchema = yup.object({
	arrivalCategoryCode: formMasterArrivalCategoryListArrivalCategoryCodeSchema,
	arrivalCategoryName: formMasterArrivalCategoryListArrivalCategoryNameSchema,
	isAvailable: formMasterArrivalCategoryListIsAvailableSchema,
}).required();
export type FormMasterArrivalCategoryListSchemaType = InferType<typeof formMasterArrivalCategoryListSchema>;
export const formMasterArrivalCategoryEntryArrivalCategoryCodeSchema = typeMasterArrivalCategoryCodeSchema;
export type FormMasterArrivalCategoryEntryArrivalCategoryCodeSchemaType = InferType<typeof formMasterArrivalCategoryEntryArrivalCategoryCodeSchema>;
export const formMasterArrivalCategoryEntryArrivalCategoryNameSchema = typeMasterArrivalCategoryNameSchema;
export type FormMasterArrivalCategoryEntryArrivalCategoryNameSchemaType = InferType<typeof formMasterArrivalCategoryEntryArrivalCategoryNameSchema>;
export const formMasterArrivalCategoryEntryArrivalCategoryNoteSchema = typeCommonNoteSchema;
export type FormMasterArrivalCategoryEntryArrivalCategoryNoteSchemaType = InferType<typeof formMasterArrivalCategoryEntryArrivalCategoryNoteSchema>;
export const formMasterArrivalCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterArrivalCategoryEntryAvailableFromSchemaType = InferType<typeof formMasterArrivalCategoryEntryAvailableFromSchema>;
export const formMasterArrivalCategoryEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterArrivalCategoryEntryAvailableToSchemaType = InferType<typeof formMasterArrivalCategoryEntryAvailableToSchema>;
export const formMasterArrivalCategoryEntrySchema = yup.object({
	arrivalCategoryCode: formMasterArrivalCategoryEntryArrivalCategoryCodeSchema,
	arrivalCategoryName: formMasterArrivalCategoryEntryArrivalCategoryNameSchema,
	arrivalCategoryNote: formMasterArrivalCategoryEntryArrivalCategoryNoteSchema,
	availableFrom: formMasterArrivalCategoryEntryAvailableFromSchema,
	availableTo: formMasterArrivalCategoryEntryAvailableToSchema,
}).required();
export type FormMasterArrivalCategoryEntrySchemaType = InferType<typeof formMasterArrivalCategoryEntrySchema>;
