/** セット組み一覧 */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formStockSetProductListInputSchema } from '../../../schema/form/stock/setProductListInput';
import { ListResponse } from '../../../response';
import { formStockSetProductResultEntrySchema } from '../../../schema/form/stock/setProductResultEntry';
import { formStockSetProductScheduleEntrySchema } from '../../../schema/form/stock/setProductScheduleEntry';

export type SetProductListResponse = ListResponse & {
	id: number;
	skuGroupId: number; // SKUグループID
	skuGroupCode: string; // SKUグループコード
	skuGroupName: string; // SKUグループ名
	skuGroupNote: string | undefined; // SKUグループ備考
	skuId: number; // SKUID
	skuCode: string; // SKUコード
	storingConditionId: number | undefined; // 保管条件ID
	storingConditionCode: string | undefined; // 保管条件コード
	storingConditionName: string | undefined; // 保管条件名
};

export type SetProductDetailListResponse = SetProductListResponse & {
	skuGroupDetailId: number; // SKUグループ明細ID
	setRequiredQuantity: number; // セット必要数量
};

/** セット組み一覧 */
const listApi = createApiInterface().request(formStockSetProductListInputSchema).response<{
	list: SetProductListResponse[];
}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

const scheduleCreateApi = createApiInterface().request(formStockSetProductScheduleEntrySchema).response<{
	isSuccess: boolean;
}>();

const scheduleFindApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: SetProductListResponse[];
	}>();

const resultCreateApi = createApiInterface().request(formStockSetProductResultEntrySchema).response<{
	isSuccess: boolean;
}>();

const resultFindApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: SetProductListResponse[];
	}>();

export const setProductApi = createApiInterfaceMap({
	['/v1/stock/set-product/list']: listApi,
	['/v1/stock/set-product/delete']: deleteApi,
	['/v1/stock/set-product/entry-schedule/create']: scheduleCreateApi,
	['/v1/stock/set-product/entry-schedule/find']: scheduleFindApi,
	['/v1/stock/set-product/entry-result/create']: resultCreateApi,
	['/v1/stock/set-product/entry-result/find']: resultFindApi,
});
