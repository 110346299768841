import { object } from 'yup';
import { MasterResponse } from '../../../response';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';

export type SKUListResponse = MasterResponse & {
	skuId: number;
	skuCode: string;
	skuNote: string | undefined;
};

// SelctCommonのmenuItem用です
const listApi = createApiInterface().request(object().required()).response<{
	list: SKUListResponse[];
}>();

export const skuApi = createApiInterfaceMap({
	['/v1/master/product/sku/list']: listApi,
});
