import { number, object } from 'yup';
import { MasterResponse } from '../../../response';
import { formMasterProductSkuGroupEntrySchema, formMasterProductSkuGroupListSchema } from '../../../schema/form/master/product';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';

export type SkuGroupListResponse = MasterResponse & {
	skuGroupId: number;
	skuGroupCode: string;
	skuGroupName: string;
	skuGroupNote: string | undefined;
	skuId: number;
	skuCode: string;
	storingConditionId: number | undefined;
	storingConditionCode: string | undefined;
	storingConditionName: string | undefined;
};

export type SkuGroupDetailListResponse = SkuGroupListResponse & {
	skuGroupDetail: SkuGroupDetail[];
};

type SkuGroupDetail = {
	skuGroupDetailId: number | undefined;
	skuId: number | undefined;
	setRequiredQuantity: number | undefined;
	productId: number | undefined;
};

const listApi = createApiInterface().request(formMasterProductSkuGroupListSchema).response<{
	list: SkuGroupListResponse[];
}>();

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: SkuGroupDetailListResponse[] }>();

const createApi = createApiInterface().request(formMasterProductSkuGroupEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterProductSkuGroupEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{ isSuccess: boolean }>();

export const skuGroupApi = createApiInterfaceMap({
	['/v1/master/product/sku-group/list']: listApi,
	['/v1/master/product/sku-group/find']: findApi,
	['/v1/master/product/sku-group/create']: createApi,
	['/v1/master/product/sku-group/update']: updateApi,
	['/v1/master/product/sku-group/delete']: deleteApi,
});
