import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { array, number, object } from 'yup';
import { BasicResponse } from '../../response';

export type StockMovementDetailListResponse = BasicResponse & {
	// 在庫(stock)
	stockId: number;
	stockQuantity: number;

	// 在庫履歴, ロケーション(stock_log, location)
	stockLogId: number;
	stockOperationDatetime: string;
	moveSourceLocationDetailId: number;
	moveSourceLocationDetailCode: string;
	moveSourceLocationDetailName: string;
	moveSourceLocationId: number;
	moveSourceLocationCode: string;
	moveSourceLocationName: string;
	destinationLocationDetailId: number;
	destinationLocationDetailCode: string;
	destinationLocationDetailName: string;
	destinationLocationId: number;
	destinationLocationCode: string;
	destinationLocationName: string;
	quantity: number;

	// SKUロケーション, SKU(sku_location_detail, sku)
	skuLocationDetailId: number;
	skuId: number;
	sku: string;
};

const listApi = createApiInterface()
	.request(object({ id: array().of(number().integer().required()) }))
	.response<{
		list: StockMovementDetailListResponse[];
	}>();

export const stockMovementDetailListApi = createApiInterfaceMap({
	['/v1/print/stock/movement-detail-list']: listApi,
});
