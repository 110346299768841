// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const formStockSetProductInspectionResultSkuGroupDetailIdValueSchema = yup.number().optional().integer();
export type FormStockSetProductInspectionResultSkuGroupDetailIdValueSchemaType = InferType<typeof formStockSetProductInspectionResultSkuGroupDetailIdValueSchema>;
export const formStockSetProductInspectionResultSkuGroupDetailIdSchema = yup.array(
	formStockSetProductInspectionResultSkuGroupDetailIdValueSchema
).optional();
export type FormStockSetProductInspectionResultSkuGroupDetailIdSchemaType = InferType<typeof formStockSetProductInspectionResultSkuGroupDetailIdSchema>;
export const formStockSetProductInspectionResultInspectionQuantityValueSchema = yup.number().optional().integer();
export type FormStockSetProductInspectionResultInspectionQuantityValueSchemaType = InferType<typeof formStockSetProductInspectionResultInspectionQuantityValueSchema>;
export const formStockSetProductInspectionResultInspectionQuantitySchema = yup.array(
	formStockSetProductInspectionResultInspectionQuantityValueSchema
).optional();
export type FormStockSetProductInspectionResultInspectionQuantitySchemaType = InferType<typeof formStockSetProductInspectionResultInspectionQuantitySchema>;
export const formStockSetProductInspectionResultSchema = yup.object({
	skuGroupDetailId: formStockSetProductInspectionResultSkuGroupDetailIdSchema,
	inspectionQuantity: formStockSetProductInspectionResultInspectionQuantitySchema,
}).required();
export type FormStockSetProductInspectionResultSchemaType = InferType<typeof formStockSetProductInspectionResultSchema>;
