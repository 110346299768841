import { object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';

export type DeliveryCompanyTimeSpanListResponse = MasterResponse & {
	deliveryCompanyTimeSpanId: number; // 配送業者配達時間帯ID
	deliveryCompanyTimeSpanCode: string; // 配送業者配達時間帯コード
	deliveryCompanyTimeSpanName: string; // 配送業者配達時間帯名
	deliveryCompanyId: number; // 配達業者ID
	deliveryCompanyTimeSpanNote: string | undefined; // 配送業者配達時間帯備考
};

const listApi = createApiInterface().request(object().required()).response<{
	list: DeliveryCompanyTimeSpanListResponse[];
}>();

export const deliveryCompanyTimeSpanApi = createApiInterfaceMap({
	['/v1/master/delivery/delivery-company-time-span/list']: listApi,
});
