// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const formArrivalArrivalInspectionResultInspectionIdValueSchema = yup.number().optional().integer();
export type FormArrivalArrivalInspectionResultInspectionIdValueSchemaType = InferType<typeof formArrivalArrivalInspectionResultInspectionIdValueSchema>;
export const formArrivalArrivalInspectionResultInspectionIdSchema = yup.array(
	formArrivalArrivalInspectionResultInspectionIdValueSchema
).optional();
export type FormArrivalArrivalInspectionResultInspectionIdSchemaType = InferType<typeof formArrivalArrivalInspectionResultInspectionIdSchema>;
export const formArrivalArrivalInspectionResultInspectionQuantityValueSchema = yup.number().optional().integer();
export type FormArrivalArrivalInspectionResultInspectionQuantityValueSchemaType = InferType<typeof formArrivalArrivalInspectionResultInspectionQuantityValueSchema>;
export const formArrivalArrivalInspectionResultInspectionQuantitySchema = yup.array(
	formArrivalArrivalInspectionResultInspectionQuantityValueSchema
).optional();
export type FormArrivalArrivalInspectionResultInspectionQuantitySchemaType = InferType<typeof formArrivalArrivalInspectionResultInspectionQuantitySchema>;
export const formArrivalArrivalInspectionResultProductStatusValueValueSchema = yup.number().optional().integer();
export type FormArrivalArrivalInspectionResultProductStatusValueValueSchemaType = InferType<typeof formArrivalArrivalInspectionResultProductStatusValueValueSchema>;
export const formArrivalArrivalInspectionResultProductStatusValueSchema = yup.array(
	formArrivalArrivalInspectionResultProductStatusValueValueSchema
).optional();
export type FormArrivalArrivalInspectionResultProductStatusValueSchemaType = InferType<typeof formArrivalArrivalInspectionResultProductStatusValueSchema>;
export const formArrivalArrivalInspectionResultProductStatusSchema = yup.array(
	formArrivalArrivalInspectionResultProductStatusValueSchema
).optional();
export type FormArrivalArrivalInspectionResultProductStatusSchemaType = InferType<typeof formArrivalArrivalInspectionResultProductStatusSchema>;
export const formArrivalArrivalInspectionResultSchema = yup.object({
	inspectionId: formArrivalArrivalInspectionResultInspectionIdSchema,
	inspectionQuantity: formArrivalArrivalInspectionResultInspectionQuantitySchema,
	productStatus: formArrivalArrivalInspectionResultProductStatusSchema,
}).required();
export type FormArrivalArrivalInspectionResultSchemaType = InferType<typeof formArrivalArrivalInspectionResultSchema>;
