// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionQuantitySchema } from "./../../common";
import { InferType } from "yup";
import { typeMasterProductLotIdSchema } from "./../../master";

export const formStockSetProductInspectionMobileEntryInspectionQuantitySchema = typeCommonInspectionQuantitySchema.optional();
export type FormStockSetProductInspectionMobileEntryInspectionQuantitySchemaType = InferType<typeof formStockSetProductInspectionMobileEntryInspectionQuantitySchema>;
export const formStockSetProductInspectionMobileEntryLotIdSchema = typeMasterProductLotIdSchema.optional();
export type FormStockSetProductInspectionMobileEntryLotIdSchemaType = InferType<typeof formStockSetProductInspectionMobileEntryLotIdSchema>;
export const formStockSetProductInspectionMobileEntrySchema = yup.object({
	inspectionQuantity: formStockSetProductInspectionMobileEntryInspectionQuantitySchema,
	lotId: formStockSetProductInspectionMobileEntryLotIdSchema,
}).required();
export type FormStockSetProductInspectionMobileEntrySchemaType = InferType<typeof formStockSetProductInspectionMobileEntrySchema>;
