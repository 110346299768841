import { GetApi, GetRequestType, GetResponseType, RootApi, rootApi } from 'type/share/api/root';
import { fetchTokenJSON } from '../api/token';

const promiseToken: Promise<string> = fetchTokenJSON();

export default async function fetchJSON(url = '', data = {}) {
	const response = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json',
			'Csrf-Token': await promiseToken,
		},
		redirect: 'follow',
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(data),
	});
	return response.json();
}

type CurrentVersion = '/v1';

// type CheckCurrentVersion<Path extends keyof RootApi> = Path extends `${CurrentVersion}${infer T}` ? Path : never;
type FilterCurrentVersion<Path extends keyof RootApi> = Path extends `${CurrentVersion}${infer T}` ? Path : never;

export function fetchTypedJSON<Path extends FilterCurrentVersion<keyof RootApi>>(url: Path, data: GetRequestType<Path>): Promise<GetResponseType<Path>> {
	const apiInterface: GetApi<Path> = rootApi[url];

	apiInterface.schema.validate(data).catch((err) => {
		console.error(`${url} のリクエストデータが不正です。`, err);
	});

	return fetchJSON('/api' + url, data);
}

export function createTypedFetcher<Path extends FilterCurrentVersion<keyof RootApi>>(url: Path) {
	return function fetcher(data: GetRequestType<Path>): Promise<GetResponseType<Path>> {
		return fetchTypedJSON(url, data);
	};
}

// デバック用
// コンソールからfetchJSONでアクセス出来ます。
// @ts-ignore
window.fetchJSON = fetchJSON;
