import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { array, number, object } from 'yup';
import { BasicResponse } from '../../response';

export type ReceiveIssueDetailListResponse = BasicResponse & {
	// 入出庫情報
	receiveIssueId: number; // 入出庫ID
	receiveIssueCode: string; // 入出庫コード
	receiveIssueName: string; // 入出庫名
	receivingScheduledDate: string; // 入庫予定日
	issuingScheduledDate: string; // 出庫予定日

	// 移動元
	movingSourceLocationDetailId: number | undefined; // 移動先ロケーション明細ID
	movingSourceLocationDetailCode: string | undefined; // 移動先ロケーション明細コード
	movingSourceLocationDetailName: string | undefined; // 移動先ロケーション明細名
	movingSourceWarehouseId: number | undefined;
	movingSourceWarehouseCode: string | undefined;
	movingSourceWarehouseName: string | undefined;
	movingSourceLocationId: number | undefined;
	movingSourceLocationCode: string | undefined;
	movingSourceLocationName: string | undefined;

	// 移動先
	destinationLocationDetailId: number | undefined; // 移動先ロケーション明細ID
	destinationLocationDetailCode: string | undefined; // 移動先ロケーション明細コード
	destinationLocationDetailName: string | undefined; // 移動先ロケーション明細名
	destinationWarehouseId: number | undefined;
	destinationWarehouseCode: string | undefined;
	destinationWarehouseName: string | undefined;
	destinationLocationId: number | undefined;
	destinationLocationCode: string | undefined;
	destinationLocationName: string | undefined;

	// 入出庫明細
	receiveIssueDetail: ReceiveIssueDetail[] | undefined;
};

type ReceiveIssueDetail = {
	// 入出庫明細
	receiveIssueDetailId: number | undefined; // 入出庫明細ID
	receivingScheduledQuantity: number | undefined; // 入庫予定数量

	skuLocationDetailId: number | undefined;
	skuId: number | undefined;

	sku: string | undefined;
	productId: number | undefined;
	partNumberId: number | undefined;
	caseQuantityPerCarton: number | undefined; // ケース入数
	partNumberSizeId: number | undefined;
	productVertical: number | undefined; // 商品縦
	productHorizontal: number | undefined; // 商品横
	productHeight: number | undefined; // 商品高さ
	weightOnlyProduct: number | undefined; // 商品のみ重さ

	stockId: number | undefined;
	stockQuantity: number | undefined;
};

const listApi = createApiInterface()
	.request(object({ id: array().of(number().integer().required()) }))
	.response<{
		list: ReceiveIssueDetailListResponse[];
	}>();

export const receiveIssueDetailListApi = createApiInterfaceMap({
	['/v1/print/receive-issue/detail-list']: listApi,
});
