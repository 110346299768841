// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterDeliveryCompanyIdSchema, typeMasterDeliveryCompanyCodeSchema, typeMasterDeliveryCompanyNameSchema, typeMasterDeliveryCompanyBaseCodeSchema, typeMasterDeliveryCompanyBaseNameSchema, typeMasterDeliveryCompanyBaseCustomerCodeSchema, typeMasterDeliveryCompanyDeliveryCompanyUrlSchema, typeMasterDeliveryCompanyBaggageTrackingUrlSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonPostalCodeSchema, typeCommonAddressSchema, typeCommonTelSchema, typeCommonFaxSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema } from "./../../common";

export const formMasterDeliveryCompanyBaseListDeliveryCompanyIdSchema = typeMasterDeliveryCompanyIdSchema.optional();
export type FormMasterDeliveryCompanyBaseListDeliveryCompanyIdSchemaType = InferType<typeof formMasterDeliveryCompanyBaseListDeliveryCompanyIdSchema>;
export const formMasterDeliveryCompanyBaseListDeliveryCompanyCodeSchema = typeMasterDeliveryCompanyCodeSchema.optional();
export type FormMasterDeliveryCompanyBaseListDeliveryCompanyCodeSchemaType = InferType<typeof formMasterDeliveryCompanyBaseListDeliveryCompanyCodeSchema>;
export const formMasterDeliveryCompanyBaseListDeliveryCompanyNameSchema = typeMasterDeliveryCompanyNameSchema.optional();
export type FormMasterDeliveryCompanyBaseListDeliveryCompanyNameSchemaType = InferType<typeof formMasterDeliveryCompanyBaseListDeliveryCompanyNameSchema>;
export const formMasterDeliveryCompanyBaseListDeliveryCompanyBaseCodeSchema = typeMasterDeliveryCompanyBaseCodeSchema.optional();
export type FormMasterDeliveryCompanyBaseListDeliveryCompanyBaseCodeSchemaType = InferType<typeof formMasterDeliveryCompanyBaseListDeliveryCompanyBaseCodeSchema>;
export const formMasterDeliveryCompanyBaseListDeliveryCompanyBaseNameSchema = typeMasterDeliveryCompanyBaseNameSchema.optional();
export type FormMasterDeliveryCompanyBaseListDeliveryCompanyBaseNameSchemaType = InferType<typeof formMasterDeliveryCompanyBaseListDeliveryCompanyBaseNameSchema>;
export const formMasterDeliveryCompanyBaseListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterDeliveryCompanyBaseListIsAvailableSchemaType = InferType<typeof formMasterDeliveryCompanyBaseListIsAvailableSchema>;
export const formMasterDeliveryCompanyBaseListSchema = yup.object({
	deliveryCompanyId: formMasterDeliveryCompanyBaseListDeliveryCompanyIdSchema,
	deliveryCompanyCode: formMasterDeliveryCompanyBaseListDeliveryCompanyCodeSchema,
	deliveryCompanyName: formMasterDeliveryCompanyBaseListDeliveryCompanyNameSchema,
	deliveryCompanyBaseCode: formMasterDeliveryCompanyBaseListDeliveryCompanyBaseCodeSchema,
	deliveryCompanyBaseName: formMasterDeliveryCompanyBaseListDeliveryCompanyBaseNameSchema,
	isAvailable: formMasterDeliveryCompanyBaseListIsAvailableSchema,
}).required();
export type FormMasterDeliveryCompanyBaseListSchemaType = InferType<typeof formMasterDeliveryCompanyBaseListSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyIdSchema = typeMasterDeliveryCompanyIdSchema;
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyIdSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyIdSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyCodeSchema = typeMasterDeliveryCompanyCodeSchema;
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyCodeSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyCodeSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyNameSchema = typeMasterDeliveryCompanyNameSchema;
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyNameSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyNameSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseCodeSchema = typeMasterDeliveryCompanyBaseCodeSchema;
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseCodeSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseCodeSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseNameSchema = typeMasterDeliveryCompanyBaseNameSchema;
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseNameSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseNameSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyBasePostalCodeSchema = typeCommonPostalCodeSchema;
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyBasePostalCodeSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyBasePostalCodeSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseAddressSchema = typeCommonAddressSchema;
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseAddressSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseAddressSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseTelSchema = typeCommonTelSchema;
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseTelSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseTelSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseFaxSchema = typeCommonFaxSchema;
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseFaxSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseFaxSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseCustomerCodeSchema = typeMasterDeliveryCompanyBaseCustomerCodeSchema.optional();
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseCustomerCodeSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseCustomerCodeSchema>;
export const formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseNoteSchema = typeCommonNoteSchema;
export type FormMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseNoteSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseNoteSchema>;
export const formMasterDeliveryCompanyBaseEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterDeliveryCompanyBaseEntryAvailableFromSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryAvailableFromSchema>;
export const formMasterDeliveryCompanyBaseEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterDeliveryCompanyBaseEntryAvailableToSchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntryAvailableToSchema>;
export const formMasterDeliveryCompanyBaseEntrySchema = yup.object({
	deliveryCompanyId: formMasterDeliveryCompanyBaseEntryDeliveryCompanyIdSchema,
	deliveryCompanyCode: formMasterDeliveryCompanyBaseEntryDeliveryCompanyCodeSchema,
	deliveryCompanyName: formMasterDeliveryCompanyBaseEntryDeliveryCompanyNameSchema,
	deliveryCompanyBaseCode: formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseCodeSchema,
	deliveryCompanyBaseName: formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseNameSchema,
	deliveryCompanyBasePostalCode: formMasterDeliveryCompanyBaseEntryDeliveryCompanyBasePostalCodeSchema,
	deliveryCompanyBaseAddress: formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseAddressSchema,
	deliveryCompanyBaseTel: formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseTelSchema,
	deliveryCompanyBaseFax: formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseFaxSchema,
	deliveryCompanyBaseCustomerCode: formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseCustomerCodeSchema,
	deliveryCompanyBaseNote: formMasterDeliveryCompanyBaseEntryDeliveryCompanyBaseNoteSchema,
	availableFrom: formMasterDeliveryCompanyBaseEntryAvailableFromSchema,
	availableTo: formMasterDeliveryCompanyBaseEntryAvailableToSchema,
}).required();
export type FormMasterDeliveryCompanyBaseEntrySchemaType = InferType<typeof formMasterDeliveryCompanyBaseEntrySchema>;
export const formMasterDeliveryCompanyListDeliveryCompanyCodeSchema = typeMasterDeliveryCompanyCodeSchema.optional();
export type FormMasterDeliveryCompanyListDeliveryCompanyCodeSchemaType = InferType<typeof formMasterDeliveryCompanyListDeliveryCompanyCodeSchema>;
export const formMasterDeliveryCompanyListDeliveryCompanyNameSchema = typeMasterDeliveryCompanyNameSchema.optional();
export type FormMasterDeliveryCompanyListDeliveryCompanyNameSchemaType = InferType<typeof formMasterDeliveryCompanyListDeliveryCompanyNameSchema>;
export const formMasterDeliveryCompanyListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterDeliveryCompanyListIsAvailableSchemaType = InferType<typeof formMasterDeliveryCompanyListIsAvailableSchema>;
export const formMasterDeliveryCompanyListSchema = yup.object({
	deliveryCompanyCode: formMasterDeliveryCompanyListDeliveryCompanyCodeSchema,
	deliveryCompanyName: formMasterDeliveryCompanyListDeliveryCompanyNameSchema,
	isAvailable: formMasterDeliveryCompanyListIsAvailableSchema,
}).required();
export type FormMasterDeliveryCompanyListSchemaType = InferType<typeof formMasterDeliveryCompanyListSchema>;
export const formMasterDeliveryCompanyEntryDeliveryCompanyCodeSchema = typeMasterDeliveryCompanyCodeSchema;
export type FormMasterDeliveryCompanyEntryDeliveryCompanyCodeSchemaType = InferType<typeof formMasterDeliveryCompanyEntryDeliveryCompanyCodeSchema>;
export const formMasterDeliveryCompanyEntryDeliveryCompanyNameSchema = typeMasterDeliveryCompanyNameSchema;
export type FormMasterDeliveryCompanyEntryDeliveryCompanyNameSchemaType = InferType<typeof formMasterDeliveryCompanyEntryDeliveryCompanyNameSchema>;
export const formMasterDeliveryCompanyEntryDeliveryCompanyUrlSchema = typeMasterDeliveryCompanyDeliveryCompanyUrlSchema.optional();
export type FormMasterDeliveryCompanyEntryDeliveryCompanyUrlSchemaType = InferType<typeof formMasterDeliveryCompanyEntryDeliveryCompanyUrlSchema>;
export const formMasterDeliveryCompanyEntryBaggageTrackingUrlSchema = typeMasterDeliveryCompanyBaggageTrackingUrlSchema.optional();
export type FormMasterDeliveryCompanyEntryBaggageTrackingUrlSchemaType = InferType<typeof formMasterDeliveryCompanyEntryBaggageTrackingUrlSchema>;
export const formMasterDeliveryCompanyEntryDeliveryCompanyNoteSchema = typeCommonNoteSchema;
export type FormMasterDeliveryCompanyEntryDeliveryCompanyNoteSchemaType = InferType<typeof formMasterDeliveryCompanyEntryDeliveryCompanyNoteSchema>;
export const formMasterDeliveryCompanyEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterDeliveryCompanyEntryAvailableFromSchemaType = InferType<typeof formMasterDeliveryCompanyEntryAvailableFromSchema>;
export const formMasterDeliveryCompanyEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterDeliveryCompanyEntryAvailableToSchemaType = InferType<typeof formMasterDeliveryCompanyEntryAvailableToSchema>;
export const formMasterDeliveryCompanyEntrySchema = yup.object({
	deliveryCompanyCode: formMasterDeliveryCompanyEntryDeliveryCompanyCodeSchema,
	deliveryCompanyName: formMasterDeliveryCompanyEntryDeliveryCompanyNameSchema,
	deliveryCompanyUrl: formMasterDeliveryCompanyEntryDeliveryCompanyUrlSchema,
	baggageTrackingUrl: formMasterDeliveryCompanyEntryBaggageTrackingUrlSchema,
	deliveryCompanyNote: formMasterDeliveryCompanyEntryDeliveryCompanyNoteSchema,
	availableFrom: formMasterDeliveryCompanyEntryAvailableFromSchema,
	availableTo: formMasterDeliveryCompanyEntryAvailableToSchema,
}).required();
export type FormMasterDeliveryCompanyEntrySchemaType = InferType<typeof formMasterDeliveryCompanyEntrySchema>;
