import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterProductSmallCategoryEntrySchema, formMasterProductSmallCategoryListSchema } from '../../../schema/form/master/product';

export type SmallCategoryResponse = MasterResponse & {
	productSmallCategoryId: number; // 商品小項目ID
	productSmallCategoryCode: string; // 商品小項目コード
	productSmallCategoryName: string; // 商品小項目名
	productLargeCategoryId: number; // 商品大項目ID
	productLargeCategoryCode: string; // 商品大項目コード
	productLargeCategoryName: string; // 商品大項目名
	productSmallCategoryDisplayOrder: number; // 表示順
	productSmallCategoryNote: string | undefined; // 備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{
		list: SmallCategoryResponse[];
	}>();

const listApi = createApiInterface().request(formMasterProductSmallCategoryListSchema).response<{
	list: SmallCategoryResponse[];
}>();

const createApi = createApiInterface().request(formMasterProductSmallCategoryEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterProductSmallCategoryEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const smallCategoryApi = createApiInterfaceMap({
	['/v1/master/product/small-category/find']: findApi,
	['/v1/master/product/small-category/list']: listApi,
	['/v1/master/product/small-category/create']: createApi,
	['/v1/master/product/small-category/update']: updateApi,
	['/v1/master/product/small-category/delete']: deleteApi,
});
