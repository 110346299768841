import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterWarehouseCategoryEntrySchema, formMasterWarehouseCategoryListSchema } from '../../../schema/form/master/warehouse';

export type WarehouseCategoryResponse = {
	warehouseCategoryId: number; // 倉庫区分ID
	warehouseCategoryCode: string; // 倉庫区分コード
	warehouseCategoryName: string; // 倉庫区分名
};

/** 倉庫区分リストデータ */
export type WarehouseCategoryListResponse = MasterResponse &
	WarehouseCategoryResponse & {
		warehouseCategoryNote: string | undefined;
	};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: WarehouseCategoryListResponse[] }>();

const listApi = createApiInterface().request(formMasterWarehouseCategoryListSchema.notRequired()).response<{ list: WarehouseCategoryListResponse[] }>();

const createApi = createApiInterface().request(formMasterWarehouseCategoryEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterWarehouseCategoryEntrySchema }))
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

/** 倉庫区分管理API */
export const warehouseCategoryApi = createApiInterfaceMap({
	['/v1/master/warehouse/warehouse-category/find']: findApi,
	['/v1/master/warehouse/warehouse-category/list']: listApi,
	['/v1/master/warehouse/warehouse-category/create']: createApi,
	['/v1/master/warehouse/warehouse-category/update']: updateApi,
	['/v1/master/warehouse/warehouse-category/delete']: deleteApi,
});
