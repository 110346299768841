// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterProductSkuGroupIdSchema, typeMasterProductSkuGroupCodeSchema, typeMasterProductSkuGroupNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonQuantitySchema } from "./../../common";

export const formStockBreakSetProductResultEntrySkuGroupIdSchema = typeMasterProductSkuGroupIdSchema;
export type FormStockBreakSetProductResultEntrySkuGroupIdSchemaType = InferType<typeof formStockBreakSetProductResultEntrySkuGroupIdSchema>;
export const formStockBreakSetProductResultEntrySkuGroupCodeSchema = typeMasterProductSkuGroupCodeSchema;
export type FormStockBreakSetProductResultEntrySkuGroupCodeSchemaType = InferType<typeof formStockBreakSetProductResultEntrySkuGroupCodeSchema>;
export const formStockBreakSetProductResultEntrySkuGroupNameSchema = typeMasterProductSkuGroupNameSchema;
export type FormStockBreakSetProductResultEntrySkuGroupNameSchemaType = InferType<typeof formStockBreakSetProductResultEntrySkuGroupNameSchema>;
export const formStockBreakSetProductResultEntryQuantitySchema = typeCommonQuantitySchema;
export type FormStockBreakSetProductResultEntryQuantitySchemaType = InferType<typeof formStockBreakSetProductResultEntryQuantitySchema>;
export const formStockBreakSetProductResultEntrySchema = yup.object({
	skuGroupId: formStockBreakSetProductResultEntrySkuGroupIdSchema,
	skuGroupCode: formStockBreakSetProductResultEntrySkuGroupCodeSchema,
	skuGroupName: formStockBreakSetProductResultEntrySkuGroupNameSchema,
	quantity: formStockBreakSetProductResultEntryQuantitySchema,
}).required();
export type FormStockBreakSetProductResultEntrySchemaType = InferType<typeof formStockBreakSetProductResultEntrySchema>;
