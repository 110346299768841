import { object } from 'yup';
import { MasterResponse } from '../../../../response';
import { createApiInterface, createApiInterfaceMap } from '../../../api-interface';

export type ReceiveIssueCategoryListResponse = MasterResponse & {
	receiveIssueCategoryId: number;
	receiveIssueCategoryCode: string;
	receiveIssueCategoryName: string;
	receiveIssueCategoryNote: string | undefined;
};

const listApi = createApiInterface().request(object().required()).response<{
	list: ReceiveIssueCategoryListResponse[];
}>();

export const receivingIssuingCategoryApi = createApiInterfaceMap({
	['/v1/master/receiving-issuing/category/list']: listApi,
});
