import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { formIssuingIssuingInspectionSchema } from '../../schema/form/issuing/issuingInspection';
import { BasicResponse } from '../../response';
import { number, object } from 'yup';
import { formIssuingIssuingInspectionResultSchema } from '../../schema/form/issuing/issuingInspectionResult';
import { formIssuingIssuingInspectionMobileEntrySchema } from '../../schema/form/issuing/issuingInspectionMobileEntry';

export type IssuingInspectionResponse = BasicResponse & {
	inspectionId: number;
	inspectionLog: IssuingInspectionLog[];

	receiveIssueDetailId: number; // 入出庫明細ID
	issuingScheduledQuantity: number; // 出庫予定数量
	issuingQuantity: number | undefined;
	skuId: number; // SKUID
	skuCode: string; // SKUコード
	productId: number; // 商品ID
	productCode: string; // 商品コード
	productName: string; // 商品名

	receiveIssueId: number; // 入出庫ID
	receiveIssueCode: string; // 入出庫コード
	issuingScheduledDate: string;
	issuingDate: string | undefined;
};

type IssuingInspectionLog = {
	inspectionLogId: number;
	lotId: number;
	inspectionQuantity: number;
};

const findApi = createApiInterface().request(formIssuingIssuingInspectionSchema).response<{
	list: IssuingInspectionResponse[];
}>();

const registApi = createApiInterface()
	.request(object({ form: formIssuingIssuingInspectionResultSchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const mobileRegistApi = createApiInterface()
	.request(object({ form: formIssuingIssuingInspectionMobileEntrySchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

export const issuingInspectionApi = createApiInterfaceMap({
	['/v1/issuing/inspection/find']: findApi,
	['/v1/issuing/inspection/regist']: registApi,
	['/v1/issuing/inspection/delete']: deleteApi,
	['/v1/issuing/inspection/mobile']: mobileRegistApi,
});
