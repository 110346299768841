// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const typeSearchItemsValueValueItemsSchema = yup.number().optional().label("content.common.search.item.field.label.target").integer();
export type TypeSearchItemsValueValueItemsSchemaType = InferType<typeof typeSearchItemsValueValueItemsSchema>;
export const typeSearchItemsValueValueTextSchema = yup.string().optional().label("content.common.search.item.field.label.target.name");
export type TypeSearchItemsValueValueTextSchemaType = InferType<typeof typeSearchItemsValueValueTextSchema>;
export const typeSearchItemsValueValueComparisonSchema = yup.number().optional().label("content.common.search.item.field.label.comparison").integer();
export type TypeSearchItemsValueValueComparisonSchemaType = InferType<typeof typeSearchItemsValueValueComparisonSchema>;
export const typeSearchItemsValueValueMatchSchema = yup.number().optional().label("content.common.search.item.field.label.match").integer();
export type TypeSearchItemsValueValueMatchSchemaType = InferType<typeof typeSearchItemsValueValueMatchSchema>;
export const typeSearchItemsValueValueSchema = yup.object({
	items: typeSearchItemsValueValueItemsSchema,
	text: typeSearchItemsValueValueTextSchema,
	comparison: typeSearchItemsValueValueComparisonSchema,
	match: typeSearchItemsValueValueMatchSchema,
}).optional();
export type TypeSearchItemsValueValueSchemaType = InferType<typeof typeSearchItemsValueValueSchema>;
export const typeSearchItemsValueSchema = yup.array(
	typeSearchItemsValueValueSchema
).optional();
export type TypeSearchItemsValueSchemaType = InferType<typeof typeSearchItemsValueSchema>;
export const typeSearchItemsSchema = yup.object({
	value: typeSearchItemsValueSchema,
}).optional();
export type TypeSearchItemsSchemaType = InferType<typeof typeSearchItemsSchema>;
