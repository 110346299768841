// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterProductSkuGroupIdSchema, typeMasterProductSkuGroupCodeSchema, typeMasterProductSkuGroupNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonQuantitySchema } from "./../../common";

export const formStockSetProductResultEntrySkuGroupIdSchema = typeMasterProductSkuGroupIdSchema;
export type FormStockSetProductResultEntrySkuGroupIdSchemaType = InferType<typeof formStockSetProductResultEntrySkuGroupIdSchema>;
export const formStockSetProductResultEntrySkuGroupCodeSchema = typeMasterProductSkuGroupCodeSchema;
export type FormStockSetProductResultEntrySkuGroupCodeSchemaType = InferType<typeof formStockSetProductResultEntrySkuGroupCodeSchema>;
export const formStockSetProductResultEntrySkuGroupNameSchema = typeMasterProductSkuGroupNameSchema;
export type FormStockSetProductResultEntrySkuGroupNameSchemaType = InferType<typeof formStockSetProductResultEntrySkuGroupNameSchema>;
export const formStockSetProductResultEntryQuantitySchema = typeCommonQuantitySchema;
export type FormStockSetProductResultEntryQuantitySchemaType = InferType<typeof formStockSetProductResultEntryQuantitySchema>;
export const formStockSetProductResultEntrySchema = yup.object({
	skuGroupId: formStockSetProductResultEntrySkuGroupIdSchema,
	skuGroupCode: formStockSetProductResultEntrySkuGroupCodeSchema,
	skuGroupName: formStockSetProductResultEntrySkuGroupNameSchema,
	quantity: formStockSetProductResultEntryQuantitySchema,
}).required();
export type FormStockSetProductResultEntrySchemaType = InferType<typeof formStockSetProductResultEntrySchema>;
