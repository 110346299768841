import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterClientEntrySchema, formMasterClientListSchema } from '../../../schema/form/master/client';

export type ClientListResponse = MasterResponse & {
	clientId: number;
	clientCode: string;
	clientName: string;
	shipperId: number;
	shipperCode: string | undefined;
	shipperName: string | undefined;
	clientCategoryId: number;
	clientCategoryCode: string | undefined;
	clientCategoryName: string | undefined;
	clientPostalCode: string | undefined;
	clientAddress: string;
	clientTel: string | undefined;
	clientFax: string | undefined;
	clientContactName: string | undefined;
	clientNote: string | undefined;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: ClientListResponse[] }>();
const listApi = createApiInterface().request(formMasterClientListSchema.notRequired()).response<{
	list: ClientListResponse[];
}>();

const createApi = createApiInterface().request(formMasterClientEntrySchema).response<{
	isSuccess: boolean;
	clientId?: number;
}>();
const updateApi = createApiInterface()
	.request(
		object({
			id: number().required().integer(),
			form: formMasterClientEntrySchema,
		})
	)
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const clientApi = createApiInterfaceMap({
	['/v1/master/client/find']: findApi,
	['/v1/master/client/list']: listApi,
	['/v1/master/client/entry']: createApi,
	['/v1/master/client/update']: updateApi,
	['/v1/master/client/delete']: deleteApi,
});
