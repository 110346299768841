/** 補充一覧 */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formStockRestockListInputSchema } from '../../../schema/form/stock/restockListInput';
import { ListResponse } from '../../../response';

export type RestockListResponse = ListResponse & {
	stockId: number; // 在庫ID
	skuId: number; // SKUID
	skuCode: string; // SKUコード
	productId: number; // 商品ID
	productCode: string; // 商品コード
	productName: string; // 商品名
	movingSourceLocationDetailId: number; // 移動元ロケーション明細ID
	movingSourceLocationDetailCode: string; // 移動元ロケーション明細コード
	movingSourceLocationDetailName: string; // 移動元ロケーション明細名
	destinationLocationDetailId: number | undefined; // 移動先ロケーション明細ID
	destinationLocationDetailCode: string | undefined; // 移動先ロケーション明細コード
	destinationLocationDetailName: string | undefined; // 移動先ロケーション明細名
	requiredQuantity: number;
};

const listApi = createApiInterface().request(formStockRestockListInputSchema).response<{ list: RestockListResponse[] }>();
const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{ isSuccess: boolean }>();

export const restockApi = createApiInterfaceMap({
	['/v1/stock/restock/list']: listApi,
	['/v1/stock/restock/delete']: deleteApi,
});
