// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const typeReceivingIdSchema = yup.number().required().integer();
export type TypeReceivingIdSchemaType = InferType<typeof typeReceivingIdSchema>;
export const typeReceivingCodeSchema = yup.string().required().label("schema.receiving.issuing.label.code");
export type TypeReceivingCodeSchemaType = InferType<typeof typeReceivingCodeSchema>;
export const typeReceivingNameSchema = yup.string().required().label("schema.receiving.issuing.label.name");
export type TypeReceivingNameSchemaType = InferType<typeof typeReceivingNameSchema>;
export const typeReceivingCategoryIdSchema = yup.number().required().label("schema.receiving.issuing.category.label.name").integer();
export type TypeReceivingCategoryIdSchemaType = InferType<typeof typeReceivingCategoryIdSchema>;
export const typeReceivingDetailIdSchema = yup.number().required().integer();
export type TypeReceivingDetailIdSchemaType = InferType<typeof typeReceivingDetailIdSchema>;
export const typeReceivingMovingSourceLocationDetailIdSchema = yup.number().required().label("schema.receiving.label.moving.source.location.detail.id").integer();
export type TypeReceivingMovingSourceLocationDetailIdSchemaType = InferType<typeof typeReceivingMovingSourceLocationDetailIdSchema>;
export const typeReceivingMovingSourceLocationDetailCodeSchema = yup.string().required().label("schema.receiving.label.moving.source.location.detail.code");
export type TypeReceivingMovingSourceLocationDetailCodeSchemaType = InferType<typeof typeReceivingMovingSourceLocationDetailCodeSchema>;
export const typeReceivingMovingSourceLocationDetailNameSchema = yup.string().required().label("schema.receiving.label.moving.source.location.detail.name");
export type TypeReceivingMovingSourceLocationDetailNameSchemaType = InferType<typeof typeReceivingMovingSourceLocationDetailNameSchema>;
export const typeReceivingDestinationLocationDetailIdSchema = yup.number().required().label("schema.receiving.label.destination.source.location.detail.id").integer();
export type TypeReceivingDestinationLocationDetailIdSchemaType = InferType<typeof typeReceivingDestinationLocationDetailIdSchema>;
export const typeReceivingDestinationLocationDetailCodeSchema = yup.string().required().label("schema.receiving.label.destination.source.location.detail.code");
export type TypeReceivingDestinationLocationDetailCodeSchemaType = InferType<typeof typeReceivingDestinationLocationDetailCodeSchema>;
export const typeReceivingDestinationLocationDetailNameSchema = yup.string().optional().label("schema.receiving.label.destination.source.location.detail.name");
export type TypeReceivingDestinationLocationDetailNameSchemaType = InferType<typeof typeReceivingDestinationLocationDetailNameSchema>;
export const typeReceivingGroupNameSchema = yup.string().required().label("schema.receiving.issuing.group.label.name");
export type TypeReceivingGroupNameSchemaType = InferType<typeof typeReceivingGroupNameSchema>;
export const typeReceivingScheduledDateSchema = yup.date().required().label("schema.receiving.label.scheduled.date");
export type TypeReceivingScheduledDateSchemaType = InferType<typeof typeReceivingScheduledDateSchema>;
export const typeReceivingDateSchema = yup.date().required().label("schema.receiving.label.date");
export type TypeReceivingDateSchemaType = InferType<typeof typeReceivingDateSchema>;
export const typeReceivingScheduledQuantitySchema = yup.number().required().label("schema.receiving.label.scheduled.quantity").integer();
export type TypeReceivingScheduledQuantitySchemaType = InferType<typeof typeReceivingScheduledQuantitySchema>;
export const typeReceivingQuantitySchema = yup.number().required().label("schema.receiving.label.quantity").integer();
export type TypeReceivingQuantitySchemaType = InferType<typeof typeReceivingQuantitySchema>;
