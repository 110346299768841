import { setLocale, object, string, array } from 'yup';
import { MessageParams } from 'yup/lib/types';

const yupTranslateKeySymbol = Symbol('yupTranslate');

export type YupTranslateKey = {
	key: string;
	value: MessageParams;
	type: typeof yupTranslateKeySymbol;
};
export function createYupTranslateKey(key: string): (value: MessageParams) => YupTranslateKey {
	return (value: MessageParams) => {
		return {
			key,
			value,
			type: yupTranslateKeySymbol,
		};
	};
}

export function isYupTranslateKey(value: unknown): value is YupTranslateKey {
	if (typeof value !== 'object') return false;
	if (value === null) return false;
	return (value as Record<string, unknown>)?.type === yupTranslateKeySymbol;
}

export function setYupLocale() {
	setLocale({
		mixed: {
			default: createYupTranslateKey('schema.mixed.default'),
			required: createYupTranslateKey('schema.mixed.required'),

			// oneOfは指定した値のみを許可するバリデーションです
			// どのようなバリデーションか明確ではないので翻訳も共通で書くことが出来ません
			// oneOfを使用する際に指定してください
			// サンプル .oneOf(["月","火",],createYupTranslateKey("schema.string.dayOfWeek"))

			// oneOf:createYupTranslateKey("schema.mixed.oneOf"),
			// notOneOf:createYupTranslateKey("schema.mixed.notOneOf"), oneOfと同じく
			notType: createYupTranslateKey('schema.mixed.notType'),
			defined: createYupTranslateKey('schema.mixed.defined'),
		},
		string: {
			length: createYupTranslateKey('schema.string.length'),
			min: createYupTranslateKey('schema.string.min'),
			max: createYupTranslateKey('schema.string.max'),
			// matches:createYupTranslateKey("schema.string.matches"), oneOfと同じく
			email: createYupTranslateKey('schema.string.email'),
			url: createYupTranslateKey('schema.string.url'),
			uuid: createYupTranslateKey('schema.string.uuid'),
			trim: createYupTranslateKey('schema.string.trim'),
			lowercase: createYupTranslateKey('schema.string.lowercase'),
			uppercase: createYupTranslateKey('schema.string.uppercase'),
		},
		number: {
			min: createYupTranslateKey('schema.number.min'),
			max: createYupTranslateKey('schema.number.max'),
			lessThan: createYupTranslateKey('schema.number.lessThan'),
			moreThan: createYupTranslateKey('schema.number.moreThan'),
			positive: createYupTranslateKey('schema.number.positive'),
			negative: createYupTranslateKey('schema.number.negative'),
			integer: createYupTranslateKey('schema.number.integer'),
		},
		date: {
			min: createYupTranslateKey('schema.date.min'),
			max: createYupTranslateKey('schema.date.max'),
		},
		object: {
			noUnknown: createYupTranslateKey('schema.object.noUnknown'),
		},
		array: {
			length: createYupTranslateKey('schema.array.length'),
			min: createYupTranslateKey('schema.array.min'),
			max: createYupTranslateKey('schema.array.max'),
		},
		boolean: {
			isValue: createYupTranslateKey('schema.boolean.isValue'),
		},
	});
}
