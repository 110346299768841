/** 入荷一覧 */
import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { ListResponse } from '../../response';
import { formArrivalArrivalListSchema } from '../../schema/form/arrival/arrivalList';
import { number, object } from 'yup';
import { formArrivalArrivalScheduleEntrySchema } from '../../schema/form/arrival/arrivalScheduleEntry';
import { formArrivalArrivalResultEntrySchema } from '../../schema/form/arrival/arrivalResultEntry';

/** 入荷一覧結果 */
export type ArrivalListResponse = ListResponse & {
	arrivalId: number;
	arrivalCode: string;
	// 仕入先
	clientId: number;
	clientCode: string;
	clientName: string;
	// 仕入先出荷元
	clientSenderId: number;
	clientSenderCode: string;
	clientSenderName: string;
	// 入荷状況
	arrivalStatusId: number;
	arrivalStatus: string;
	// 入荷予定日
	arrivalScheduledDate: string;
	arrivalDate: string | undefined;
	// 入荷前検品
	inspectionBeforeArrivalLocationDetailId: number | undefined;
	inspectionBeforeArrivalLocationDetailCode: string | undefined;
	inspectionBeforeArrivalLocationDetailName: string | undefined;
	// 入荷ロケーション
	arrivalLocationDetailId: number | undefined;
	arrivalLocationDetailCode: string | undefined;
	arrivalLocationDetailName: string | undefined;
	// 入荷備考
	arrivalNote: string | undefined;
};

export type ArrivalDetailListResponse = ArrivalListResponse & { arrivalDetail: ArrivalDetail[] };

type ArrivalDetail = {
	arrivalCategoryId: number | undefined;
	arrivalCatogoryCode: string | undefined;
	arrivalCategoryName: string | undefined;
	arrivalDetailId: number | undefined; // 入荷明細ID
	arrivalDetailNote: string | undefined; // 入荷明細備考
	arrivalScheduledQuantity: number | undefined; // 入荷予定数量
	arrivalQuantity: number | undefined; // 入荷数量
	skuId: number | undefined; // SKUID
	productId: number | undefined; // 商品ID
};

/** 入荷一覧 */
const listApi = createApiInterface().request(formArrivalArrivalListSchema).response<{
	list: ArrivalListResponse[];
}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

/** 入荷予定 */
const scheduleCreateApi = createApiInterface().request(formArrivalArrivalScheduleEntrySchema).response<{
	isSuccess: boolean;
	arrivalId?: number;
}>();

const scheduleUpdateApi = createApiInterface()
	.request(object({ id: number().integer().required(), form: formArrivalArrivalScheduleEntrySchema }))
	.response<{ isSuccess: boolean }>();

const scheduleFindApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: ArrivalDetailListResponse[];
	}>();

/** 入荷実績入力 */
const resultCreateApi = createApiInterface().request(formArrivalArrivalResultEntrySchema).response<{
	isSuccess: boolean;
}>();

const resultUpdateApi = createApiInterface()
	.request(object({ id: number().integer().required(), form: formArrivalArrivalResultEntrySchema }))
	.response<{ isSuccess: boolean }>();

const resultFindApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: ArrivalDetailListResponse[];
	}>();

export const arrivalApi = createApiInterfaceMap({
	['/v1/arrival/list']: listApi,
	['/v1/arrival/delete']: deleteApi,
	['/v1/arrival/entry-schedule/create']: scheduleCreateApi,
	['/v1/arrival/entry-schedule/update']: scheduleUpdateApi,
	['/v1/arrival/entry-schedule/find']: scheduleFindApi,
	['/v1/arrival/entry-result/create']: resultCreateApi,
	['/v1/arrival/entry-result/update']: resultUpdateApi,
	['/v1/arrival/entry-result/find']: resultFindApi,
});
