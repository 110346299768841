/** 入荷前検品入力 */
import { number, object } from 'yup';
import { BasicResponse } from '../../response';
import { formArrivalArrivalInspectionSchema } from '../../schema/form/arrival/arrivalInspection';
import { formArrivalArrivalInspectionMobileEntrySchema } from '../../schema/form/arrival/arrivalInspectionMobileEntry';
import { formArrivalArrivalInspectionResultSchema } from '../../schema/form/arrival/arrivalInspectionResult';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';

// todo: 編集データの取得検討

export type ArrivalInspectionResponse = BasicResponse & {
	inspectionId: number;
	inspectionLog: ArrivalInspectionLog[];

	// 入荷明細情報
	arrivalDetailId: number;
	arrivalScheduledQuantity: number | undefined; // 入荷予定数量
	arrivalQuantity: number | undefined; // 入荷数量
	skuId: number | undefined; // SKUID
	skuCode: string | undefined; // SKUコード
	productId: number | undefined; // 商品ID
	productCode: string | undefined; // 商品コード
	productName: string | undefined; // 商品名

	// 入荷情報
	arrivalId: number;
	arrivalCode: string;
	arrivalScheduledDate: string;
	arrivalDate: string | undefined;
};

type ArrivalInspectionLog = {
	inspectionLogId: number;
	lotId: number;
	inspectionQuantity: number;

	// ToDo: productStatus?
	productStateId: number;
	productStateCode: string;
	productStateName: string;
};

const findApi = createApiInterface().request(formArrivalArrivalInspectionSchema).response<{
	list: ArrivalInspectionResponse[];
}>();

const registApi = createApiInterface()
	.request(object({ form: formArrivalArrivalInspectionResultSchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const mobileRegistApi = createApiInterface()
	.request(object({ form: formArrivalArrivalInspectionMobileEntrySchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

export const arrivalInspectionApi = createApiInterfaceMap({
	['/v1/arrival/inspection/find']: findApi,
	['/v1/arrival/inspection/regist']: registApi,
	['/v1/arrival/inspection/delete']: deleteApi,
	['/v1/arrival/inspection/mobile']: mobileRegistApi,
});
