import { number, object } from 'yup';
import { MasterResponse } from '../../../response';
import { formMasterAssetEntrySchema, formMasterAssetListSchema } from '../../../schema/form/master/asset';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';

export type AssetListResponse = MasterResponse & {
	assetId: number; // 資産ID
	assetCode: string; // 資産コード
	assetName: string; // 資産名
	warehouseId: number | undefined; // 倉庫ID
	warehouseCode: string | undefined; // 倉庫コード
	warehouseName: string | undefined; // 倉庫名
	assetCategoryId1: number; // 資産区分Id1
	assetCategoryCode1: string;
	assetCategoryName1: string;
	assetCategoryId2: number | undefined; // 資産区分Id2
	assetCategoryCode2: string | undefined;
	assetCategoryName2: string | undefined;
	assetCategoryId3: number | undefined; // 資産区分Id3
	assetCategoryCode3: string | undefined; // 資産区分コード3
	assetCategoryName3: string | undefined; // 資産区分名3
	assetQuantity: number | undefined; // 資産数量
	assetStatus: number | undefined; // 資産状態
	assetNote: string | undefined; // 資産備考
	assetReturnScheduledDate: string | undefined; // 返却予定日
	assetReturnDate: string | undefined; // 返却日
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: AssetListResponse[] }>();

const listApi = createApiInterface().request(formMasterAssetListSchema.notRequired()).response<{
	list: AssetListResponse[];
}>();

const createApi = createApiInterface().request(formMasterAssetEntrySchema).response<{
	isSuccess: boolean;
	assetId?: number;
}>();

const updateApi = createApiInterface()
	.request(
		object({
			id: number().required().integer(),
			form: formMasterAssetEntrySchema,
		})
	)
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const assetApi = createApiInterfaceMap({
	['/v1/master/asset/find']: findApi,
	['/v1/master/asset/list']: listApi,
	['/v1/master/asset/create']: createApi,
	['/v1/master/asset/update']: updateApi,
	['/v1/master/asset/delete']: deleteApi,
});
