// fv-generatorで生成されました
import * as yup from "yup";
import { typeReceivingIdSchema, typeReceivingCodeSchema, typeReceivingNameSchema, typeReceivingCategoryIdSchema, typeReceivingDetailIdSchema, typeReceivingMovingSourceLocationDetailIdSchema, typeReceivingMovingSourceLocationDetailCodeSchema, typeReceivingMovingSourceLocationDetailNameSchema, typeReceivingDestinationLocationDetailIdSchema, typeReceivingDestinationLocationDetailCodeSchema, typeReceivingDestinationLocationDetailNameSchema } from "./receiving";
import { InferType } from "yup";

export const typeIssuingIdSchema = typeReceivingIdSchema;
export type TypeIssuingIdSchemaType = InferType<typeof typeIssuingIdSchema>;
export const typeIssuingCodeSchema = typeReceivingCodeSchema;
export type TypeIssuingCodeSchemaType = InferType<typeof typeIssuingCodeSchema>;
export const typeIssuingNameSchema = typeReceivingNameSchema;
export type TypeIssuingNameSchemaType = InferType<typeof typeIssuingNameSchema>;
export const typeIssuingCategoryIdSchema = typeReceivingCategoryIdSchema;
export type TypeIssuingCategoryIdSchemaType = InferType<typeof typeIssuingCategoryIdSchema>;
export const typeIssuingDetailIdSchema = typeReceivingDetailIdSchema;
export type TypeIssuingDetailIdSchemaType = InferType<typeof typeIssuingDetailIdSchema>;
export const typeIssuingScheduledDateSchema = yup.date().required().label("schema.issuing.label.scheduled.date");
export type TypeIssuingScheduledDateSchemaType = InferType<typeof typeIssuingScheduledDateSchema>;
export const typeIssuingDateSchema = yup.date().required().label("schema.issuing.label.date");
export type TypeIssuingDateSchemaType = InferType<typeof typeIssuingDateSchema>;
export const typeIssuingScheduledQuantitySchema = yup.number().required().label("schema.issuing.label.scheduled.quantity").integer();
export type TypeIssuingScheduledQuantitySchemaType = InferType<typeof typeIssuingScheduledQuantitySchema>;
export const typeIssuingQuantitySchema = yup.number().required().label("schema.issuing.label.quantity").integer();
export type TypeIssuingQuantitySchemaType = InferType<typeof typeIssuingQuantitySchema>;
export const typeIssuingMovingSourceLocationDetailIdSchema = typeReceivingMovingSourceLocationDetailIdSchema;
export type TypeIssuingMovingSourceLocationDetailIdSchemaType = InferType<typeof typeIssuingMovingSourceLocationDetailIdSchema>;
export const typeIssuingMovingSourceLocationDetailCodeSchema = typeReceivingMovingSourceLocationDetailCodeSchema;
export type TypeIssuingMovingSourceLocationDetailCodeSchemaType = InferType<typeof typeIssuingMovingSourceLocationDetailCodeSchema>;
export const typeIssuingMovingSourceLocationDetailNameSchema = typeReceivingMovingSourceLocationDetailNameSchema;
export type TypeIssuingMovingSourceLocationDetailNameSchemaType = InferType<typeof typeIssuingMovingSourceLocationDetailNameSchema>;
export const typeIssuingDestinationLocationDetailIdSchema = typeReceivingDestinationLocationDetailIdSchema;
export type TypeIssuingDestinationLocationDetailIdSchemaType = InferType<typeof typeIssuingDestinationLocationDetailIdSchema>;
export const typeIssuingDestinationLocationDetailCodeSchema = typeReceivingDestinationLocationDetailCodeSchema;
export type TypeIssuingDestinationLocationDetailCodeSchemaType = InferType<typeof typeIssuingDestinationLocationDetailCodeSchema>;
export const typeIssuingDestinationLocationDetailNameSchema = typeReceivingDestinationLocationDetailNameSchema;
export type TypeIssuingDestinationLocationDetailNameSchemaType = InferType<typeof typeIssuingDestinationLocationDetailNameSchema>;
