import { object, number } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterProductPartNumberEntrySchema, formMasterProductPartNumberListSchema } from '../../../schema/form/master/product';

export type PartNumbersListResponse = MasterResponse & {
	partNumberId: number; // 品番ID
	partNumberCode: string; // 品番コード
	partNumberName: string; // 品名
	shipperId: number; // 荷主ID
	shipperCode: string; // 荷主コード
	shipperName: string; // 荷主名
	partNumberCategoryId1: number; // 品番区分ID1
	partNumberCategoryCode1: string; // 品番区分コード1
	partNumberCategoryName1: string; // 品番区分名1
	partNumberCategoryId2: number | undefined; // 品番区分ID2
	partNumberCategoryCode2: string | undefined; // 品番区分コード2
	partNumberCategoryName2: string | undefined; // 品番区分名2
	partNumberCategoryId3: number | undefined; // 品番区分ID3
	partNumberCategoryCode3: string | undefined; // 品番区分コード3
	partNumberCategoryName3: string | undefined; // 品番区分名3
	storingConditionId: number; // 保管条件Id
	storingConditionCode: string; // 保管条件コード
	storingConditionName: string; // 保管条件名
	supplierPartNumber: string | undefined; // 仕入先品番
	quantityUnit: string; // 数量単位
	quantityPerCase: number; // ケース入数
	locationAllocationMinimumQuantity: number; // ロケーション割当最小数量
	partNumberNote: string | undefined; // 品番備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: PartNumbersListResponse[] }>();

const listApi = createApiInterface().request(formMasterProductPartNumberListSchema).response<{
	list: PartNumbersListResponse[];
}>();

const createApi = createApiInterface().request(formMasterProductPartNumberEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterProductPartNumberEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const partNumbersApi = createApiInterfaceMap({
	['/v1/master/product/part-numbers/find']: findApi,
	['/v1/master/product/part-numbers/list']: listApi,
	['/v1/master/product/part-numbers/create']: createApi,
	['/v1/master/product/part-numbers/update']: updateApi,
	['/v1/master/product/part-numbers/delete']: deleteApi,
});
