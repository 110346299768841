/** 倉庫マスタ */

import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formMasterWarehouseEntrySchema, formMasterWarehouseListSchema } from '../../../schema/form/master/warehouse';
import { MasterResponse } from '../../../response';
import { WarehouseCategoryResponse } from './warehouseCategory';

/** 倉庫リストデータ */
export type WarehouseListResponse = MasterResponse & {
	warehouseId: number; // 倉庫ID
	warehouseCode: string; // 倉庫コード
	warehouseName: string; // 倉庫名
	warehouseCategories: WarehouseCategoryResponse[]; // 倉庫区分リスト
	shipperId: number; // 荷主ID
	shipperCode: string; // 荷主コード
	shipperName: string; // 荷主名
	warehousePostalCode: string | undefined; // 倉庫郵便番号
	warehouseAddress: string | undefined; // 倉庫住所
	warehouseTel: string | undefined; // 倉庫電話番号
	warehouseFax: string | undefined; // 倉庫FAX番号
	warehouseNote: string | undefined; // 備考
};

// TODO: 倉庫ロケーション設定マスタとして独立？
/** 倉庫データ */
// export type WarehouseResponse = WarehouseListResponse & {
// 	preReceivingInspectionLocationDetailId: number | undefined; // 入荷前検品ロケーション明細ID
// 	preReceivingInspectionLocationDetailCode: string | undefined; // 入荷前検品ロケーション明細コード
// 	preReceivingInspectionLocationDetailName: string | undefined; // 入荷前検品ロケーション明細名
// 	receivingInspectionLocationDetailId: number | undefined; // 入荷ロケーション明細ID
// 	receivingInspectionLocationDetailCode: string | undefined; // 入荷ロケーション明細コード
// 	receivingInspectionLocationDetailName: string | undefined; // 入荷ロケーション明細名
// 	preLoadingInspectionLocationDetailId: number | undefined; // 入庫前検品ロケーション明細ID
// 	preLoadingInspectionLocationDetailCode: string | undefined; // 入庫前検品ロケーション明細コード
// 	preLoadingInspectionLocationDetailName: string | undefined; // 入庫前検品ロケーション明細名
// 	loadingInspectionLocationDetailId: number | undefined; // 入庫ロケーション明細ID
// 	loadingInspectionLocationDetailCode: string | undefined; // 入庫ロケーション明細コード
// 	loadingInspectionLocationDetailName: string | undefined; // 入庫ロケーション明細名
// 	preIssuingInspectionLocationDetailId: number | undefined; // 出庫前検品ロケーション明細ID
// 	preIssuingInspectionLocationDetailCode: string | undefined; // 出庫前検品ロケーション明細コード
// 	preIssuingInspectionLocationDetailName: string | undefined; // 出庫前検品ロケーション明細名
// 	issuingInspectionLocationDetailId: number | undefined; // 出庫ロケーション明細ID
// 	issuingInspectionLocationDetailCode: string | undefined; // 出庫ロケーション明細コード
// 	issuingInspectionLocationDetailName: string | undefined; // 出庫ロケーション明細名
// 	preShippingInspectionLocationDetailId: number | undefined; // 出荷前検品ロケーション明細ID
// 	preShippingInspectionLocationDetailCode: string | undefined; // 出荷前検品ロケーション明細コード
// 	preShippingInspectionLocationDetailName: string | undefined; // 出荷前検品ロケーション明細名
// 	inspectionBeforeLocationDetailId: number | undefined; // 梱包前検品ロケーション明細ID
// 	inspectionBeforeLocationDetailCode: string | undefined; // 梱包前検品ロケーション明細コード
// 	inspectionBeforeLocationDetailName: string | undefined; // 梱包前検品ロケーション明細名
// 	afterPackagingLocationDetailId: number | undefined; // 梱包後ロケーション明細ID
// 	afterPackagingLocationDetailCode: string | undefined; // 梱包後ロケーション明細コード
// 	afterPackagingLocationDetailName: string | undefined; // 梱包後ロケーション明細名
// 	inspectionAfterLocationDetailId: number | undefined; // 梱包後検品ロケーション明細ID
// 	inspectionAfterLocationDetailCode: string | undefined; // 梱包後検品ロケーション明細コード
// 	inspectionAfterLocationDetailName: string | undefined; // 梱包後検品ロケーション明細名
// 	shippingLocationDetailId: number | undefined; // 出荷ロケーション明細ID
// 	shippingLocationDetailCode: string | undefined; // 出荷ロケーション明細コード
// 	shippingLocationDetailName: string | undefined; // 出荷ロケーション明細名
// 	totalPickingLocationDetailId: number | undefined; // トータルピッキング後ロケーション明細ID
// 	totalPickingLocationDetailCode: string | undefined; // トータルピッキング後ロケーション明細コード
// 	totalPickingLocationDetailName: string | undefined; // トータルピッキング後ロケーション明細名
// };

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: WarehouseListResponse[] }>();

const listApi = createApiInterface().request(formMasterWarehouseListSchema).response<{
	list: WarehouseListResponse[];
}>();

const createApi = createApiInterface().request(formMasterWarehouseEntrySchema).response<{
	isSuccess: boolean;
	warehouseId?: number;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterWarehouseEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

/** 倉庫管理API */
export const warehouseApi = createApiInterfaceMap({
	['/v1/master/warehouse/find']: findApi,
	['/v1/master/warehouse/list']: listApi,
	['/v1/master/warehouse/create']: createApi,
	['/v1/master/warehouse/update']: updateApi,
	['/v1/master/warehouse/delete']: deleteApi,
});
