// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingScheduledDateSchema, typeShippingCodeSchema, typeShippingDetailCodeSchema } from "./../../shipping";
import { InferType } from "yup";
import { typeMasterCustomerIdSchema, typeMasterCustomerCodeSchema, typeMasterCustomerNameSchema, typeMasterProductSkuIdSchema, typeMasterProductIdSchema, typeMasterProductCodeSchema, typeMasterProductNameSchema } from "./../../master";
import { typeSearchItemsSchema } from "./../../search";

export const formShippingShippingAllocationListShippingScheduledDateToSchema = typeShippingScheduledDateSchema.optional();
export type FormShippingShippingAllocationListShippingScheduledDateToSchemaType = InferType<typeof formShippingShippingAllocationListShippingScheduledDateToSchema>;
export const formShippingShippingAllocationListShippingScheduledDateFromSchema = typeShippingScheduledDateSchema.optional();
export type FormShippingShippingAllocationListShippingScheduledDateFromSchemaType = InferType<typeof formShippingShippingAllocationListShippingScheduledDateFromSchema>;
export const formShippingShippingAllocationListShippingCodeSchema = typeShippingCodeSchema.optional();
export type FormShippingShippingAllocationListShippingCodeSchemaType = InferType<typeof formShippingShippingAllocationListShippingCodeSchema>;
export const formShippingShippingAllocationListShippingDetailCodeSchema = typeShippingDetailCodeSchema.optional();
export type FormShippingShippingAllocationListShippingDetailCodeSchemaType = InferType<typeof formShippingShippingAllocationListShippingDetailCodeSchema>;
export const formShippingShippingAllocationListCustomerIdSchema = typeMasterCustomerIdSchema.optional();
export type FormShippingShippingAllocationListCustomerIdSchemaType = InferType<typeof formShippingShippingAllocationListCustomerIdSchema>;
export const formShippingShippingAllocationListCustomerCodeSchema = typeMasterCustomerCodeSchema.optional();
export type FormShippingShippingAllocationListCustomerCodeSchemaType = InferType<typeof formShippingShippingAllocationListCustomerCodeSchema>;
export const formShippingShippingAllocationListCustomerNameSchema = typeMasterCustomerNameSchema.optional();
export type FormShippingShippingAllocationListCustomerNameSchemaType = InferType<typeof formShippingShippingAllocationListCustomerNameSchema>;
export const formShippingShippingAllocationListSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormShippingShippingAllocationListSkuIdSchemaType = InferType<typeof formShippingShippingAllocationListSkuIdSchema>;
export const formShippingShippingAllocationListProductIdSchema = typeMasterProductIdSchema.optional();
export type FormShippingShippingAllocationListProductIdSchemaType = InferType<typeof formShippingShippingAllocationListProductIdSchema>;
export const formShippingShippingAllocationListProductCodeSchema = typeMasterProductCodeSchema.optional();
export type FormShippingShippingAllocationListProductCodeSchemaType = InferType<typeof formShippingShippingAllocationListProductCodeSchema>;
export const formShippingShippingAllocationListProductNameSchema = typeMasterProductNameSchema.optional();
export type FormShippingShippingAllocationListProductNameSchemaType = InferType<typeof formShippingShippingAllocationListProductNameSchema>;
export const formShippingShippingAllocationListSearchItemsSchema = typeSearchItemsSchema;
export type FormShippingShippingAllocationListSearchItemsSchemaType = InferType<typeof formShippingShippingAllocationListSearchItemsSchema>;
export const formShippingShippingAllocationListSchema = yup.object({
	shippingScheduledDateTo: formShippingShippingAllocationListShippingScheduledDateToSchema,
	shippingScheduledDateFrom: formShippingShippingAllocationListShippingScheduledDateFromSchema,
	shippingCode: formShippingShippingAllocationListShippingCodeSchema,
	shippingDetailCode: formShippingShippingAllocationListShippingDetailCodeSchema,
	customerId: formShippingShippingAllocationListCustomerIdSchema,
	customerCode: formShippingShippingAllocationListCustomerCodeSchema,
	customerName: formShippingShippingAllocationListCustomerNameSchema,
	skuId: formShippingShippingAllocationListSkuIdSchema,
	productId: formShippingShippingAllocationListProductIdSchema,
	productCode: formShippingShippingAllocationListProductCodeSchema,
	productName: formShippingShippingAllocationListProductNameSchema,
	searchItems: formShippingShippingAllocationListSearchItemsSchema,
}).required();
export type FormShippingShippingAllocationListSchemaType = InferType<typeof formShippingShippingAllocationListSchema>;
