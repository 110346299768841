/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { createIntl, IntlProvider, IntlShape } from 'react-intl';
import { requestLangMap } from 'api/lang';
import { defaultLanguage, Language, saveLangauge } from 'util/language';
import { useQuery } from 'react-query';

//
type intlLoaderContextType = {
	changeLanguage: (lang: Language) => void;
	isLoading: boolean;
};
export const intlLoaderContext = createContext<intlLoaderContextType>({
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	changeLanguage: () => {},
	isLoading: false,
});

//
type IntlLoaderProps = {
	children: ReactNode;
};

let currentIntl = createIntl({
	defaultLocale: defaultLanguage,
	locale: defaultLanguage,
});

/**
 * 出来る限り、useIntlを使用すべきです。
 * useIntlを使用する事で、言語変更時再レンダリングされます
 * @returns
 */
export function getIntl(): IntlShape {
	return currentIntl;
}

export function IntlLoader({ children }: IntlLoaderProps) {
	const [currentLanguage, setCurrentLanguage] = useState<Language>(defaultLanguage);
	const { data } = useQuery(['lang', currentLanguage], () => requestLangMap(currentLanguage), {
		suspense: true,
		refetchOnMount: false,
		cacheTime: Infinity,
		staleTime: 1000 * 60 * 60 * 24, // 1日
		retry: true,
	});

	// ページの翻訳ファイルをキャッシュします
	// 言語設定が変更されるとMapごと作り直されます
	const [isLoading, startTransition] = useTransition();

	const changeLanguage = useCallback(
		(newLang: Language) => {
			if (currentLanguage === newLang) return;
			saveLangauge(newLang);
			startTransition(() => {
				setCurrentLanguage(newLang);
			});
		},
		[currentLanguage, setCurrentLanguage]
	);

	const contextValue = useMemo(
		() => ({
			changeLanguage,
			isLoading,
		}),
		[changeLanguage, isLoading]
	);

	useEffect(() => {
		currentIntl = createIntl({
			defaultLocale: defaultLanguage,
			locale: currentLanguage,
			messages: data,
		});
	}, [currentLanguage, data]);

	return (
		// TODO: react-intl対応待ち
		// @ts-ignore
		<IntlProvider
			defaultLocale={defaultLanguage}
			locale={currentLanguage}
			messages={data}
		>
			<intlLoaderContext.Provider value={contextValue}>{children}</intlLoaderContext.Provider>
		</IntlProvider>
	);
}
