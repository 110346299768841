// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionNumberSchema } from "./../../common";
import { InferType } from "yup";

export const formShippingShippingInspectionShippingIdSchema = typeCommonInspectionNumberSchema;
export type FormShippingShippingInspectionShippingIdSchemaType = InferType<typeof formShippingShippingInspectionShippingIdSchema>;
export const formShippingShippingInspectionSchema = yup.object({
	shippingId: formShippingShippingInspectionShippingIdSchema,
}).required();
export type FormShippingShippingInspectionSchemaType = InferType<typeof formShippingShippingInspectionSchema>;
