/** 荷主契約マスタ */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterShipperContractEntrySchema, formMasterShipperContractListSchema } from '../../../schema/form/master/shipper';

export type ShipperContractListResponse = MasterResponse & {
	shipperId: number; // 荷主ID
	shipperCode: string; // 荷主コード
	shipperName: string; // 荷主名
	shipperContractId: number; // 荷主契約ID
	shipperContractDate: string; // 荷主契約日
	shipperOperationStartDate: string | undefined; // 荷主運用開始日
	shipperWithdrawalDate: string | undefined; // 荷主解約日
	shipperContractNote: string | undefined; // 備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: ShipperContractListResponse[] }>();

const listApi = createApiInterface().request(formMasterShipperContractListSchema).response<{
	list: ShipperContractListResponse[];
}>();

const createApi = createApiInterface().request(formMasterShipperContractEntrySchema).response<{ isSuccess: boolean }>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterShipperContractEntrySchema }))
	.response<Record<never, never>>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const shipperContractApi = createApiInterfaceMap({
	['/v1/master/shipper/contract/find']: findApi,
	['/v1/master/shipper/contract/list']: listApi,
	['/v1/master/shipper/contract/create']: createApi,
	['/v1/master/shipper/contract/update']: updateApi,
	['/v1/master/shipper/contract/delete']: deleteApi,
});
