// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const typeShippingIdSchema = yup.number().required().integer();
export type TypeShippingIdSchemaType = InferType<typeof typeShippingIdSchema>;
export const typeShippingCodeSchema = yup.string().required().label("schema.shipping.label.code");
export type TypeShippingCodeSchemaType = InferType<typeof typeShippingCodeSchema>;
export const typeShippingDetailIdSchema = yup.number().required().label("schema.shipping.label.detail.code").integer();
export type TypeShippingDetailIdSchemaType = InferType<typeof typeShippingDetailIdSchema>;
export const typeShippingDetailCodeSchema = yup.string().required().label("schema.shipping.label.detail.code");
export type TypeShippingDetailCodeSchemaType = InferType<typeof typeShippingDetailCodeSchema>;
export const typeShippingDateSchema = yup.date().required().label("schema.shipping.label.date");
export type TypeShippingDateSchemaType = InferType<typeof typeShippingDateSchema>;
export const typeShippingScheduledDateSchema = yup.date().required().label("schema.shipping.label.scheduled.date");
export type TypeShippingScheduledDateSchemaType = InferType<typeof typeShippingScheduledDateSchema>;
export const typeShippingQuantitySchema = yup.number().required().label("schema.shipping.label.quantity").integer();
export type TypeShippingQuantitySchemaType = InferType<typeof typeShippingQuantitySchema>;
export const typeShippingScheduledQuantitySchema = yup.number().required().label("schema.shipping.label.scheduled.quantity").integer();
export type TypeShippingScheduledQuantitySchemaType = InferType<typeof typeShippingScheduledQuantitySchema>;
export const typeShippingStatusSchema = yup.string().required().label("schema.shipping.label.status");
export type TypeShippingStatusSchemaType = InferType<typeof typeShippingStatusSchema>;
export const typeShippingCategoryIdSchema = yup.number().required().label("schema.shipping.category.label.id").integer();
export type TypeShippingCategoryIdSchemaType = InferType<typeof typeShippingCategoryIdSchema>;
export const typeShippingOrderCodeSchema = yup.string().required().label("schema.shipping.label.order.code");
export type TypeShippingOrderCodeSchemaType = InferType<typeof typeShippingOrderCodeSchema>;
export const typeShippingDesignateLotSchema = yup.number().required().label("schema.shipping.allocation.label.designate.lot").integer();
export type TypeShippingDesignateLotSchemaType = InferType<typeof typeShippingDesignateLotSchema>;
export const typeShippingPreventLotRotationSchema = yup.number().required().label("schema.shipping.allocation.label.prevent.lot.rotation").integer();
export type TypeShippingPreventLotRotationSchemaType = InferType<typeof typeShippingPreventLotRotationSchema>;
export const typeShippingAllocationPrioritySchema = yup.number().required().label("schema.shipping.allocation.label.allocation.priority").integer();
export type TypeShippingAllocationPrioritySchemaType = InferType<typeof typeShippingAllocationPrioritySchema>;
export const typeShippingPickingDateSchema = yup.date().required().label("schema.shipping.picking.label.date");
export type TypeShippingPickingDateSchemaType = InferType<typeof typeShippingPickingDateSchema>;
export const typeShippingPickingIdSchema = yup.number().required().label("schema.shipping.picking.label.id").integer();
export type TypeShippingPickingIdSchemaType = InferType<typeof typeShippingPickingIdSchema>;
export const typeShippingPickingCodeSchema = yup.string().required().label("schema.shipping.picking.label.code");
export type TypeShippingPickingCodeSchemaType = InferType<typeof typeShippingPickingCodeSchema>;
export const typeShippingPickingNameSchema = yup.string().required().label("schema.shipping.picking.label.name");
export type TypeShippingPickingNameSchemaType = InferType<typeof typeShippingPickingNameSchema>;
export const typeShippingPickingQuantityValueSchema = yup.number().optional().integer();
export type TypeShippingPickingQuantityValueSchemaType = InferType<typeof typeShippingPickingQuantityValueSchema>;
export const typeShippingPickingQuantitySchema = yup.array(
	typeShippingPickingQuantityValueSchema
).optional().label("schema.shipping.picking.quantity");
export type TypeShippingPickingQuantitySchemaType = InferType<typeof typeShippingPickingQuantitySchema>;
