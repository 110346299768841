import { number, object } from 'yup';
import { MasterResponse } from '../../../../response';
import { formMasterArrivalCategoryEntrySchema, formMasterArrivalCategoryListSchema } from '../../../../schema/form/master/arrivalCategory';
import { createApiInterface, createApiInterfaceMap } from '../../../api-interface';

export type ArrivalCategoryListResponse = MasterResponse & {
	arrivalCategoryId: number;
	arrivalCategoryCode: string;
	arrivalCategoryName: string;
	arrivalCategoryNote: string | undefined;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: ArrivalCategoryListResponse[] }>();

const listApi = createApiInterface().request(formMasterArrivalCategoryListSchema.notRequired()).response<{
	list: ArrivalCategoryListResponse[];
}>();

const createApi = createApiInterface().request(formMasterArrivalCategoryEntrySchema).response<{
	isSuccess: boolean;
	arrivalCategoryId?: number;
}>();

const updateApi = createApiInterface()
	.request(
		object({
			id: number().required().integer(),
			form: formMasterArrivalCategoryEntrySchema,
		})
	)
	.response<{
		isSuccess: boolean;
	}>();
const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const arrivalCategoryApi = createApiInterfaceMap({
	['/v1/master/arrival/category/find']: findApi,
	['/v1/master/arrival/category/list']: listApi,
	['/v1/master/arrival/category/create']: createApi,
	['/v1/master/arrival/category/update']: updateApi,
	['/v1/master/arrival/category/delete']: deleteApi,
});
