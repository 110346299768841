// fv-generatorで生成されました
import * as yup from "yup";
import { typeReceivingGroupNameSchema, typeReceivingIdSchema } from "./../../receiving";
import { InferType } from "yup";
import { typeCommonNoteSchema } from "./../../common";

export const formReceivingReceivingAndIssuingGroupEntryReceiveIssueGroupNameSchema = typeReceivingGroupNameSchema;
export type FormReceivingReceivingAndIssuingGroupEntryReceiveIssueGroupNameSchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupEntryReceiveIssueGroupNameSchema>;
export const formReceivingReceivingAndIssuingGroupEntryReceiveIssueGroupNoteSchema = typeCommonNoteSchema;
export type FormReceivingReceivingAndIssuingGroupEntryReceiveIssueGroupNoteSchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupEntryReceiveIssueGroupNoteSchema>;
export const formReceivingReceivingAndIssuingGroupEntryReceivingDetailValueReceiveIssueIdSchema = typeReceivingIdSchema.optional();
export type FormReceivingReceivingAndIssuingGroupEntryReceivingDetailValueReceiveIssueIdSchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupEntryReceivingDetailValueReceiveIssueIdSchema>;
export const formReceivingReceivingAndIssuingGroupEntryReceivingDetailValueSchema = yup.object({
	receiveIssueId: formReceivingReceivingAndIssuingGroupEntryReceivingDetailValueReceiveIssueIdSchema,
}).optional();
export type FormReceivingReceivingAndIssuingGroupEntryReceivingDetailValueSchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupEntryReceivingDetailValueSchema>;
export const formReceivingReceivingAndIssuingGroupEntryReceivingDetailSchema = yup.array(
	formReceivingReceivingAndIssuingGroupEntryReceivingDetailValueSchema
).required();
export type FormReceivingReceivingAndIssuingGroupEntryReceivingDetailSchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupEntryReceivingDetailSchema>;
export const formReceivingReceivingAndIssuingGroupEntryIssuingDetailValueReceiveIssueIdSchema = typeReceivingIdSchema.optional();
export type FormReceivingReceivingAndIssuingGroupEntryIssuingDetailValueReceiveIssueIdSchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupEntryIssuingDetailValueReceiveIssueIdSchema>;
export const formReceivingReceivingAndIssuingGroupEntryIssuingDetailValueSchema = yup.object({
	receiveIssueId: formReceivingReceivingAndIssuingGroupEntryIssuingDetailValueReceiveIssueIdSchema,
}).optional();
export type FormReceivingReceivingAndIssuingGroupEntryIssuingDetailValueSchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupEntryIssuingDetailValueSchema>;
export const formReceivingReceivingAndIssuingGroupEntryIssuingDetailSchema = yup.array(
	formReceivingReceivingAndIssuingGroupEntryIssuingDetailValueSchema
).required();
export type FormReceivingReceivingAndIssuingGroupEntryIssuingDetailSchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupEntryIssuingDetailSchema>;
export const formReceivingReceivingAndIssuingGroupEntrySchema = yup.object({
	receiveIssueGroupName: formReceivingReceivingAndIssuingGroupEntryReceiveIssueGroupNameSchema,
	receiveIssueGroupNote: formReceivingReceivingAndIssuingGroupEntryReceiveIssueGroupNoteSchema,
	receivingDetail: formReceivingReceivingAndIssuingGroupEntryReceivingDetailSchema,
	issuingDetail: formReceivingReceivingAndIssuingGroupEntryIssuingDetailSchema,
}).required();
export type FormReceivingReceivingAndIssuingGroupEntrySchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupEntrySchema>;
