/** 顧客区分マスタ */

import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterCustomerCategoryEntrySchema, formMasterCustomerCategoryListSchema } from '../../../schema/form/master/customer';

export type CustomercategoryListResponse = MasterResponse & {
	id: number;
	customerCategoryId: number;
	customerCategoryCode: string;
	customerCategoryName: string;
	customerCategoryNote: string | undefined;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{
		list: CustomercategoryListResponse[];
	}>();

const listApi = createApiInterface().request(formMasterCustomerCategoryListSchema).response<{ list: CustomercategoryListResponse[] }>();

const createApi = createApiInterface().request(formMasterCustomerCategoryEntrySchema).response<{ isSuccess: boolean; customerCategoryId?: number }>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterCustomerCategoryEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const customerCategoryApi = createApiInterfaceMap({
	['/v1/master/customer-manage/customer-category/find']: findApi,
	['/v1/master/customer-manage/customer-category/list']: listApi,
	['/v1/master/customer-manage/customer-category/create']: createApi,
	['/v1/master/customer-manage/customer-category/update']: updateApi,
	['/v1/master/customer-manage/customer-category/delete']: deleteApi,
});
