import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formSystemMaintenanceCompanyEntrySchema, formSystemMaintenanceCompanyListSchema } from '../../../schema/form/systemMaintenance/company';
import { MasterResponse } from '../../../response';

export type CompanyListResponse = MasterResponse & {
	companyId: number;
	companyCode: string;
	companyName: string;
	companyNote: string | undefined;
	companyPostalCode: string | undefined;
	companyAddress: string;
	companyTel: string | undefined;
	companyFax: string | undefined;
	companyEmail: string | undefined;
	countryId: number;
	countryName: string;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: CompanyListResponse[] }>();

const listApi = createApiInterface().request(formSystemMaintenanceCompanyListSchema).response<{
	list: CompanyListResponse[];
}>();

const createApi = createApiInterface().request(formSystemMaintenanceCompanyEntrySchema).response<{
	isSuccess: boolean;
	companyId?: number;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formSystemMaintenanceCompanyEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const companyApi = createApiInterfaceMap({
	['/v1/system-maintenance/company/find']: findApi,
	['/v1/system-maintenance/company/list']: listApi,
	['/v1/system-maintenance/company/create']: createApi,
	['/v1/system-maintenance/company/update']: updateApi,
	['/v1/system-maintenance/company/delete']: deleteApi,
});
