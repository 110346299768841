import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import Routing from './Routing';
import { ErrorBoundary } from 'component/ErrorBoundary';
import { IntlLoader } from 'component/intl/IntlLoader';
import registerServiceWorker from 'registerServiceWorker';
import { setYupLocale } from 'util/yup-config';
import { createTheme, Theme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { jaJP } from '@mui/material/locale';

import { QueryClientProvider } from 'react-query';
import DateFormatProvider from 'util/DateFormatProvider';
import { queryClient } from 'util/query-client';
import { ReactQueryDevtools } from 'react-query/devtools';
import { getTheme } from 'component/common/theme/theme';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { LoadingProvider } from 'component/LoadingProvider';
import SideMenuProvider from 'component/common/menu/SideMenuProvider';

registerServiceWorker();

const breakpoints = createBreakpoints({});

let mdTheme: Theme = createTheme(
	{
		components: {
			MuiInputLabel: {
				styleOverrides: {
					root: {
						[breakpoints.between('xs', 'sm')]: {
							fontSize: '24px',
						},
						[breakpoints.up('md')]: {
							fontSize: '16px',
						},
					},
				},
			},
			MuiFormLabel: {
				styleOverrides: {
					root: {
						[breakpoints.between('xs', 'sm')]: {
							fontSize: '24px',
						},
						[breakpoints.up('md')]: {
							fontSize: '16px',
						},
						transformOrigin: 'top left',
						transform: 'translate(0, 7px) scale(0.75);',
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						[breakpoints.between('xs', 'sm')]: {
							fontSize: '20px',
						},
					},
				},
			},
			MuiTablePagination: {
				styleOverrides: {
					displayedRows: {
						[breakpoints.between('xs', 'sm')]: {
							fontSize: '20px',
						},
						[breakpoints.up('md')]: {
							fontSize: '16px',
						},
					},
				},
			},
		},
	},
	jaJP
);
mdTheme = getTheme(mdTheme);

setYupLocale();

//
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);
root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<LoadingProvider>
				<QueryClientProvider client={queryClient}>
					<IntlLoader>
						<ThemeProvider theme={mdTheme}>
							<DateFormatProvider>
								<SideMenuProvider>
									<Routing />
								</SideMenuProvider>
							</DateFormatProvider>
						</ThemeProvider>
					</IntlLoader>
				</QueryClientProvider>
			</LoadingProvider>
		</ErrorBoundary>
	</React.StrictMode>
);
