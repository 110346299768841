// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterWarehouseLocationDetailIdSchema, typeMasterWarehouseLocationDetailCodeSchema, typeMasterWarehouseLocationDetailNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeStockTakingScheduledDateSchema } from "./../../stock";

export const formStockStockTakingScheduleEntryLocationDetailIdSchema = typeMasterWarehouseLocationDetailIdSchema;
export type FormStockStockTakingScheduleEntryLocationDetailIdSchemaType = InferType<typeof formStockStockTakingScheduleEntryLocationDetailIdSchema>;
export const formStockStockTakingScheduleEntryLocationDetailCodeSchema = typeMasterWarehouseLocationDetailCodeSchema;
export type FormStockStockTakingScheduleEntryLocationDetailCodeSchemaType = InferType<typeof formStockStockTakingScheduleEntryLocationDetailCodeSchema>;
export const formStockStockTakingScheduleEntryLocationDetailNameSchema = typeMasterWarehouseLocationDetailNameSchema;
export type FormStockStockTakingScheduleEntryLocationDetailNameSchemaType = InferType<typeof formStockStockTakingScheduleEntryLocationDetailNameSchema>;
export const formStockStockTakingScheduleEntryStockTakingScheduleDateSchema = typeStockTakingScheduledDateSchema;
export type FormStockStockTakingScheduleEntryStockTakingScheduleDateSchemaType = InferType<typeof formStockStockTakingScheduleEntryStockTakingScheduleDateSchema>;
export const formStockStockTakingScheduleEntrySchema = yup.object({
	locationDetailId: formStockStockTakingScheduleEntryLocationDetailIdSchema,
	locationDetailCode: formStockStockTakingScheduleEntryLocationDetailCodeSchema,
	locationDetailName: formStockStockTakingScheduleEntryLocationDetailNameSchema,
	stockTakingScheduleDate: formStockStockTakingScheduleEntryStockTakingScheduleDateSchema,
}).required();
export type FormStockStockTakingScheduleEntrySchemaType = InferType<typeof formStockStockTakingScheduleEntrySchema>;
