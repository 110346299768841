/** 出荷前検品 */
import { number, object } from 'yup';
import { formShippingShippingPackagingInspectionSchema } from '../../schema/form/shipping/shippingPackagingInspection';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { ShippingInspectionResponse } from './inspection';

const findApi = createApiInterface().request(formShippingShippingPackagingInspectionSchema).response<{
	list: ShippingInspectionResponse[];
}>();

// const registApi = createApiInterface().request({id: number().integer().required(), form:}).response<{
// 	isSuccess: true
// }>()

export const shippingPackagingInspectionApi = createApiInterfaceMap({
	['/v1/shipping/packaging-inspection/find']: findApi,
	// ['/v1/shipping/inspection/regist']: registApi,
});
