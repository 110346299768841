/** 顧客配送先マスタ */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterCustomerDeliveryDestinationEntrySchema, formMasterCustomerDeliveryDestinationListSchema } from '../../../schema/form/master/customer';

export type DeliveryDestinationResponse = MasterResponse & {
	customerDeliveryDestinationId: number; // 顧客配送先ID
	customerDeliveryDestinationCode: string; // 顧客配送先コード
	customerDeliveryDestinationName: string; // 顧客配送先名
	customerDeliveryDestinationNameRuby: string | undefined; // 顧客配送先カナ
	customerId: number; // 顧客ID
	customerCode: string; // 顧客コード
	customerName: string; // 顧客名
	customerDeliveryDestinationPostalCode: string | undefined; // 郵便番号
	customerDeliveryDestinationAddress: string; // 住所
	customerDeliveryDestinationTel: string;  // TEL
	customerDeliveryDestinationFax: string | undefined; // FAX
	customerDeliveryDestinationNote: string | undefined; // 備考
	countryId: number; // 国名ID
	countryCode: string; // 国名コード
	countryName: string; // 国名
	prefectureId: number | undefined; // 都道府県ID
	prefectureCode: string | undefined; // 都道府県コード
	prefectureName: string | undefined; // 都道府県名
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: DeliveryDestinationResponse[] }>();

const listApi = createApiInterface().request(formMasterCustomerDeliveryDestinationListSchema).response<{
	list: DeliveryDestinationResponse[];
}>();

const createApi = createApiInterface().request(formMasterCustomerDeliveryDestinationEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterCustomerDeliveryDestinationEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const deliveryDestinationApi = createApiInterfaceMap({
	['/v1/master/customer-manage/delivery-destination/find']: findApi,
	['/v1/master/customer-manage/delivery-destination/list']: listApi,
	['/v1/master/customer-manage/delivery-destination/create']: createApi,
	['/v1/master/customer-manage/delivery-destination/update']: updateApi,
	['/v1/master/customer-manage/delivery-destination/delete']: deleteApi,
});
