import { ListResponse } from '../../response';
import { formReceivingReceivingListSchema } from '../../schema/form/receiving/receivingList';
import { formReceivingReceivingScheduleEntrySchema } from '../../schema/form/receiving/receivingScheduleEntry';
import { formReceivingReceivingResultEntrySchema } from '../../schema/form/receiving/receivingResultEntry';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { number, object } from 'yup';

export type ReceivingListResponse = ListResponse & {
	receiveIssueId: number; // 入出庫ID
	receiveIssueCode: string; // 入出庫コード
	receiveIssueName: string; // 入出庫名
	receiveIssueCategoryId: number; // 入出庫区分ID
	receiveIssueCategoryCode: string; // 入出庫区分コード
	receiveIssueCategoryName: string; // 入出庫区分名
	receivingScheduledDate: string; // 入庫予定日
	receivingDate: string | undefined; // 入庫日
	receiveIssueNote: string; // 入出庫備考
	movingSourceLocationDetailId: number; // 移動元ロケーション明細ID
	movingSourceLocationDetailCode: string; // 移動元ロケーション明細コード
	movingSourceLocationDetailName: string; // 移動元ロケーション明細名
	destinationLocationDetailId: number | undefined; // 移動先ロケーション明細ID
	destinationLocationDetailCode: string | undefined; // 移動先ロケーション明細コード
	destinationLocationDetailName: string | undefined; // 移動先ロケーション明細名
};

export type ReceivingDetailListResponse = ReceivingListResponse & { receivingDetail: ReceivingDetail[] };

export type ReceivingDetail = {
	receiveIssueDetailId: number | undefined; // 入出庫明細ID
	skuLocationDetailId: number | undefined; // SKUロケーション明細ID
	skuLocationDetailCode: number | undefined; // SKUロケーション明細コード
	skuLocationDetailName: string | undefined; // SKUロケーション明細名
	skuId: number | undefined; // SKUID
	skuCode: number | undefined; // SKUコード
	productId: number | undefined; // 商品ID
	productCode: string | undefined; // 商品コード
	productName: string | undefined; // 商品名
	receivingScheduledQuantity: number | undefined; // 入庫予定数量
	receivingQuantity: number | undefined; // 入庫数量
	receiveIssueDetailNote: string | undefined; // 入出庫明細備考
};

// export type ReceivingDetailListResponse = ReceivingListResponse & {
// 	receiveIssueDetailId: number[] | undefined; // 入出庫明細ID
// 	skuLocationDetailId: number[] | undefined; // SKUロケーション明細ID
// 	skuLocationDetailCode: number[] | undefined; // SKUロケーション明細コード
// 	skuLocationDetailName: string[] | undefined; // SKUロケーション明細名
// 	skuId: number[] | undefined; // SKUID
// 	skuCode: number[] | undefined; // SKUコード
// 	productId: number[] | undefined; // 商品ID
// 	productCode: string[] | undefined; // 商品コード
// 	productName: string[] | undefined; // 商品名
// 	receivingScheduleQuantity: number[] | undefined; // 入庫予定数量
// 	receivingQuantity: number[] | undefined; // 入庫数量
// 	receiveIssueDetailNote: string[] | undefined; // 入出庫明細備考
// };

/** 入庫一覧 */
const listApi = createApiInterface().request(formReceivingReceivingListSchema).response<{
	list: ReceivingListResponse[];
}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

/** 入庫予定入力 */
const scheduleCreateApi = createApiInterface().request(formReceivingReceivingScheduleEntrySchema).response<{ isSuccess: boolean }>();

const scheduleUpdateApi = createApiInterface()
	.request(object({ id: number().integer().required(), form: formReceivingReceivingScheduleEntrySchema }))
	.response<{ isSuccess: boolean }>();

const scheduleFindApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: ReceivingDetailListResponse[];
	}>();

/** 入庫実績入力 */
const resultCreateApi = createApiInterface().request(formReceivingReceivingResultEntrySchema).response<{
	isSuccess: boolean;
}>();

const resultUpdateApi = createApiInterface()
	.request(object({ id: number().integer().required(), form: formReceivingReceivingResultEntrySchema }))
	.response<{ isSuccess: boolean }>();

const resultFindApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: ReceivingDetailListResponse[];
	}>();

export const receivingApi = createApiInterfaceMap({
	['/v1/receiving/list']: listApi,
	['/v1/receiving/delete']: deleteApi,
	['/v1/receiving/entry-schedule/create']: scheduleCreateApi,
	['/v1/receiving/entry-schedule/update']: scheduleUpdateApi,
	['/v1/receiving/entry-schedule/find']: scheduleFindApi,
	['/v1/receiving/entry-result/create']: resultCreateApi,
	['/v1/receiving/entry-result/update']: resultUpdateApi,
	['/v1/receiving/entry-result/find']: resultFindApi,
});
