import { number, object } from 'yup';
import { MasterResponse } from '../../../response';
import { formSystemMaintenanceFeatureCategoryEntrySchema, formSystemMaintenanceFeatureCategoryListSchema } from '../../../schema/form/systemMaintenance/feature';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';

export type FeaturesCategoryListResponse = MasterResponse & {
	id: number;
	featureCategoryId: number; // 機能区分ID
	featureCategoryCode: string; // 機能区分コード
	featureCategoryName: string; // 機能区分名
	featureCategoryNote: string | undefined; // 備考
	terminalType: number; // PC/Handy
	forDeveloper: boolean; // 開発者向け機能
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: FeaturesCategoryListResponse[] }>();

const listApi = createApiInterface().request(formSystemMaintenanceFeatureCategoryListSchema.notRequired()).response<{ list: FeaturesCategoryListResponse[] }>();

const createApi = createApiInterface().request(formSystemMaintenanceFeatureCategoryEntrySchema).response<{ isSuccess: boolean }>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formSystemMaintenanceFeatureCategoryEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const featuresCategoryApi = createApiInterfaceMap({
	['/v1/system-maintenance/feature/category/find']: findApi,
	['/v1/system-maintenance/feature/category/list']: listApi,
	['/v1/system-maintenance/feature/category/create']: createApi,
	['/v1/system-maintenance/feature/category/update']: updateApi,
	['/v1/system-maintenance/feature/category/delete']: deleteApi,
});
