import React, { ErrorInfo, ReactNode } from 'react';

type ErrorInfoViewerProps = {
	info: ErrorInfo;
};
function ErrorInfoViewer({ info }: ErrorInfoViewerProps) {
	if (info === undefined)
		return (
			<div id="error-info">
				<h3>Component Stack</h3>
				<p>unknown</p>
			</div>
		);
	return (
		<div id="error-info">
			<h3>Component Stack</h3>
			<pre>{info.componentStack}</pre>
		</div>
	);
}

type ErrorViewerProps = {
	error: Error;
	info: ErrorInfo;
};
function ErrorViewer({ error, info }: ErrorViewerProps) {
	return (
		<div id="error">
			<h1>エラーが発生しました</h1>
			<h2>{error.message}</h2>
			<hr />
			{error instanceof CustomViewerError ? error.getViewer() : null}
			<hr />
			<ErrorInfoViewer info={info} />
		</div>
	);
}

type ErrorBoundaryProps = {
	children: ReactNode;
};
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { error: Error | null; info: ErrorInfo | null }> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { error: null, info: null };
	}

	// static getDerivedStateFromError(error:any,info:any) {
	//   // Update state so the next render will show the fallback UI.
	//   return { error, info };
	// }

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	componentDidCatch(error: Error, info: any) {
		// You can also log the error to an error reporting service
		this.setState({
			error,
			info,
		});
	}

	render() {
		if (this.state.error !== null && this.state.info !== null) {
			// You can render any custom fallback UI
			return (
				<ErrorViewer
					error={this.state.error}
					info={this.state.info}
				/>
			);
		}

		return this.props.children;
	}
}

export class CustomViewerError extends Error {
	private errorInfo?: React.ErrorInfo;
	private viewer: React.ReactNode;

	public constructor(message: string, viewer: React.ReactNode) {
		super(message);
		this.viewer = viewer;
	}

	public setErrorInfo(info: ErrorInfo) {
		this.errorInfo = info;
	}

	public getErrorInfo() {
		return this.errorInfo;
	}

	public getViewer() {
		return this.viewer;
	}
}

export const errorThrowComponentConstructor = (error: Error) => () => {
	throw error;
};
