// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterClientCategoryCodeSchema, typeMasterClientCategoryNameSchema, typeMasterClientSenderCodeSchema, typeMasterClientSenderNameSchema, typeMasterClientIdSchema, typeMasterClientCodeSchema, typeMasterClientNameSchema, typeMasterClientTransportMethodCodeSchema, typeMasterClientTransportMethodNameSchema, typeMasterClientTransportMethodIdSchema, typeMasterShipperIdSchema, typeMasterShipperCodeSchema, typeMasterShipperNameSchema, typeMasterClientCategoryIdSchema, typeMasterClientContactNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema, typeCommonPostalCodeSchema, typeCommonAddressSchema, typeCommonTelSchema, typeCommonFaxSchema } from "./../../common";
import { typeSearchItemsSchema } from "./../../search";
import { typeDetailedConditionItemsSchema } from "./../../detailedCondition";
import { typeSystemMaintenanceRegionCountryIdSchema, typeSystemMaintenanceRegionPrefectureIdSchema } from "./../../systemMaintenance";

export const formMasterClientCategoryListClientCategoryCodeSchema = typeMasterClientCategoryCodeSchema.optional();
export type FormMasterClientCategoryListClientCategoryCodeSchemaType = InferType<typeof formMasterClientCategoryListClientCategoryCodeSchema>;
export const formMasterClientCategoryListClientCategoryNameSchema = typeMasterClientCategoryNameSchema.optional();
export type FormMasterClientCategoryListClientCategoryNameSchemaType = InferType<typeof formMasterClientCategoryListClientCategoryNameSchema>;
export const formMasterClientCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterClientCategoryListIsAvailableSchemaType = InferType<typeof formMasterClientCategoryListIsAvailableSchema>;
export const formMasterClientCategoryListSchema = yup.object({
	clientCategoryCode: formMasterClientCategoryListClientCategoryCodeSchema,
	clientCategoryName: formMasterClientCategoryListClientCategoryNameSchema,
	isAvailable: formMasterClientCategoryListIsAvailableSchema,
}).required();
export type FormMasterClientCategoryListSchemaType = InferType<typeof formMasterClientCategoryListSchema>;
export const formMasterClientCategoryEntryClientCategoryCodeSchema = typeMasterClientCategoryCodeSchema;
export type FormMasterClientCategoryEntryClientCategoryCodeSchemaType = InferType<typeof formMasterClientCategoryEntryClientCategoryCodeSchema>;
export const formMasterClientCategoryEntryClientCategoryNameSchema = typeMasterClientCategoryNameSchema;
export type FormMasterClientCategoryEntryClientCategoryNameSchemaType = InferType<typeof formMasterClientCategoryEntryClientCategoryNameSchema>;
export const formMasterClientCategoryEntryClientCategoryNoteSchema = typeCommonNoteSchema;
export type FormMasterClientCategoryEntryClientCategoryNoteSchemaType = InferType<typeof formMasterClientCategoryEntryClientCategoryNoteSchema>;
export const formMasterClientCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema.optional();
export type FormMasterClientCategoryEntryAvailableFromSchemaType = InferType<typeof formMasterClientCategoryEntryAvailableFromSchema>;
export const formMasterClientCategoryEntryAvailableToSchema = typeCommonAvailableToSchema.optional();
export type FormMasterClientCategoryEntryAvailableToSchemaType = InferType<typeof formMasterClientCategoryEntryAvailableToSchema>;
export const formMasterClientCategoryEntrySchema = yup.object({
	clientCategoryCode: formMasterClientCategoryEntryClientCategoryCodeSchema,
	clientCategoryName: formMasterClientCategoryEntryClientCategoryNameSchema,
	clientCategoryNote: formMasterClientCategoryEntryClientCategoryNoteSchema,
	availableFrom: formMasterClientCategoryEntryAvailableFromSchema,
	availableTo: formMasterClientCategoryEntryAvailableToSchema,
}).required();
export type FormMasterClientCategoryEntrySchemaType = InferType<typeof formMasterClientCategoryEntrySchema>;
export const formMasterClientSenderListClientSenderCodeSchema = typeMasterClientSenderCodeSchema.optional();
export type FormMasterClientSenderListClientSenderCodeSchemaType = InferType<typeof formMasterClientSenderListClientSenderCodeSchema>;
export const formMasterClientSenderListClientSenderNameSchema = typeMasterClientSenderNameSchema.optional();
export type FormMasterClientSenderListClientSenderNameSchemaType = InferType<typeof formMasterClientSenderListClientSenderNameSchema>;
export const formMasterClientSenderListClientIdSchema = typeMasterClientIdSchema.optional();
export type FormMasterClientSenderListClientIdSchemaType = InferType<typeof formMasterClientSenderListClientIdSchema>;
export const formMasterClientSenderListClientCodeSchema = typeMasterClientCodeSchema.optional();
export type FormMasterClientSenderListClientCodeSchemaType = InferType<typeof formMasterClientSenderListClientCodeSchema>;
export const formMasterClientSenderListClientNameSchema = typeMasterClientNameSchema.optional();
export type FormMasterClientSenderListClientNameSchemaType = InferType<typeof formMasterClientSenderListClientNameSchema>;
export const formMasterClientSenderListTransportMethodCodeSchema = typeMasterClientTransportMethodCodeSchema.optional();
export type FormMasterClientSenderListTransportMethodCodeSchemaType = InferType<typeof formMasterClientSenderListTransportMethodCodeSchema>;
export const formMasterClientSenderListTransportMethodNameSchema = typeMasterClientTransportMethodNameSchema.optional();
export type FormMasterClientSenderListTransportMethodNameSchemaType = InferType<typeof formMasterClientSenderListTransportMethodNameSchema>;
export const formMasterClientSenderListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterClientSenderListIsAvailableSchemaType = InferType<typeof formMasterClientSenderListIsAvailableSchema>;
export const formMasterClientSenderListSearchItemsSchema = typeSearchItemsSchema;
export type FormMasterClientSenderListSearchItemsSchemaType = InferType<typeof formMasterClientSenderListSearchItemsSchema>;
export const formMasterClientSenderListDetailedConditionsSchema = typeDetailedConditionItemsSchema;
export type FormMasterClientSenderListDetailedConditionsSchemaType = InferType<typeof formMasterClientSenderListDetailedConditionsSchema>;
export const formMasterClientSenderListSchema = yup.object({
	clientSenderCode: formMasterClientSenderListClientSenderCodeSchema,
	clientSenderName: formMasterClientSenderListClientSenderNameSchema,
	clientId: formMasterClientSenderListClientIdSchema,
	clientCode: formMasterClientSenderListClientCodeSchema,
	clientName: formMasterClientSenderListClientNameSchema,
	transportMethodCode: formMasterClientSenderListTransportMethodCodeSchema,
	transportMethodName: formMasterClientSenderListTransportMethodNameSchema,
	isAvailable: formMasterClientSenderListIsAvailableSchema,
	searchItems: formMasterClientSenderListSearchItemsSchema,
	detailedConditions: formMasterClientSenderListDetailedConditionsSchema,
}).required();
export type FormMasterClientSenderListSchemaType = InferType<typeof formMasterClientSenderListSchema>;
export const formMasterClientSenderEntryClientSenderCodeSchema = typeMasterClientSenderCodeSchema;
export type FormMasterClientSenderEntryClientSenderCodeSchemaType = InferType<typeof formMasterClientSenderEntryClientSenderCodeSchema>;
export const formMasterClientSenderEntryClientSenderNameSchema = typeMasterClientSenderNameSchema;
export type FormMasterClientSenderEntryClientSenderNameSchemaType = InferType<typeof formMasterClientSenderEntryClientSenderNameSchema>;
export const formMasterClientSenderEntryClientIdSchema = typeMasterClientIdSchema;
export type FormMasterClientSenderEntryClientIdSchemaType = InferType<typeof formMasterClientSenderEntryClientIdSchema>;
export const formMasterClientSenderEntryClientCodeSchema = typeMasterClientCodeSchema;
export type FormMasterClientSenderEntryClientCodeSchemaType = InferType<typeof formMasterClientSenderEntryClientCodeSchema>;
export const formMasterClientSenderEntryClientNameSchema = typeMasterClientNameSchema;
export type FormMasterClientSenderEntryClientNameSchemaType = InferType<typeof formMasterClientSenderEntryClientNameSchema>;
export const formMasterClientSenderEntryTransportMethodIdSchema = typeMasterClientTransportMethodIdSchema;
export type FormMasterClientSenderEntryTransportMethodIdSchemaType = InferType<typeof formMasterClientSenderEntryTransportMethodIdSchema>;
export const formMasterClientSenderEntryClientSenderPostalCodeSchema = typeCommonPostalCodeSchema;
export type FormMasterClientSenderEntryClientSenderPostalCodeSchemaType = InferType<typeof formMasterClientSenderEntryClientSenderPostalCodeSchema>;
export const formMasterClientSenderEntryClientSenderAddressSchema = typeCommonAddressSchema;
export type FormMasterClientSenderEntryClientSenderAddressSchemaType = InferType<typeof formMasterClientSenderEntryClientSenderAddressSchema>;
export const formMasterClientSenderEntryClientSenderTelSchema = typeCommonTelSchema;
export type FormMasterClientSenderEntryClientSenderTelSchemaType = InferType<typeof formMasterClientSenderEntryClientSenderTelSchema>;
export const formMasterClientSenderEntryClientSenderFaxSchema = typeCommonFaxSchema;
export type FormMasterClientSenderEntryClientSenderFaxSchemaType = InferType<typeof formMasterClientSenderEntryClientSenderFaxSchema>;
export const formMasterClientSenderEntryClientSenderNoteSchema = typeCommonNoteSchema;
export type FormMasterClientSenderEntryClientSenderNoteSchemaType = InferType<typeof formMasterClientSenderEntryClientSenderNoteSchema>;
export const formMasterClientSenderEntryCountryIdSchema = typeSystemMaintenanceRegionCountryIdSchema;
export type FormMasterClientSenderEntryCountryIdSchemaType = InferType<typeof formMasterClientSenderEntryCountryIdSchema>;
export const formMasterClientSenderEntryPrefectureIdSchema = typeSystemMaintenanceRegionPrefectureIdSchema.optional();
export type FormMasterClientSenderEntryPrefectureIdSchemaType = InferType<typeof formMasterClientSenderEntryPrefectureIdSchema>;
export const formMasterClientSenderEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterClientSenderEntryAvailableFromSchemaType = InferType<typeof formMasterClientSenderEntryAvailableFromSchema>;
export const formMasterClientSenderEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterClientSenderEntryAvailableToSchemaType = InferType<typeof formMasterClientSenderEntryAvailableToSchema>;
export const formMasterClientSenderEntrySchema = yup.object({
	clientSenderCode: formMasterClientSenderEntryClientSenderCodeSchema,
	clientSenderName: formMasterClientSenderEntryClientSenderNameSchema,
	clientId: formMasterClientSenderEntryClientIdSchema,
	clientCode: formMasterClientSenderEntryClientCodeSchema,
	clientName: formMasterClientSenderEntryClientNameSchema,
	transportMethodId: formMasterClientSenderEntryTransportMethodIdSchema,
	clientSenderPostalCode: formMasterClientSenderEntryClientSenderPostalCodeSchema,
	clientSenderAddress: formMasterClientSenderEntryClientSenderAddressSchema,
	clientSenderTel: formMasterClientSenderEntryClientSenderTelSchema,
	clientSenderFax: formMasterClientSenderEntryClientSenderFaxSchema,
	clientSenderNote: formMasterClientSenderEntryClientSenderNoteSchema,
	countryId: formMasterClientSenderEntryCountryIdSchema,
	prefectureId: formMasterClientSenderEntryPrefectureIdSchema,
	availableFrom: formMasterClientSenderEntryAvailableFromSchema,
	availableTo: formMasterClientSenderEntryAvailableToSchema,
}).required();
export type FormMasterClientSenderEntrySchemaType = InferType<typeof formMasterClientSenderEntrySchema>;
export const formMasterClientTransportMethodListTransportMethodCodeSchema = typeMasterClientTransportMethodCodeSchema.optional();
export type FormMasterClientTransportMethodListTransportMethodCodeSchemaType = InferType<typeof formMasterClientTransportMethodListTransportMethodCodeSchema>;
export const formMasterClientTransportMethodListTransportMethodNameSchema = typeMasterClientTransportMethodNameSchema.optional();
export type FormMasterClientTransportMethodListTransportMethodNameSchemaType = InferType<typeof formMasterClientTransportMethodListTransportMethodNameSchema>;
export const formMasterClientTransportMethodListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterClientTransportMethodListIsAvailableSchemaType = InferType<typeof formMasterClientTransportMethodListIsAvailableSchema>;
export const formMasterClientTransportMethodListSchema = yup.object({
	transportMethodCode: formMasterClientTransportMethodListTransportMethodCodeSchema,
	transportMethodName: formMasterClientTransportMethodListTransportMethodNameSchema,
	isAvailable: formMasterClientTransportMethodListIsAvailableSchema,
}).required();
export type FormMasterClientTransportMethodListSchemaType = InferType<typeof formMasterClientTransportMethodListSchema>;
export const formMasterClientTransportMethodEntryTransportMethodCodeSchema = typeMasterClientTransportMethodCodeSchema;
export type FormMasterClientTransportMethodEntryTransportMethodCodeSchemaType = InferType<typeof formMasterClientTransportMethodEntryTransportMethodCodeSchema>;
export const formMasterClientTransportMethodEntryTransportMethodNameSchema = typeMasterClientTransportMethodNameSchema;
export type FormMasterClientTransportMethodEntryTransportMethodNameSchemaType = InferType<typeof formMasterClientTransportMethodEntryTransportMethodNameSchema>;
export const formMasterClientTransportMethodEntryTransportMethodNoteSchema = typeCommonNoteSchema;
export type FormMasterClientTransportMethodEntryTransportMethodNoteSchemaType = InferType<typeof formMasterClientTransportMethodEntryTransportMethodNoteSchema>;
export const formMasterClientTransportMethodEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterClientTransportMethodEntryAvailableFromSchemaType = InferType<typeof formMasterClientTransportMethodEntryAvailableFromSchema>;
export const formMasterClientTransportMethodEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterClientTransportMethodEntryAvailableToSchemaType = InferType<typeof formMasterClientTransportMethodEntryAvailableToSchema>;
export const formMasterClientTransportMethodEntrySchema = yup.object({
	transportMethodCode: formMasterClientTransportMethodEntryTransportMethodCodeSchema,
	transportMethodName: formMasterClientTransportMethodEntryTransportMethodNameSchema,
	transportMethodNote: formMasterClientTransportMethodEntryTransportMethodNoteSchema,
	availableFrom: formMasterClientTransportMethodEntryAvailableFromSchema,
	availableTo: formMasterClientTransportMethodEntryAvailableToSchema,
}).required();
export type FormMasterClientTransportMethodEntrySchemaType = InferType<typeof formMasterClientTransportMethodEntrySchema>;
export const formMasterClientListShipperIdSchema = typeMasterShipperIdSchema.optional();
export type FormMasterClientListShipperIdSchemaType = InferType<typeof formMasterClientListShipperIdSchema>;
export const formMasterClientListShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormMasterClientListShipperCodeSchemaType = InferType<typeof formMasterClientListShipperCodeSchema>;
export const formMasterClientListShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormMasterClientListShipperNameSchemaType = InferType<typeof formMasterClientListShipperNameSchema>;
export const formMasterClientListClientCategoryIdSchema = typeMasterClientCategoryIdSchema.optional();
export type FormMasterClientListClientCategoryIdSchemaType = InferType<typeof formMasterClientListClientCategoryIdSchema>;
export const formMasterClientListClientNameSchema = typeMasterClientNameSchema.optional();
export type FormMasterClientListClientNameSchemaType = InferType<typeof formMasterClientListClientNameSchema>;
export const formMasterClientListClientCodeSchema = typeMasterClientCodeSchema.optional();
export type FormMasterClientListClientCodeSchemaType = InferType<typeof formMasterClientListClientCodeSchema>;
export const formMasterClientListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterClientListIsAvailableSchemaType = InferType<typeof formMasterClientListIsAvailableSchema>;
export const formMasterClientListSchema = yup.object({
	shipperId: formMasterClientListShipperIdSchema,
	shipperCode: formMasterClientListShipperCodeSchema,
	shipperName: formMasterClientListShipperNameSchema,
	clientCategoryId: formMasterClientListClientCategoryIdSchema,
	clientName: formMasterClientListClientNameSchema,
	clientCode: formMasterClientListClientCodeSchema,
	isAvailable: formMasterClientListIsAvailableSchema,
}).required();
export type FormMasterClientListSchemaType = InferType<typeof formMasterClientListSchema>;
export const formMasterClientEntryClientCodeSchema = typeMasterClientCodeSchema;
export type FormMasterClientEntryClientCodeSchemaType = InferType<typeof formMasterClientEntryClientCodeSchema>;
export const formMasterClientEntryClientNameSchema = typeMasterClientNameSchema;
export type FormMasterClientEntryClientNameSchemaType = InferType<typeof formMasterClientEntryClientNameSchema>;
export const formMasterClientEntryShipperIdSchema = typeMasterShipperIdSchema;
export type FormMasterClientEntryShipperIdSchemaType = InferType<typeof formMasterClientEntryShipperIdSchema>;
export const formMasterClientEntryShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormMasterClientEntryShipperCodeSchemaType = InferType<typeof formMasterClientEntryShipperCodeSchema>;
export const formMasterClientEntryShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormMasterClientEntryShipperNameSchemaType = InferType<typeof formMasterClientEntryShipperNameSchema>;
export const formMasterClientEntryClientCategoryIdSchema = typeMasterClientCategoryIdSchema;
export type FormMasterClientEntryClientCategoryIdSchemaType = InferType<typeof formMasterClientEntryClientCategoryIdSchema>;
export const formMasterClientEntryClientCategoryCodeSchema = typeMasterClientCategoryCodeSchema.optional();
export type FormMasterClientEntryClientCategoryCodeSchemaType = InferType<typeof formMasterClientEntryClientCategoryCodeSchema>;
export const formMasterClientEntryClientCategoryNameSchema = typeMasterClientCategoryNameSchema.optional();
export type FormMasterClientEntryClientCategoryNameSchemaType = InferType<typeof formMasterClientEntryClientCategoryNameSchema>;
export const formMasterClientEntryClientPostalCodeSchema = typeCommonPostalCodeSchema;
export type FormMasterClientEntryClientPostalCodeSchemaType = InferType<typeof formMasterClientEntryClientPostalCodeSchema>;
export const formMasterClientEntryClientAddressSchema = typeCommonAddressSchema;
export type FormMasterClientEntryClientAddressSchemaType = InferType<typeof formMasterClientEntryClientAddressSchema>;
export const formMasterClientEntryClientTelSchema = typeCommonTelSchema;
export type FormMasterClientEntryClientTelSchemaType = InferType<typeof formMasterClientEntryClientTelSchema>;
export const formMasterClientEntryClientFaxSchema = typeCommonFaxSchema;
export type FormMasterClientEntryClientFaxSchemaType = InferType<typeof formMasterClientEntryClientFaxSchema>;
export const formMasterClientEntryClientContactNameSchema = typeMasterClientContactNameSchema.optional();
export type FormMasterClientEntryClientContactNameSchemaType = InferType<typeof formMasterClientEntryClientContactNameSchema>;
export const formMasterClientEntryClientNoteSchema = typeCommonNoteSchema;
export type FormMasterClientEntryClientNoteSchemaType = InferType<typeof formMasterClientEntryClientNoteSchema>;
export const formMasterClientEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterClientEntryAvailableFromSchemaType = InferType<typeof formMasterClientEntryAvailableFromSchema>;
export const formMasterClientEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterClientEntryAvailableToSchemaType = InferType<typeof formMasterClientEntryAvailableToSchema>;
export const formMasterClientEntrySchema = yup.object({
	clientCode: formMasterClientEntryClientCodeSchema,
	clientName: formMasterClientEntryClientNameSchema,
	shipperId: formMasterClientEntryShipperIdSchema,
	shipperCode: formMasterClientEntryShipperCodeSchema,
	shipperName: formMasterClientEntryShipperNameSchema,
	clientCategoryId: formMasterClientEntryClientCategoryIdSchema,
	clientCategoryCode: formMasterClientEntryClientCategoryCodeSchema,
	clientCategoryName: formMasterClientEntryClientCategoryNameSchema,
	clientPostalCode: formMasterClientEntryClientPostalCodeSchema,
	clientAddress: formMasterClientEntryClientAddressSchema,
	clientTel: formMasterClientEntryClientTelSchema,
	clientFax: formMasterClientEntryClientFaxSchema,
	clientContactName: formMasterClientEntryClientContactNameSchema,
	clientNote: formMasterClientEntryClientNoteSchema,
	availableFrom: formMasterClientEntryAvailableFromSchema,
	availableTo: formMasterClientEntryAvailableToSchema,
}).required();
export type FormMasterClientEntrySchemaType = InferType<typeof formMasterClientEntrySchema>;
