// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";
import { typeMasterWarehouseIdSchema, typeMasterWarehouseCodeSchema, typeMasterWarehouseNameSchema, typeMasterWarehouseLocationIdSchema, typeMasterWarehouseLocationCodeSchema, typeMasterWarehouseLocationNameSchema, typeMasterWarehouseLocationDetailIdSchema, typeMasterWarehouseLocationDetailCodeSchema, typeMasterWarehouseLocationDetailNameSchema, typeMasterShipperIdSchema, typeMasterShipperCodeSchema, typeMasterShipperNameSchema, typeMasterProductPartNumberCodeSchema, typeMasterProductPartNumberNameSchema, typeMasterProductIdSchema, typeMasterProductCodeSchema, typeMasterProductNameSchema, typeMasterProductJanCodeSchema, typeMasterProductLotNameSchema, typeMasterProductLotFreshnessDateSchema } from "./../../master";
import { typeSearchItemsSchema } from "./../../search";
import { typeDetailedConditionItemsSchema } from "./../../detailedCondition";

export const formStockStockListInputSearchCategorySchema = yup.string().required().label("schema.common.search.label.target");
export type FormStockStockListInputSearchCategorySchemaType = InferType<typeof formStockStockListInputSearchCategorySchema>;
export const formStockStockListInputWarehouseIdSchema = typeMasterWarehouseIdSchema.optional();
export type FormStockStockListInputWarehouseIdSchemaType = InferType<typeof formStockStockListInputWarehouseIdSchema>;
export const formStockStockListInputWarehouseCodeSchema = typeMasterWarehouseCodeSchema.optional();
export type FormStockStockListInputWarehouseCodeSchemaType = InferType<typeof formStockStockListInputWarehouseCodeSchema>;
export const formStockStockListInputWarehouseNameSchema = typeMasterWarehouseNameSchema.optional();
export type FormStockStockListInputWarehouseNameSchemaType = InferType<typeof formStockStockListInputWarehouseNameSchema>;
export const formStockStockListInputLocationIdSchema = typeMasterWarehouseLocationIdSchema.optional();
export type FormStockStockListInputLocationIdSchemaType = InferType<typeof formStockStockListInputLocationIdSchema>;
export const formStockStockListInputLocationCodeSchema = typeMasterWarehouseLocationCodeSchema.optional();
export type FormStockStockListInputLocationCodeSchemaType = InferType<typeof formStockStockListInputLocationCodeSchema>;
export const formStockStockListInputLocationNameSchema = typeMasterWarehouseLocationNameSchema.optional();
export type FormStockStockListInputLocationNameSchemaType = InferType<typeof formStockStockListInputLocationNameSchema>;
export const formStockStockListInputLocationDetailIdSchema = typeMasterWarehouseLocationDetailIdSchema.optional();
export type FormStockStockListInputLocationDetailIdSchemaType = InferType<typeof formStockStockListInputLocationDetailIdSchema>;
export const formStockStockListInputLocationDetailCodeSchema = typeMasterWarehouseLocationDetailCodeSchema.optional();
export type FormStockStockListInputLocationDetailCodeSchemaType = InferType<typeof formStockStockListInputLocationDetailCodeSchema>;
export const formStockStockListInputLocationDetailNameSchema = typeMasterWarehouseLocationDetailNameSchema.optional();
export type FormStockStockListInputLocationDetailNameSchemaType = InferType<typeof formStockStockListInputLocationDetailNameSchema>;
export const formStockStockListInputShipperIdSchema = typeMasterShipperIdSchema.optional();
export type FormStockStockListInputShipperIdSchemaType = InferType<typeof formStockStockListInputShipperIdSchema>;
export const formStockStockListInputShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormStockStockListInputShipperCodeSchemaType = InferType<typeof formStockStockListInputShipperCodeSchema>;
export const formStockStockListInputShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormStockStockListInputShipperNameSchemaType = InferType<typeof formStockStockListInputShipperNameSchema>;
export const formStockStockListInputPartNumberCodeSchema = typeMasterProductPartNumberCodeSchema.optional();
export type FormStockStockListInputPartNumberCodeSchemaType = InferType<typeof formStockStockListInputPartNumberCodeSchema>;
export const formStockStockListInputPartNumberNameSchema = typeMasterProductPartNumberNameSchema.optional();
export type FormStockStockListInputPartNumberNameSchemaType = InferType<typeof formStockStockListInputPartNumberNameSchema>;
export const formStockStockListInputProductIdSchema = typeMasterProductIdSchema.optional();
export type FormStockStockListInputProductIdSchemaType = InferType<typeof formStockStockListInputProductIdSchema>;
export const formStockStockListInputProductCodeSchema = typeMasterProductCodeSchema.optional();
export type FormStockStockListInputProductCodeSchemaType = InferType<typeof formStockStockListInputProductCodeSchema>;
export const formStockStockListInputProductNameSchema = typeMasterProductNameSchema.optional();
export type FormStockStockListInputProductNameSchemaType = InferType<typeof formStockStockListInputProductNameSchema>;
export const formStockStockListInputJanCodeSchema = typeMasterProductJanCodeSchema.optional();
export type FormStockStockListInputJanCodeSchemaType = InferType<typeof formStockStockListInputJanCodeSchema>;
export const formStockStockListInputLotNameSchema = typeMasterProductLotNameSchema.optional();
export type FormStockStockListInputLotNameSchemaType = InferType<typeof formStockStockListInputLotNameSchema>;
export const formStockStockListInputBestBeforeFromSchema = typeMasterProductLotFreshnessDateSchema.optional();
export type FormStockStockListInputBestBeforeFromSchemaType = InferType<typeof formStockStockListInputBestBeforeFromSchema>;
export const formStockStockListInputBestBeforeToSchema = typeMasterProductLotFreshnessDateSchema.optional();
export type FormStockStockListInputBestBeforeToSchemaType = InferType<typeof formStockStockListInputBestBeforeToSchema>;
export const formStockStockListInputSearchItemsSchema = typeSearchItemsSchema;
export type FormStockStockListInputSearchItemsSchemaType = InferType<typeof formStockStockListInputSearchItemsSchema>;
export const formStockStockListInputDetailedConditionsSchema = typeDetailedConditionItemsSchema;
export type FormStockStockListInputDetailedConditionsSchemaType = InferType<typeof formStockStockListInputDetailedConditionsSchema>;
export const formStockStockListInputSchema = yup.object({
	searchCategory: formStockStockListInputSearchCategorySchema,
	warehouseId: formStockStockListInputWarehouseIdSchema,
	warehouseCode: formStockStockListInputWarehouseCodeSchema,
	warehouseName: formStockStockListInputWarehouseNameSchema,
	locationId: formStockStockListInputLocationIdSchema,
	locationCode: formStockStockListInputLocationCodeSchema,
	locationName: formStockStockListInputLocationNameSchema,
	locationDetailId: formStockStockListInputLocationDetailIdSchema,
	locationDetailCode: formStockStockListInputLocationDetailCodeSchema,
	locationDetailName: formStockStockListInputLocationDetailNameSchema,
	shipperId: formStockStockListInputShipperIdSchema,
	shipperCode: formStockStockListInputShipperCodeSchema,
	shipperName: formStockStockListInputShipperNameSchema,
	partNumberCode: formStockStockListInputPartNumberCodeSchema,
	partNumberName: formStockStockListInputPartNumberNameSchema,
	productId: formStockStockListInputProductIdSchema,
	productCode: formStockStockListInputProductCodeSchema,
	productName: formStockStockListInputProductNameSchema,
	janCode: formStockStockListInputJanCodeSchema,
	lotName: formStockStockListInputLotNameSchema,
	bestBeforeFrom: formStockStockListInputBestBeforeFromSchema,
	bestBeforeTo: formStockStockListInputBestBeforeToSchema,
	searchItems: formStockStockListInputSearchItemsSchema,
	detailedConditions: formStockStockListInputDetailedConditionsSchema,
}).required();
export type FormStockStockListInputSchemaType = InferType<typeof formStockStockListInputSchema>;
