import { object, number } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterPackagingCategoryEntrySchema, formMasterPackagingCategoryListSchema } from '../../../schema/form/master/packagingCategory';

export type PackagingCategoryListResponse = MasterResponse & {
	packagingCategoryId: number; // 梱包区分ID
	packagingCategoryCode: string; // 梱包区分コード
	packagingCategoryName: string; // 梱包区分名
	packagingCategoryNote: string | undefined; // 備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: PackagingCategoryListResponse[] }>();

const listApi = createApiInterface().request(formMasterPackagingCategoryListSchema).response<{
	list: PackagingCategoryListResponse[];
}>();

const createApi = createApiInterface().request(formMasterPackagingCategoryEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterPackagingCategoryEntrySchema }))
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const packagingCategoryApi = createApiInterfaceMap({
	['/v1/master/packaging/category/find']: findApi,
	['/v1/master/packaging/category/list']: listApi,
	['/v1/master/packaging/category/create']: createApi,
	['/v1/master/packaging/category/update']: updateApi,
	['/v1/master/packaging/category/delete']: deleteApi,
});
