/** ユーザー権限マスタ */

import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formMasterUserAuthorityEntrySchema, formMasterUserAuthorityListSchema } from '../../../schema/form/master/user';
import { MasterResponse } from '../../../response';
import { FeatureResponse } from '../../system-maintenance/feature/feature';

export type UserAuthorityListResponse = MasterResponse & {
	authorityId: number; // 権限ID
	authorityCode: string; // 権限コード
	authorityName: string; // 権限名
	authorityNote: string | undefined; // 備考
	features: FeatureResponse[];
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: UserAuthorityListResponse[] }>();

const listApi = createApiInterface().request(formMasterUserAuthorityListSchema.notRequired()).response<{ list: UserAuthorityListResponse[] }>();

const createApi = createApiInterface().request(formMasterUserAuthorityEntrySchema).response<{
	isSuccess: boolean;
	authorityId?: number;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterUserAuthorityEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ isSuccess: boolean }>();

export const userAuthorityApi = createApiInterfaceMap({
	['/v1/master/user/authority/find']: findApi,
	['/v1/master/user/authority/list']: listApi,
	['/v1/master/user/authority/create']: createApi,
	['/v1/master/user/authority/update']: updateApi,
	['/v1/master/user/authority/delete']: deleteApi,
});
