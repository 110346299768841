// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionNumberSchema } from "./../../common";
import { InferType } from "yup";

export const formShippingShippingPackagingInspectionInspectionNoSchema = typeCommonInspectionNumberSchema;
export type FormShippingShippingPackagingInspectionInspectionNoSchemaType = InferType<typeof formShippingShippingPackagingInspectionInspectionNoSchema>;
export const formShippingShippingPackagingInspectionSchema = yup.object({
	inspectionNo: formShippingShippingPackagingInspectionInspectionNoSchema,
}).required();
export type FormShippingShippingPackagingInspectionSchemaType = InferType<typeof formShippingShippingPackagingInspectionSchema>;
