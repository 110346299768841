import { object } from 'yup';
import { MasterResponse } from '../../../../response';
import { createApiInterface, createApiInterfaceMap } from '../../../api-interface';

export type ShippingCategoryListResponse = MasterResponse & {
	shippingCategoryId: number;
	shippingCategoryCode: string;
	shippingCategoryName: string;
	shippingCategoryNote: string | undefined;
};

const listApi = createApiInterface().request(object().required()).response<{
	list: ShippingCategoryListResponse[];
}>();

export const shippingCategoryApi = createApiInterfaceMap({
	['/v1/master/shipping/category/list']: listApi,
});
