/** 都道府県コード */
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formSystemMaintenanceRegionPrefectureCodeListSchema } from '../../../schema/form/systemMaintenance/region';
import { MasterResponse } from '../../../response';

export type PrefectureCodeListResponse = MasterResponse & {
	prefectureId: number;
	countryId: number;
	prefectureName: string;
	prefectureCode: string;
	prefectureNote: string | undefined;
};

const listApi = createApiInterface().request(formSystemMaintenanceRegionPrefectureCodeListSchema.notRequired()).response<{
	list: PrefectureCodeListResponse[];
}>();

export const prefectureApi = createApiInterfaceMap({
	['/v1/system-maintenance/region/prefecture-code/list']: listApi,
});
