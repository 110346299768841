// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionNumberSchema } from "./../../common";
import { InferType } from "yup";

export const formIssuingIssuingInspectionReceiveIssueIdSchema = typeCommonInspectionNumberSchema;
export type FormIssuingIssuingInspectionReceiveIssueIdSchemaType = InferType<typeof formIssuingIssuingInspectionReceiveIssueIdSchema>;
export const formIssuingIssuingInspectionSchema = yup.object({
	receiveIssueId: formIssuingIssuingInspectionReceiveIssueIdSchema,
}).required();
export type FormIssuingIssuingInspectionSchemaType = InferType<typeof formIssuingIssuingInspectionSchema>;
