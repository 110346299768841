/** 在庫引当 */
import { number, object } from 'yup';
import { ListResponse } from '../../response';
import { formShippingShippingAllocationEntrySchema } from '../../schema/form/shipping/shippingAllocationEntry';
import { formShippingShippingAllocationListSchema } from '../../schema/form/shipping/shippingAllocationList';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';

export type AllocationListResponse = ListResponse & {
	shippingId: number; // 出荷ID
	shippingCode: string; // 出荷コード
	shippingDetailId: number; // 出荷明細ID
	shippingDetailCode: string; // 出荷明細コード
	customerId: number; // 顧客ID
	customerCode: string; // 顧客コード
	customerName: string; // 顧客名
	shippingScheduledDate: string; // 出荷予定日
	skuId: number; // SKUID
	skuCode: string; // SKUコード
	janCode: string; // JANコード
	partNumberId: number; // 品番ID
	partNumberCode: string; // 品番コード
	partNumberName: string; // 品名
	productId: number; // 商品ID
	productCode: string; // 商品コード
	productName: string; // 商品名
	lotId: number; // ロットID
	lotName: string; // ロット名
	shippingScheduledQuantity: number | undefined; // 出荷予定数量
	allocationQuantity: number | undefined; // 引当数量
};

const listApi = createApiInterface().request(formShippingShippingAllocationListSchema).response<{
	list: AllocationListResponse[];
}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

const createApi = createApiInterface().request(formShippingShippingAllocationEntrySchema).response<{ isSuccess: boolean }>();

export const allocationApi = createApiInterfaceMap({
	['/v1/shipping/allocation/list']: listApi,
	['/v1/shipping/allocation/create']: createApi,
	['/v1/shipping/allocation/delete']: deleteApi,
});
