import { object, string } from 'yup';
import { createApiInterface, createApiInterfaceMap } from './api-interface';

const loginApi = createApiInterface()
	.request(
		object({
			code: string().required(),
			password: string().required(),
		})
	)
	.response<{
		status: number;
		// ※暫定
		// 1 => 成功
		// 2 => 失敗
	}>();

const logoutApi = createApiInterface().request(object({}).required()).response<{ isSuccess: boolean }>();

const fetchAccountData = createApiInterface().request(object({}).required()).response<{
	userId: number;
	userName: string;
	companyId: number;
	authorityId: number;
}>();

export const accountApi = createApiInterfaceMap({
	['/v1/account/login']: loginApi,
	['/v1/account/logout']: logoutApi,
	['/v1/account/fetch-account']: fetchAccountData,
});
