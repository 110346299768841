// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterWarehouseIdSchema, typeMasterWarehouseCodeSchema, typeMasterWarehouseNameSchema, typeMasterWarehouseLocationIdSchema, typeMasterWarehouseLocationCodeSchema, typeMasterWarehouseLocationNameSchema, typeMasterWarehouseLocationDetailIdSchema, typeMasterWarehouseLocationDetailCodeSchema, typeMasterWarehouseLocationDetailNameSchema, typeMasterProductIdSchema, typeMasterProductCodeSchema, typeMasterProductNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeSearchItemsSchema } from "./../../search";

export const formStockStockOutListInputWarehouseIdSchema = typeMasterWarehouseIdSchema.optional();
export type FormStockStockOutListInputWarehouseIdSchemaType = InferType<typeof formStockStockOutListInputWarehouseIdSchema>;
export const formStockStockOutListInputWarehouseCodeSchema = typeMasterWarehouseCodeSchema.optional();
export type FormStockStockOutListInputWarehouseCodeSchemaType = InferType<typeof formStockStockOutListInputWarehouseCodeSchema>;
export const formStockStockOutListInputWarehouseNameSchema = typeMasterWarehouseNameSchema.optional();
export type FormStockStockOutListInputWarehouseNameSchemaType = InferType<typeof formStockStockOutListInputWarehouseNameSchema>;
export const formStockStockOutListInputLocationIdSchema = typeMasterWarehouseLocationIdSchema.optional();
export type FormStockStockOutListInputLocationIdSchemaType = InferType<typeof formStockStockOutListInputLocationIdSchema>;
export const formStockStockOutListInputLocationCodeSchema = typeMasterWarehouseLocationCodeSchema.optional();
export type FormStockStockOutListInputLocationCodeSchemaType = InferType<typeof formStockStockOutListInputLocationCodeSchema>;
export const formStockStockOutListInputLocationNameSchema = typeMasterWarehouseLocationNameSchema.optional();
export type FormStockStockOutListInputLocationNameSchemaType = InferType<typeof formStockStockOutListInputLocationNameSchema>;
export const formStockStockOutListInputLocationDetailIdSchema = typeMasterWarehouseLocationDetailIdSchema.optional();
export type FormStockStockOutListInputLocationDetailIdSchemaType = InferType<typeof formStockStockOutListInputLocationDetailIdSchema>;
export const formStockStockOutListInputLocationDetailCodeSchema = typeMasterWarehouseLocationDetailCodeSchema.optional();
export type FormStockStockOutListInputLocationDetailCodeSchemaType = InferType<typeof formStockStockOutListInputLocationDetailCodeSchema>;
export const formStockStockOutListInputLocationDetailNameSchema = typeMasterWarehouseLocationDetailNameSchema.optional();
export type FormStockStockOutListInputLocationDetailNameSchemaType = InferType<typeof formStockStockOutListInputLocationDetailNameSchema>;
export const formStockStockOutListInputProductIdSchema = typeMasterProductIdSchema.optional();
export type FormStockStockOutListInputProductIdSchemaType = InferType<typeof formStockStockOutListInputProductIdSchema>;
export const formStockStockOutListInputProductCodeSchema = typeMasterProductCodeSchema.optional();
export type FormStockStockOutListInputProductCodeSchemaType = InferType<typeof formStockStockOutListInputProductCodeSchema>;
export const formStockStockOutListInputProductNameSchema = typeMasterProductNameSchema.optional();
export type FormStockStockOutListInputProductNameSchemaType = InferType<typeof formStockStockOutListInputProductNameSchema>;
export const formStockStockOutListInputSearchItemsSchema = typeSearchItemsSchema;
export type FormStockStockOutListInputSearchItemsSchemaType = InferType<typeof formStockStockOutListInputSearchItemsSchema>;
export const formStockStockOutListInputSchema = yup.object({
	warehouseId: formStockStockOutListInputWarehouseIdSchema,
	warehouseCode: formStockStockOutListInputWarehouseCodeSchema,
	warehouseName: formStockStockOutListInputWarehouseNameSchema,
	locationId: formStockStockOutListInputLocationIdSchema,
	locationCode: formStockStockOutListInputLocationCodeSchema,
	locationName: formStockStockOutListInputLocationNameSchema,
	locationDetailId: formStockStockOutListInputLocationDetailIdSchema,
	locationDetailCode: formStockStockOutListInputLocationDetailCodeSchema,
	locationDetailName: formStockStockOutListInputLocationDetailNameSchema,
	productId: formStockStockOutListInputProductIdSchema,
	productCode: formStockStockOutListInputProductCodeSchema,
	productName: formStockStockOutListInputProductNameSchema,
	searchItems: formStockStockOutListInputSearchItemsSchema,
}).required();
export type FormStockStockOutListInputSchemaType = InferType<typeof formStockStockOutListInputSchema>;
