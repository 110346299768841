import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { array, number, object } from 'yup';
import { formCommonSaveSearchOptionDialogBaseSchema } from '../../../schema/form/common/saveSearchOptionDialogBase';
import { formMasterSearchConditionListSchema } from '../../../schema/form/master/searchCondition';

// MEMO: DB確定後にresponse型、createApi修正予定です。
export type SearchConditionListResponse = MasterResponse & {
	searchConditionId: number; // 検索条件ID
	searchConditionCode: string; // 検索条件コード
	searchConditionName: string; // 検索条件名
	searchTargetId: number; // 検索対象ID
	searchTargetName: string; // 検索対象名
};

export type SearchConditionDetailListResponse = MasterResponse & {
	searchConditionDetailId: number; // 検索条件明細ID
	searchConditionId: number; // 検索条件ID
	// 検索項目名
	// 検索条件
};

const listApi = createApiInterface().request(formMasterSearchConditionListSchema).response<{
	list: SearchConditionListResponse[];
}>();

const createApi = createApiInterface()
	.request(object({ name: formCommonSaveSearchOptionDialogBaseSchema, data: array() }).required())
	.response<{
		isSuccess: true;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().required() }))
	.response<{
		isSuccess: true;
	}>();

export const searchConditionApi = createApiInterfaceMap({
	['/v1/master/search-condition/list']: listApi,
	['/v1/master/search-condition/create']: createApi,
	['/v1/master/search-condition/delete']: deleteApi,
});
