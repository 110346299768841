/** 国名コード */
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formSystemMaintenanceRegionCountryCodeListSchema } from '../../../schema/form/systemMaintenance/region';
import { MasterResponse } from '../../../response';

export type CountryCodeListResponse = MasterResponse & {
	countryId: number;
	countryName: string;
	countryAlpha2: string;
	countryAlpha3: string;
	countryNumeric: number;
	countryNote: string | undefined;
};

const listApi = createApiInterface().request(formSystemMaintenanceRegionCountryCodeListSchema.notRequired()).response<{
	list: CountryCodeListResponse[];
}>();

export const countryCodeApi = createApiInterfaceMap({
	['/v1/system-maintenance/region/country-code/list']: listApi,
});
