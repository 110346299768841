// fv-generatorで生成されました
import * as yup from "yup";
import { typeReceivingGroupNameSchema } from "./../../receiving";
import { InferType } from "yup";

export const formReceivingReceivingAndIssuingGroupListReceiveIssueGroupNameSchema = typeReceivingGroupNameSchema.optional();
export type FormReceivingReceivingAndIssuingGroupListReceiveIssueGroupNameSchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupListReceiveIssueGroupNameSchema>;
export const formReceivingReceivingAndIssuingGroupListSchema = yup.object({
	receiveIssueGroupName: formReceivingReceivingAndIssuingGroupListReceiveIssueGroupNameSchema,
}).required();
export type FormReceivingReceivingAndIssuingGroupListSchemaType = InferType<typeof formReceivingReceivingAndIssuingGroupListSchema>;
