/** セット組み検品入力 */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formStockSetProductInspectionSchema } from '../../../schema/form/stock/setProductInspection';
import { formStockSetProductInspectionResultSchema } from '../../../schema/form/stock/setProductInspectionResult';
import { BasicResponse } from '../../../response';
import { formStockSetProductInspectionMobileEntrySchema } from '../../../schema/form/stock/setProductInspectionMobileEntry';

export type SetProductInspectionListResponse = BasicResponse & {
	// 検品
	inspectionId: number;
	inspectionLog: SetProductInspectionLog[];
	// SKUグループ
	skuGroupId: number;
	skuGroupCode: string;
	// SKUグループ明細
	skuGroupDetailId: number;
	setRequiredQuantity: number; // セット必要数量
	inspectionQuantity: number | undefined; // 検品数量
	// SKU（明細）
	skuId: number;
	skuCode: string;
	// 商品
	productId: number;
	productCode: string;
	productName: string;
};

type SetProductInspectionLog = {
	inspectionLogId: number;
	lotId: number;
	inspectionQuantity: number;
};

const findApi = createApiInterface().request(formStockSetProductInspectionSchema).response<{
	list: SetProductInspectionListResponse[];
}>();

const registApi = createApiInterface()
	.request(object({ form: formStockSetProductInspectionResultSchema, id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const mobileRegistApi = createApiInterface()
	.request(object({ form: formStockSetProductInspectionMobileEntrySchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

export const setProductInspectionApi = createApiInterfaceMap({
	['/v1/stock/set-product/inspection/find']: findApi,
	['/v1/stock/set-product/inspection/regist']: registApi,
	['/v1/stock/set-product/inspection/delete']: deleteApi,
	['/v1/stock/set-product/inspection/mobile']: mobileRegistApi,
});
