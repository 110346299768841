import { InferType, SchemaOf } from 'yup';
import { JSONObject } from '../json';

const isApiInterfaceSymbol = Symbol('ApiInterface');

const responseTypeKey = Symbol();
type ResponseTypeKey = typeof responseTypeKey;

export type ApiInterface<RequestSchema extends SchemaOf<unknown> = SchemaOf<unknown>, Response extends Record<string, unknown> = Record<string, unknown>> = {
	type: typeof isApiInterfaceSymbol;
	schema: RequestSchema;
	[responseTypeKey]?: Response;
};

export type GetRequestTypeFromApi<Api extends ApiInterface> = InferType<Api['schema']>;
export type GetResponseTypeFromApi<Api extends ApiInterface> = NonNullable<Api[ResponseTypeKey]>;

export type ApiMapBase = {
	[P in string]: ApiInterface;
};

// ApiInterfaceオブジェクトを作成するBuilderです
export function createApiInterface() {
	return {
		request: function <RequestSchema extends SchemaOf<unknown>>(schema: RequestSchema) {
			return {
				response: function <Response extends JSONObject>(): ApiInterface<RequestSchema, Response> {
					return {
						type: isApiInterfaceSymbol,
						schema,
					};
				},
			};
		},
	};
}

// 型エラーの原因を分かりやすくする為に使用します
export function createApiInterfaceMap<T extends Record<string, ApiInterface>>(map: T): T {
	return map;
}
