import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formMasterUserEntryEditSchema, formMasterUserEntrySchema, formMasterUserListSchema } from '../../../schema/form/master/user';
import { MasterResponse } from '../../../response';

export type UserListResponse = MasterResponse & {
	userId: number; // ユーザーID
	userCode: string; // ユーザーコード
	userName: string; // ユーザー名
	userNote: string | undefined; // 備考
	authorityId: number; // 権限ID
	authorityCode: string; // 権限コード
	authorityName: string; // 権限名
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: UserListResponse[] }>();

const listApi = createApiInterface().request(formMasterUserListSchema.notRequired()).response<{ list: UserListResponse[] }>();

const createApi = createApiInterface().request(formMasterUserEntrySchema).response<{
	isSuccess: boolean;
	userId?: number;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterUserEntryEditSchema }))
	.response<{ isSuccess: boolean }>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const systemUserApi = createApiInterfaceMap({
	['/v1/master/user/find']: findApi,
	['/v1/master/user/list']: listApi,
	['/v1/master/user/create']: createApi,
	['/v1/master/user/update']: updateApi,
	['/v1/master/user/delete']: deleteApi,
});
