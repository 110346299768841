/** 空きロケーション・棚一覧 */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { formIssuingFreeLocationListInputSchema } from '../../schema/form/issuing/freeLocationListInput';
import { ListResponse } from '../../response';

export type FreeLocationListResponse = ListResponse & {
	shipperId: number; // 荷主ID
	shipperCode: string; // 荷主コード
	shipperName: string; // 荷主名
	locationId: number; // ロケーションID
	locationCode: string; // ロケーションコード
	locationName: string; // ロケーション名
	locationDetailId: number; // ロケーション明細(棚)ID
	locationDetailCode: string; // ロケーション明細(棚)コード
	locationDetailName: string; // ロケーション明細(棚)名
	storingConditionId: number; // 保管条件ID
	storingConditionCode: string; // 保管条件コード
	storingConditionName: string; // 保管条件名
	assigningPriority: number; // 割当優先順位
	mixedLocationDetailFlag: boolean; // 混在ロケーションフラグ
	locationDetailVerticalM: number; // ロケーション明細縦(m)
	locationDetailHorizontalM: number; // ロケーション明細幅(m)
	locationDetailHeightM: number; // ロケーション明細高(m)
};

const listApi = createApiInterface().request(formIssuingFreeLocationListInputSchema).response<{
	list: FreeLocationListResponse[];
}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const freeLocationApi = createApiInterfaceMap({
	['/v1/issuing/free-location/list']: listApi,
	['/v1/issuing/free-location/delete']: deleteApi,
});
