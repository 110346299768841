import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useIntl } from 'react-intl';
import { isTranslateableLanguage, Language } from './language';
import jaLocale from 'date-fns/locale/ja';
import enUSLocale from 'date-fns/locale/en-US';
import { ReactNode } from 'react';

class JaDateUtils extends DateFnsUtils {
	getCalendarHeaderText(date: Date): string {
		return this.format(date, 'yyyy MMM');
	}

	getDatePickerHeaderText(date: Date): string {
		return this.format(date, 'MMMd日');
	}
}

type DateFormatConfig = {
	[P in Language]: {
		locale: Locale;
		utils: typeof DateFnsUtils;
	};
};

const config: DateFormatConfig = {
	'ja-jp': {
		locale: jaLocale,
		utils: JaDateUtils,
	},
	'en-us': {
		locale: enUSLocale,
		utils: DateFnsUtils,
	},
};

type DateFormatProviderProps = {
	children: ReactNode;
};
export default function DateFormatProvider({ children }: DateFormatProviderProps) {
	const intl = useIntl();
	if (!isTranslateableLanguage(intl.locale)) throw new Error();

	const { utils, locale } = config[intl.locale];

	return (
		<MuiPickersUtilsProvider
			utils={utils}
			locale={locale}
		>
			{children}
		</MuiPickersUtilsProvider>
	);
}
