/** 在庫一覧 */

import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formStockStockListInputSchema } from '../../../schema/form/stock/stockListInput';
import { ListResponse } from '../../../response';
import { formStockStockEntrySchema } from '../../../schema/form/stock/stockEntry';

export type StockListResponse = ListResponse & {
	stockId: number; // 在庫ID
	shipperId: number; // 荷主ID
	shipperCode: string; // 荷主コード
	shipperName: string; // 荷主名
	warehouseId: number; // 倉庫ID
	warehouseCode: string; // 倉庫コード
	warehouseName: string; // 倉庫名
	locationId: number; // ロケーションID
	locationCode: string; // ロケーションコード
	locationName: string; // ロケーション名
	aisleId: number | undefined; // 通路ID
	aisleCode: string | undefined; // 通路コード
	aisleName: string | undefined; // 通路名
	locationDetailId: number; // ロケーション明細ID
	locationDetailCode: string; // ロケーション明細コード
	locationDetailName: string; // ロケーション明細名
	productId: number; // 商品Id
	productCode: string; // 商品コード
	productName: string; // 商品名
	partNumberId: number; // 品番ID
	partNumberCode: string; // 品番コード
	partNumberName: string; // 品名
	janCode: string | undefined; // JANコード
	skuId: number; // SKUID
	skuCode: string; // SKUコード
	lotId: number; // ロットID
	lotName: string; // ロット名
	bestBeforeDate: string | undefined; // 賞味期限
	stockQuantity: number; // 在庫数量
	afterAllocationStockQuantity: number; // 引当後在庫数量
	availableStockQuantity: number; // 有効在庫数量
	futureStockQuantity: number; // 未来在庫数量
};

const listApi = createApiInterface().request(formStockStockListInputSchema.notRequired()).response<{ list: StockListResponse[] }>();
const createApi = createApiInterface().request(formStockStockEntrySchema).response<{ isSuccess: boolean }>();
const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formStockStockEntrySchema }))
	.response<{ isSuccess: boolean }>();
const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const stockApi = createApiInterfaceMap({
	['/v1/stock/list']: listApi,
	['/v1/stock/create']: createApi,
	['/v1/stock/update']: updateApi,
	['/v1/stock/delete']: deleteApi,
});
