// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterShipperIdSchema, typeMasterShipperCodeSchema, typeMasterShipperNameSchema, typeMasterShipperContractDateSchema, typeMasterShipperContractOperationStartDateSchema, typeMasterShipperContractWithdrawalDateSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema } from "./../../common";

export const formMasterShipperContractListShipperIdSchema = typeMasterShipperIdSchema.optional();
export type FormMasterShipperContractListShipperIdSchemaType = InferType<typeof formMasterShipperContractListShipperIdSchema>;
export const formMasterShipperContractListShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormMasterShipperContractListShipperCodeSchemaType = InferType<typeof formMasterShipperContractListShipperCodeSchema>;
export const formMasterShipperContractListShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormMasterShipperContractListShipperNameSchemaType = InferType<typeof formMasterShipperContractListShipperNameSchema>;
export const formMasterShipperContractListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterShipperContractListIsAvailableSchemaType = InferType<typeof formMasterShipperContractListIsAvailableSchema>;
export const formMasterShipperContractListSchema = yup.object({
	shipperId: formMasterShipperContractListShipperIdSchema,
	shipperCode: formMasterShipperContractListShipperCodeSchema,
	shipperName: formMasterShipperContractListShipperNameSchema,
	isAvailable: formMasterShipperContractListIsAvailableSchema,
}).required();
export type FormMasterShipperContractListSchemaType = InferType<typeof formMasterShipperContractListSchema>;
export const formMasterShipperContractEntryShipperIdSchema = typeMasterShipperIdSchema;
export type FormMasterShipperContractEntryShipperIdSchemaType = InferType<typeof formMasterShipperContractEntryShipperIdSchema>;
export const formMasterShipperContractEntryShipperCodeSchema = typeMasterShipperCodeSchema;
export type FormMasterShipperContractEntryShipperCodeSchemaType = InferType<typeof formMasterShipperContractEntryShipperCodeSchema>;
export const formMasterShipperContractEntryShipperNameSchema = typeMasterShipperNameSchema;
export type FormMasterShipperContractEntryShipperNameSchemaType = InferType<typeof formMasterShipperContractEntryShipperNameSchema>;
export const formMasterShipperContractEntryShipperContractDateSchema = typeMasterShipperContractDateSchema;
export type FormMasterShipperContractEntryShipperContractDateSchemaType = InferType<typeof formMasterShipperContractEntryShipperContractDateSchema>;
export const formMasterShipperContractEntryShipperOperationStartDateSchema = typeMasterShipperContractOperationStartDateSchema.optional();
export type FormMasterShipperContractEntryShipperOperationStartDateSchemaType = InferType<typeof formMasterShipperContractEntryShipperOperationStartDateSchema>;
export const formMasterShipperContractEntryShipperWithdrawalDateSchema = typeMasterShipperContractWithdrawalDateSchema.optional();
export type FormMasterShipperContractEntryShipperWithdrawalDateSchemaType = InferType<typeof formMasterShipperContractEntryShipperWithdrawalDateSchema>;
export const formMasterShipperContractEntryShipperContractNoteSchema = typeCommonNoteSchema;
export type FormMasterShipperContractEntryShipperContractNoteSchemaType = InferType<typeof formMasterShipperContractEntryShipperContractNoteSchema>;
export const formMasterShipperContractEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterShipperContractEntryAvailableFromSchemaType = InferType<typeof formMasterShipperContractEntryAvailableFromSchema>;
export const formMasterShipperContractEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterShipperContractEntryAvailableToSchemaType = InferType<typeof formMasterShipperContractEntryAvailableToSchema>;
export const formMasterShipperContractEntrySchema = yup.object({
	shipperId: formMasterShipperContractEntryShipperIdSchema,
	shipperCode: formMasterShipperContractEntryShipperCodeSchema,
	shipperName: formMasterShipperContractEntryShipperNameSchema,
	shipperContractDate: formMasterShipperContractEntryShipperContractDateSchema,
	shipperOperationStartDate: formMasterShipperContractEntryShipperOperationStartDateSchema,
	shipperWithdrawalDate: formMasterShipperContractEntryShipperWithdrawalDateSchema,
	shipperContractNote: formMasterShipperContractEntryShipperContractNoteSchema,
	availableFrom: formMasterShipperContractEntryAvailableFromSchema,
	availableTo: formMasterShipperContractEntryAvailableToSchema,
}).required();
export type FormMasterShipperContractEntrySchemaType = InferType<typeof formMasterShipperContractEntrySchema>;
export const formMasterShipperListShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormMasterShipperListShipperCodeSchemaType = InferType<typeof formMasterShipperListShipperCodeSchema>;
export const formMasterShipperListShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormMasterShipperListShipperNameSchemaType = InferType<typeof formMasterShipperListShipperNameSchema>;
export const formMasterShipperListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterShipperListIsAvailableSchemaType = InferType<typeof formMasterShipperListIsAvailableSchema>;
export const formMasterShipperListSchema = yup.object({
	shipperCode: formMasterShipperListShipperCodeSchema,
	shipperName: formMasterShipperListShipperNameSchema,
	isAvailable: formMasterShipperListIsAvailableSchema,
}).required();
export type FormMasterShipperListSchemaType = InferType<typeof formMasterShipperListSchema>;
export const formMasterShipperEntryShipperCodeSchema = typeMasterShipperCodeSchema;
export type FormMasterShipperEntryShipperCodeSchemaType = InferType<typeof formMasterShipperEntryShipperCodeSchema>;
export const formMasterShipperEntryShipperNameSchema = typeMasterShipperNameSchema;
export type FormMasterShipperEntryShipperNameSchemaType = InferType<typeof formMasterShipperEntryShipperNameSchema>;
export const formMasterShipperEntryShipperNoteSchema = typeCommonNoteSchema;
export type FormMasterShipperEntryShipperNoteSchemaType = InferType<typeof formMasterShipperEntryShipperNoteSchema>;
export const formMasterShipperEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterShipperEntryAvailableFromSchemaType = InferType<typeof formMasterShipperEntryAvailableFromSchema>;
export const formMasterShipperEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterShipperEntryAvailableToSchemaType = InferType<typeof formMasterShipperEntryAvailableToSchema>;
export const formMasterShipperEntrySchema = yup.object({
	shipperCode: formMasterShipperEntryShipperCodeSchema,
	shipperName: formMasterShipperEntryShipperNameSchema,
	shipperNote: formMasterShipperEntryShipperNoteSchema,
	availableFrom: formMasterShipperEntryAvailableFromSchema,
	availableTo: formMasterShipperEntryAvailableToSchema,
}).required();
export type FormMasterShipperEntrySchemaType = InferType<typeof formMasterShipperEntrySchema>;
