import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { MasterResponse } from '../../../response';
import { formMasterProductLargeCategoryEntrySchema, formMasterProductLargeCategoryListSchema } from '../../../schema/form/master/product';

export type LargeCategoryResponse = MasterResponse & {
	productLargeCategoryId: number; // 商品大項目ID
	productLargeCategoryCode: string; // 商品大項目コード
	productLargeCategoryName: string; // 商品大項目名
	partNumberCategoryId: number; // 品番区分ID
	partNumberCategoryCode: string; // 品番区分コード
	partNumberCategoryName: string; // 品番区分名
	productLargeCategoryDisplayOrder: number; // 表示順
	productLargeCategoryNote: string | undefined; // 備考
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{ list: LargeCategoryResponse[] }>();

const listApi = createApiInterface().request(formMasterProductLargeCategoryListSchema).response<{
	list: LargeCategoryResponse[];
}>();

const createApi = createApiInterface().request(formMasterProductLargeCategoryEntrySchema).response<{
	isSuccess: boolean;
}>();

const updateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formMasterProductLargeCategoryEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const largeCategoryApi = createApiInterfaceMap({
	['/v1/master/product/large-category/find']: findApi,
	['/v1/master/product/large-category/list']: listApi,
	['/v1/master/product/large-category/create']: createApi,
	['/v1/master/product/large-category/update']: updateApi,
	['/v1/master/product/large-category/delete']: deleteApi,
});
