import { number, object } from 'yup';
import { MasterResponse } from '../../../response';
import { formMasterClientCategoryEntrySchema, formMasterClientCategoryListSchema } from '../../../schema/form/master/client';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';

export type ClientCategoryResponse = MasterResponse & {
	clientCategoryId: number;
	clientCategoryCode: string;
	clientCategoryName: string;
	clientCategoryNote: string | undefined;
};

const findApi = createApiInterface()
	.request(object({ id: number().required().integer() }))
	.response<{
		list: ClientCategoryResponse[];
	}>();

const listApi = createApiInterface().request(formMasterClientCategoryListSchema.notRequired()).response<{
	list: ClientCategoryResponse[];
}>();

const createApi = createApiInterface().request(formMasterClientCategoryEntrySchema).response<{
	isSuccess: boolean;
	clientCategoryId?: number;
}>();

const updateApi = createApiInterface()
	.request(
		object({
			id: number().required().integer(),
			form: formMasterClientCategoryEntrySchema,
		})
	)
	.response<{
		isSuccess: boolean;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

export const clientCategoryApi = createApiInterfaceMap({
	['/v1/master/client/category/find']: findApi,
	['/v1/master/client/category/list']: listApi,
	['/v1/master/client/category/create']: createApi,
	['/v1/master/client/category/update']: updateApi,
	['/v1/master/client/category/delete']: deleteApi,
});
