import { Theme } from '@mui/material';

/** モバイル表示に切り替える幅です */
export const breakpointMobileWidth = 900;

/** テーマ設定用です */
export const getTheme = (theme: Theme) => {
	theme.typography.body1 = {
		[theme.breakpoints.between('xs', 'sm')]: {
			fontSize: '1.25rem',
		},
		[theme.breakpoints.between('sm', 'md')]: {
			fontSize: '1.25rem',
		},
		[theme.breakpoints.up('md')]: {
			fontSize: '1.0rem',
		},
	};

	theme.typography.button = {
		[theme.breakpoints.between('xs', 'sm')]: {
			fontSize: '1.2rem',
		},
		[theme.breakpoints.between('sm', 'md')]: {
			fontSize: '1.07rem',
		},
		[theme.breakpoints.up('md')]: {
			fontSize: '1.0rem',
		},
	};
	return theme;
};
