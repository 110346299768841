import React, { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react';
import { MenuIdsType, menuIds } from './MenuList';

/** メニュー展開状態 */
export type MenuOpenState = {
	[key: MenuIdsType]: boolean;
};

export type MenuStateType = {
	menuState: MenuOpenState;
	setMenuState: Dispatch<SetStateAction<MenuOpenState>>;
};

/** 初期のメニュー展開状態を取得します。 */
export const getInitialMenuState = (): MenuOpenState => {
	const ret: MenuOpenState = {};
	for (const key of Object.keys(menuIds)) {
		ret[menuIds[key]] = false;
	}
	return ret;
};

/** メニュー展開状態の記憶用です */
export const MenuStateContext = createContext<MenuStateType>({ menuState: {}, setMenuState: () => ({}) });

export default React.memo(function SideMenuProvider({ children }: { children: React.ReactNode }) {
	/** メニュー展開状態の記憶用です */
	const [menuState, setMenuState] = useState<MenuOpenState>(getInitialMenuState());
	const contextValue = useMemo(
		() => ({
			menuState,
			setMenuState,
		}),
		[menuState, setMenuState]
	);

	return <MenuStateContext.Provider value={contextValue}>{children}</MenuStateContext.Provider>;
});
