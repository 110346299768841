// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionNumberSchema } from "./../../common";
import { InferType } from "yup";

export const formReceivingReceivingInspectionReceiveIssueIdSchema = typeCommonInspectionNumberSchema;
export type FormReceivingReceivingInspectionReceiveIssueIdSchemaType = InferType<typeof formReceivingReceivingInspectionReceiveIssueIdSchema>;
export const formReceivingReceivingInspectionSchema = yup.object({
	receiveIssueId: formReceivingReceivingInspectionReceiveIssueIdSchema,
}).required();
export type FormReceivingReceivingInspectionSchemaType = InferType<typeof formReceivingReceivingInspectionSchema>;
