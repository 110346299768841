/** セット崩し一覧 */
import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../../api-interface';
import { formStockBreakSetProductListInputSchema } from '../../../schema/form/stock/breakSetProductListInput';
import { ListResponse } from '../../../response';
import { formStockBreakSetProductScheduleEntrySchema } from '../../../schema/form/stock/breakSetProductScheduleEntry';
import { formStockBreakSetProductResultEntrySchema } from '../../../schema/form/stock/breakSetProductResultEntry';

export type BreakSetProductListResponse = ListResponse & {
	skuGroupId: number; // SKUグループID
	skuGroupCode: string; // SKUグループコード
	skuGroupName: string; // SKUグループ名
	skuGroupNote: string | undefined; // SKUグループ備考
	skuId: number; // SKUID
	skuCode: string; // SKUコード
	storingConditionId: number | undefined; // 保管条件ID
	storingConditionCode: string | undefined; // 保管条件コード
	storingConditionName: string | undefined; // 保管条件名
	quantity: number; // 数量
};

export type BreakSetProductDetailListResponse = BreakSetProductListResponse & {
	skuGroupDetailId: number; // SKUグループ明細ID
	setRequiredQuantity: number; // セット必要数量
};

const listApi = createApiInterface().request(formStockBreakSetProductListInputSchema).response<{
	list: BreakSetProductListResponse[];
}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

const scheduleCreateApi = createApiInterface().request(formStockBreakSetProductScheduleEntrySchema).response<{
	isSuccess: boolean;
}>();

const resultCreateApi = createApiInterface().request(formStockBreakSetProductResultEntrySchema).response<{
	isSuccess: boolean;
}>();

export const breakSetProductApi = createApiInterfaceMap({
	['/v1/stock/break-set-product/list']: listApi,
	['/v1/stock/break-set-product/delete']: deleteApi,
	['/v1/stock/break-set-product/entry-schedule/create']: scheduleCreateApi,
	['/v1/stock/break-set-product/entry-result/create']: resultCreateApi,
});
